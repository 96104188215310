import { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useStoreState } from '../../../../hooks';
import { AVAILABLE, SOLD } from '../../../../styles/constants';
import { LayoutsPopover } from './LayoutsPopover';

export type MetricsRowProps = {
  layout: string;
  total: number;
  sold: number;
  reserved: number;
};

export const LayoutsCol: FC<MetricsRowProps> = ({
  layout,
  total,
  sold,
  reserved,
}) => {
  const { includeReservations } = useStoreState((state) => state.dashboard);

  const calculatedSold = includeReservations ? sold + reserved : sold;
  const is4r = layout.includes('4');

  return (
    <div className='grid-rows-3 text-center'>
      <LayoutsPopover
        layout={layout}
        total={total}
        sold={sold}
        reserved={reserved}
      >
        <span className='font-bold whitespace-nowrap'>
          {layout}
          {is4r ? ' +' : ''}
        </span>
        <div className='flex gap-2 items-center justify-center'>
          <div className='flex-shrink-0 w-[24px]'>
            <PieChart
              lineWidth={60}
              startAngle={-90}
              animate
              data={[
                { value: Math.round(calculatedSold), color: AVAILABLE },
                { value: total - Math.round(calculatedSold), color: SOLD },
              ]}
              className='w-full h-full'
            />
          </div>
          <span className='font-bold text-[2.4rem] whitespace-nowrap'>
            {!!!total ? '0 %' : `${Math.round((calculatedSold * 100) / total)} %`}
          </span>
        </div>
        <div className='text-[1.2rem]'>{`${calculatedSold}/${total}`}</div>
      </LayoutsPopover>
    </div>
  );
};
