import { action, createStore, persist } from 'easy-peasy';
import { userStore } from './user';
import { dashboardStore } from './dashboard';
import { Mode, ProjectType, StoreModel } from './types';
import { filterStore } from './filters';
import { priceListStore } from './priceList';
import { defaultTableData, marketStore } from './market';
import { globalStore } from './global';
import { projectStore } from './project';
import { priceListDetailStore } from './priceListDetail';
import { FilterEnum } from '../components/project/useFilter';
import { dailyNewsStore } from './dailyNews';
import { secondaryMarketStore } from './secondary';
import { subscribeStore } from './subscribe';
import { salesStore } from './sales';
import { DataUpdates, onboardingStore } from './onboarding';
import { defaultColumns } from '../components/columnSelector/Columns';
import { ConstructionTypeEnum, ProjectTypeEnum } from '../api/enums';

export const store = createStore<StoreModel, StoreModel>(
  persist(
    {
      user: userStore,
      dashboard: dashboardStore,
      filters: filterStore,
      priceList: priceListStore,
      market: marketStore,
      global: globalStore,
      project: projectStore,
      priceListDetail: priceListDetailStore,
      dailyNews: dailyNewsStore,
      secondaryMarket: secondaryMarketStore,
      subscribe: subscribeStore,
      sales: salesStore,
      onboarding: onboardingStore,
      reset: action((state) => {
        state.user = { isLoading: false, isLoggedIn: false };
        state.dashboard = {
          dashboardLoading: false,
          updatingCompetitors: false,
          includeReservations: state.dashboard.includeReservations,
        };
        state.filters = {
          isLoading: false,
          paramsPM: null,
          paramsSM: null,
          marketDates: [],
          aggregationDates: [],
          availability: FilterEnum.AVAILABLE,
          displayValues: state.filters.displayValues,
          priceDecreases: state.filters.priceDecreases,
          separateUnderConstruction: state.filters.separateUnderConstruction,
          projectType: ProjectType.SALE,
          unitsType: [ProjectTypeEnum.UNITS],
          constructionType: [
            ConstructionTypeEnum.NEW,
            ConstructionTypeEnum.RENOVATION,
          ],
        };
        state.secondaryMarket = {
          reports: [],
        };
        state.dailyNews = {};
        state.global = {
          isLoaded: false,
          isLoading: false,
          activeRequestCount: 0,
        };
        state.market = {
          loadingFilters: false,
          columns: defaultColumns,
          addReportStep: null,
          isMaximized: false,
          templates: [],
          data: null,
          dashboardOverviewData: null,
          isMapLoading: false,
          projects: [],
          simplifiedProjects: [],
          reportsOrder: [],
          availableMapTags: [],
          activeTags: [],
          projectsLoading: false,
          selectedProjectId: null,
          primaryParameters: null,
          secondaryParameters: null,
          isReportCreating: false,
          polygons: {},
          circles: {},
          selectedCompetitors: [],
          selectedProjectNames: [],
          selectedDeveloperNames: [],
          mode: Mode.READ,
          tableData: defaultTableData,
          selectedProject: undefined,
          getProjects: Promise.resolve([]),
          showPricePerSM: false,
          mapControls: 'move',
        };
        state.priceList = {
          buildingsData: [],
          buildingsDataUpdated: [],
          selectedData: undefined,
          selectedPricingData: undefined,
          selectedPricingUpdateData: undefined,
          showPricePerSM: false,
          layoutFilter: [],
          showAnalytics: false,
          showAnalyticsUpdated: false,
        };
        state.onboarding = {
          currentPage: 0,
          projectName: '',
          projectType: { rental: false, sale: false },
          pageUrl: '',
          emails: [],
          dataUpdates: DataUpdates.EMPTY,
          buildingType: { house: false, unit: false },
          saleEnd: null,
          saleStart: null,
          targets: [],
          startingPrices: [],
          priceList: [],
          skipPriceList: false,
          startingPricesOverall: null,
          selectedFiles: [],
        };
        state.priceListDetail = {
          showPrediction: false,
          showPredictionUpdated: false,
          comparisonPrediction: [],
          comparisonPredictionUpdated: [],
        };
        state.project = { aggregatedTableData: [] };
        localStorage.removeItem('access_token');
      }),
    },
    {
      storage: 'localStorage',
      deny: ['market', 'sales'],
    },
  ),
  { version: 1 },
);
