import { Grid } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserver';
import { useMemo } from 'react';

const { useBreakpoint } = Grid;

type Breakpoints = {
  breakpoints: Partial<Record<Breakpoint, boolean>>;
  isSmallScreen: boolean;
};

export const useBreakpoints = (): Breakpoints => {
  const breakpoints = useBreakpoint();
  const isSmallScreen = useMemo(() => window.innerWidth < 1000, []);
  return {
    breakpoints,
    isSmallScreen,
  };
};
