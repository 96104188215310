/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { useRegionFormatting } from '../../../../hooks';
import { Content as ContentUS } from './us';
import { Content as ContentOther } from './other';
import { MarketProjectExtended } from '../../../../store/types';

interface Props {
  data: MarketProjectExtended;
}

export const Content: FC<Props> = (props) => {
  const { isUsRegion } = useRegionFormatting();
  if (isUsRegion) {
    return <ContentUS {...props} />;
  }
  return <ContentOther {...props} />;
};
