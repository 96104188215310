/** @jsxImportSource @emotion/react */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC, ReactNode } from 'react';
import { Popover, Button, PopoverProps } from 'antd';
import { css } from '@emotion/react/macro';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { BOLD_FONT_WEIGHT } from '../styles/constants';

const styles = {
  label: css({
    fontWeight: BOLD_FONT_WEIGHT,
    marginRight: '2.4rem',
  }),
  rowsContainer: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  row: css({ display: 'flex', justifyContent: 'space-between' }),
};

export interface RowData {
  label: string;
  value: ReactNode;
}
interface ExtendedPopoverProps extends PopoverProps {
  rows?: RowData[];
}
interface Props {
  popoverProps: ExtendedPopoverProps;
  label?: ReactNode;
  icon?: ReactNode;
}

export const InfoPopover: FC<Props> = ({
  popoverProps: { content, rows, ...restPopoverProps },
  label: popoverLabel,
  icon,
}) => (
  <Popover
    content={
      rows ? (
        <div css={styles.rowsContainer}>
          {rows.map(({ label, value }) => (
            <div css={styles.row} key={label}>
              <div css={styles.label}>{label}</div>
              <div>{value}</div>
            </div>
          ))}
        </div>
      ) : (
        <>{content}</>
      )
    }
    {...restPopoverProps}
  >
    <span className='flex'>
    {popoverLabel}
    <Button
      className='bm-info-btn'
      type='text'
      shape='circle'
      icon={icon ?? <InformationCircleIcon className='w-7 text-[#BDBDBD]' />}
      size='small'
    />
    </span>
  </Popover>
);
