import { Action, action, thunk, Thunk } from 'easy-peasy';
import {
  getDashboardData,
  getPricingDashboardData,
  updateSelectedCompetitors,
} from '../api';
import { StoreModel } from './types';
import {
  DashboardData,
  PricingDashboardData,
} from '../api/mockData/dashboardData';
import {
  DashboardDataParameters,
  PricingDashboardCompetitorsDataParameters,
  PricingDashboardDataParameters,
} from '../api/types';
import { logError } from '../utils/utils';

export interface DashboardStore {
  data?: DashboardData;
  dashboardData?: PricingDashboardData;
  updateData?: any;
  dashboardLoading: boolean;
  includeReservations: boolean;
  updatingCompetitors: boolean;
  setDashboardLoading: Action<DashboardStore, boolean>;
  setUpdateData: Action<DashboardStore, any>;
  setData: Action<DashboardStore, DashboardData>;
  setDashboardData: Action<DashboardStore, PricingDashboardData>;
  setIncludeReservations: Action<DashboardStore, boolean>;
  setUpdatingCompetitors: Action<DashboardStore, boolean>;
  fetchData: Thunk<
    DashboardStore,
    DashboardDataParameters,
    unknown,
    StoreModel
  >;
  fetchDashboardData: Thunk<
    DashboardStore,
    PricingDashboardDataParameters,
    unknown,
    StoreModel
  >;
  fetchCompetitors: Thunk<
    DashboardStore,
    PricingDashboardDataParameters,
    unknown,
    StoreModel
  >;
  updateSelectedCompetitors: Thunk<
    DashboardStore,
    PricingDashboardCompetitorsDataParameters,
    unknown,
    StoreModel
  >;
}

export const dashboardStore: DashboardStore = {
  dashboardLoading: false,
  includeReservations: true,
  updatingCompetitors: false,
  setData: action((state, newData: DashboardData) => {
    state.data = newData;
  }),
  setDashboardLoading: action((state, value) => {
    state.dashboardLoading = value;
  }),
  setUpdateData: action((state, value) => {
    state.updateData = value;
  }),
  setDashboardData: action((state, newData: PricingDashboardData) => {
    state.dashboardData = newData;
  }),
  setIncludeReservations: action((state, value) => {
    state.includeReservations = value;
  }),
  setUpdatingCompetitors: action((state, value) => {
    state.updatingCompetitors = value;
  }),
  fetchDashboardData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      dashboard: { setDashboardLoading },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    setDashboardLoading(true);
    try {
      const { data } = await getPricingDashboardData({
        ...payload,
      });
      actions.setDashboardData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setDashboardLoading(false);
      setIsLoaded(true);
    }
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    const {
      user: { profile },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    try {
      const { data } = await getDashboardData({
        ...payload,
        price_per_sm_calculation: profile?.price_per_sm_calculation,
      });
      actions.setData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
  updateSelectedCompetitors: thunk(async (actions, payload, helpers) => {
    actions.setUpdatingCompetitors(true);
    try {
      await updateSelectedCompetitors({ ...payload });
      await actions.fetchCompetitors({ projectId: payload.projectId });
    } catch (error) {
      logError(error);
    } finally {
      actions.setUpdatingCompetitors(false);
    }
  }),
  // TODO: this is only workaround to fetch competitors for now
  fetchCompetitors: thunk(async (actions, payload, helpers) => {
    try {
      const { data } = await getPricingDashboardData({
        ...payload,
      });
      actions.setDashboardData(data);
    } catch (error) {
      logError(error);
    }
  }),
};
