import { Thunk, Action, action, thunk } from 'easy-peasy';
import { StoreModel } from './types';
import { logError } from '../utils/utils';
import { getDailyNewsDashboardData, getDailyNewsData, getDailyNewsDataPartial, getDailyNewsGpsData } from '../api';
import { SubareaEnum } from '../api/enums';

export interface GraphData {
  city: string;
  available_today: number;
  available_last_month_pct_change: number;
  available_price_per_sm_today: number;
  available_price_per_sm_pct_change: number;
  available_timeline_price_per_sm: {
    availability: string;
    date: string;
    avg_price: number;
  }[];
  available_availability_timeline: {
    date: string;
    AVAILABLE: number;
  }[];
  sold_last_month: number;
  sold_last_month_pct_change: number;
  sold_price_per_sm_last_month: number;
  sold_price_per_sm_last_month_pct_change: number;
  sold_timeline_price_per_sm: {
    availability: string;
    date: string;
    avg_price: number;
  }[];
  sold_availability_timeline: {
    date: string;
    availability: string;
    value: number;
  }[];
}

interface AddedUnitObj {
  project_name: string,
  difference: number,
}

interface AddedUnit {
  [city_id: string]: AddedUnitObj[],
}

export interface AddedUnits {
  [date: string]: AddedUnit[];
}

export interface NewSoldOutProjects {
  [date: string]: {
    [id: number]: string;
  };
}

export interface TopSellersLastMonth {
  [date: string]: {
    [date: string]: {
      [city: string]: {
        [projectName: string]: number;
      };
    }[];
  };
}

export interface NewProjects {
  [date: string]: {
    [id: number]: string;
  };
}

export interface Discount {
  discount_text: string;
  project_id: number;
  date: string;
}

export interface DailyNewsData {
  graph_data: GraphData;
  new_sold_out_projects: NewSoldOutProjects;
  new_projects: NewProjects;
  top_sellers_last_month: TopSellersLastMonth;
  added_units: AddedUnits;
  discounts: Discount[];
}

export interface GpsProject {
  name: string;
  address: string | null;
  gps_latitude: number;
  gps_longitude: number;
}

interface GpsDailyNewsDataProject {
  [projectId: string]: GpsProject;
}

export interface GpsDailyNewsData {
  projects_coords: GpsDailyNewsDataProject;
}

export interface MappedGpsData {
  project_id: number;
  address: string;
  name: string;
  project_name: string;
  gps_latitude: number;
  gps_longitude: number;
  include: boolean;
}

export interface DailyNewsPartialData {
  city_only_graph_data: DailyNewsData['graph_data'];
  outskirts_only_graph_data: DailyNewsData['graph_data'];
  city_ids?: number[];
  outskirts_ids?: number[];
}

// Define a type for the segment key
type SegmentKey = keyof DailyNewsData;

interface Params<T extends SegmentKey> {
  segment: T;
  cityId: number;
}

interface DashboardParams<T extends SegmentKey> {
  segment: T;
  dashboardId?: number;
}

export interface DailyNewsStore {
  data?: Partial<DailyNewsData>;
  dashboardData?: Partial<DailyNewsData>;
  setData: Action<DailyNewsStore, Partial<DailyNewsData>>;
  setDashboardData: Action<DailyNewsStore, Partial<DailyNewsData>>;
  gpsData?: MappedGpsData[];
  setGpsData: Action<DailyNewsStore, MappedGpsData[]>;
  fetchGpsData: Thunk<DailyNewsStore, string, unknown, StoreModel>;
  selectedProjectId?: number;
  setSelectedProjectId: Action<DailyNewsStore, number>;
  selectedArea?: string;
  setSelectedArea: Action<DailyNewsStore, string>;
  fetchPartialData: Thunk<DailyNewsStore, number, unknown, StoreModel>;
  setPartialData: Action<DailyNewsStore, DailyNewsPartialData>;
  partialData?: DailyNewsPartialData;
  subarea?: SubareaEnum;
  setSubarea: Action<DailyNewsStore, SubareaEnum>;
  currentData?: Partial<DailyNewsData>;
  currentDashboardData?: Partial<DailyNewsData>;
  setCurrentData: Action<DailyNewsStore, Partial<DailyNewsData>>;
  setCurrentDashboardData: Action<DailyNewsStore, Partial<DailyNewsData>>;
  fetchBlockData: Thunk<DailyNewsStore, Params<SegmentKey>, unknown, StoreModel>;
  fetchBlockDashboardData: Thunk<DailyNewsStore, DashboardParams<SegmentKey>, unknown, StoreModel>;
}

export const dailyNewsStore: DailyNewsStore = {
  setData: action((state, newData: DailyNewsData) => {
    state.data = newData;
  }),
  setDashboardData: action((state, newData: DailyNewsData) => {
    state.dashboardData = newData;
  }),
  setGpsData: action((state, newData) => {
    state.gpsData = newData;
  }),
  fetchGpsData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const data = await getDailyNewsGpsData(payload);
      actions.setGpsData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
  setSelectedProjectId: action((state, id) => {
    state.selectedProjectId = id;
  }),
  setSelectedArea: action((state, project) => {
    state.selectedArea = project;
  }),
  fetchPartialData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const { data } = await getDailyNewsDataPartial(payload);
      actions.setPartialData(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
  setPartialData: action((state, data) => {
    state.partialData = data;
  }),
  setSubarea: action((state, area) => {
    state.subarea = area;
  }),
  setCurrentData: action((state, data) => {
    state.currentData = data;
  }),
  setCurrentDashboardData: action((state, data) => {
    state.currentDashboardData = data;
  }),
  fetchBlockData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
    } = helpers.getStoreActions();
    setGlobalLoading(true);
    try {
      const { cityId, segment } = payload;
      const { data } = await getDailyNewsData(cityId, segment);

      const oldData = helpers.getStoreState().dailyNews.data ?? {};
      const oldCurrentData = helpers.getStoreState().dailyNews.currentData ?? {};

      if (segment === 'graph_data' && data.graph_data) {
        const { city } = data.graph_data as GraphData;
        actions.setSelectedArea(city);
      }

      // Ensure that the data being merged is of the correct type
      const mergedData: Partial<DailyNewsData> = {
        ...oldData,
        [segment]: data[segment],
      };

      actions.setData(mergedData);
      actions.setCurrentData({
        ...oldCurrentData,
        [segment]: data[segment],
      });
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
    }
  }),
  // eslint-disable-next-line consistent-return
  fetchBlockDashboardData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading },
    } = helpers.getStoreActions();
    // setGlobalLoading(true);
    try {
      const { dashboardId, segment } = payload;
      if (!dashboardId) {
        return null;
      }
      const { data } = await getDailyNewsDashboardData(dashboardId, segment);

      const oldData = helpers.getStoreState().dailyNews.dashboardData ?? {};
      const oldCurrentData = helpers.getStoreState().dailyNews.currentDashboardData ?? {};

      // if (segment === 'graph_data' && data.graph_data) {
      //   const { city } = data.graph_data as GraphData;
      //   actions.setSelectedArea(city);
      // }

      // Ensure that the data being merged is of the correct type
      const mergedData: Partial<DailyNewsData> = {
        ...oldData,
        [segment]: data[segment],
      };

      actions.setDashboardData(mergedData);
      actions.setCurrentDashboardData({
        ...oldCurrentData,
        [segment]: data[segment],
      });
    } catch (error) {
      logError(error);
    } finally {
      // setGlobalLoading(false);
    }
  }),
};
