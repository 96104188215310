import { FC, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { BuildingOfficeIcon, ArrowPathIcon, KeyIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import { Page } from '../../components';
import { useBreakpoints, useStoreActions, useStoreState } from '../../hooks';
import { Reports } from '../../components/market';
import { MarketHeaderFilters } from '../../components/market/MarketDashboardFilters';
import {
  MarketDashboardBlockType,
  MarketDashboardTemplateEnum,
  MarketTypeEnum,
} from '../../api/enums';
import {
  PriceHistory,
  SizeComparison,
  SaleSpeed,
  MarketShare,
  PriceComparison,
  UnitMix,
  Overview,
  Map,
  Demand,
  Supply,
  SupplyHistory as SupplyHistoryPrimary,
  SaleDetail as SaleDetailPrimary,
  FloorOverview,
  DaysOnMarket as DaysOnMarketPrimary,
} from '../../components/market/blocks';
import { MarketDashboardOverview } from '../../api/types';
import { SupplyOverview, DemandOverview } from '../../components/secondaryMarket/blocks/Overview';
import {
  SaleSpeed as SecondarySaleSpeed,
  PriceHistory as SecondaryPriceHistory,
  SupplyHistory,
  UnitMix as SecondaryUnitMix,
  SaleDetail,
  DaysOnMarket,
  PriceComparison as SecondaryPriceComparison,
  SizeComparison as SecondarySizeComparison,
  Map as SecondaryMap,
  MarketShare as SecondaryMarketShare,
} from '../../components/secondaryMarket/blocks';
import { downloadPDF } from './pdfDownload';
import { getUpdateFrequencyDates, sortByString } from '../../utils/utils';
import { Mode } from '../../store/types';
import { useFilter } from '../../components/market/blocks/useFilter';
import { SubscribePage } from '../../components/SubscribePage/SubscribePage';

interface FilterProps {
    filterValue: string | number;
    handleChange: (value: string | number) => void;
}

const getComponent = (blockType: MarketDashboardBlockType, filterProps?: FilterProps): ReactNode => {
  switch (blockType) {
    case MarketDashboardBlockType.PRICE_HISTORY:
      return <PriceHistory />;
    case MarketDashboardBlockType.SIZE_COMPARISON:
      return <SizeComparison />;
    case MarketDashboardBlockType.SALE_SPEED:
      return <SaleSpeed />;
    case MarketDashboardBlockType.UNIT_MIX:
      return <UnitMix />;
    case MarketDashboardBlockType.MARKET_SHARE:
      return <MarketShare />;
    case MarketDashboardBlockType.PRICE_COMPARISON:
      return <PriceComparison />;
    case MarketDashboardBlockType.MAP:
      return <Map />;
    case MarketDashboardBlockType.OVERVIEW:
      return <Overview handleChangeExternalFilter={filterProps?.handleChange} filterExternalValue={filterProps?.filterValue} />;
    case MarketDashboardBlockType.DEMAND:
      return <Demand />;
    case MarketDashboardBlockType.SUPPLY:
        return <Supply />;
    case MarketDashboardBlockType.SUPPLY_HISTORY:
        return <SupplyHistoryPrimary />;
    case MarketDashboardBlockType.SALE_DETAIL:
        return <SaleDetailPrimary />;
    case MarketDashboardBlockType.FLOOR_OVERVIEW:
        return <FloorOverview />;
    case MarketDashboardBlockType.DAYS_ON_MARKET:
      return <DaysOnMarketPrimary />;
    default:
      return null;
  }
};

const getBlocks = (overviewData: MarketDashboardOverview | null, filterProps: FilterProps, pdfPage1Ref: RefObject<HTMLDivElement>, pdfPage2Ref?: RefObject<HTMLDivElement>, pdfPage3Ref?: RefObject<HTMLDivElement>): ReactNode => {
  if (overviewData && overviewData.blocks.length >= 5) {
    switch (overviewData.template_type) {
      case MarketDashboardTemplateEnum.ADVANCED:
        return (
          <Row ref={pdfPage1Ref} gutter={[10, 10]} style={{ width: '100%' }}>
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[0])}
            </Col>
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[1], filterProps)}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[2])}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[3])}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[4])}
            </Col>
          </Row>
        );
      case MarketDashboardTemplateEnum.BASIC:
        return (
          <Row ref={pdfPage1Ref} gutter={[10, 10]} style={{ width: '100%' }}>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[0])}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[1], filterProps)}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[2])}
            </Col>
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[3])}
            </Col>
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[4])}
            </Col>
          </Row>
        );
      case MarketDashboardTemplateEnum.COMPLETE:
        return (
          <>
          <Row ref={pdfPage1Ref} gutter={[10, 10]} style={{ width: '100%' }}>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[0])}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(MarketDashboardBlockType.SUPPLY)}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(MarketDashboardBlockType.DEMAND)}
            </Col>
            {/* <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[1], filterProps)}
            </Col> */}
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[3])}
            </Col>
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[4])}
            </Col>
          </Row>
          <Row ref={pdfPage2Ref} gutter={[10, 10]} style={{ width: '100%' }}>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(MarketDashboardBlockType.SUPPLY_HISTORY)}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[2])}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(MarketDashboardBlockType.SALE_DETAIL)}
            </Col>
            <Col xs={24} lg={12}>
              {getComponent(MarketDashboardBlockType.DAYS_ON_MARKET)}
            </Col>
            <Col xs={24} lg={12}>
              {getComponent(overviewData.blocks[6])}
            </Col>
          </Row>
          <Row ref={pdfPage3Ref} gutter={[10, 10]} style={{ width: '100%' }}>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[5])}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(MarketDashboardBlockType.FLOOR_OVERVIEW)}
            </Col>
            <Col xs={24} lg={12} xxl={8}>
              {getComponent(overviewData.blocks[7])}
            </Col>
          </Row>
          </>
        );
      default:
        return null;
    }
  }
  return null;
};

const getSecondaryBlocks = (pdfPage1Ref: RefObject<HTMLDivElement>, pdfPage2Ref: RefObject<HTMLDivElement>, pdfPageRef3: RefObject<HTMLDivElement>): ReactNode => (
  <>
    <Row ref={pdfPage1Ref} style={{ width: '100%' }} gutter={[10, 10]}>
    <Col xs={24} lg={12} xxl={8}>
      <SecondaryMap />
    </Col>
    <Col xs={24} lg={12} xxl={8}>
      <SupplyOverview />
    </Col>
    <Col xs={24} lg={12} xxl={8}>
      <DemandOverview />
    </Col>
    <Col xs={24} lg={12}>
      <SecondarySaleSpeed />
    </Col>
    <Col xs={24} lg={12}>
      <SecondaryPriceHistory />
    </Col>
    </Row>
    <Row ref={pdfPage2Ref} style={{ width: '100%' }} gutter={[10, 10]}>
    <Col xs={24} lg={12} xxl={8}>
      <SupplyHistory />
    </Col>
    <Col xs={24} lg={12} xxl={8}>
      <SecondaryUnitMix />
    </Col>
    <Col xs={24} lg={12} xxl={8}>
      <SaleDetail />
    </Col>
    <Col xs={24} lg={12}>
      <DaysOnMarket />
    </Col>
    <Col xs={24} lg={12}>
      <SecondaryMarketShare />
    </Col>
    </Row>
    <Row ref={pdfPageRef3} style={{ width: '100%' }} gutter={[10, 10]}>
    <Col xs={24} lg={12}>
      <SecondaryPriceComparison />
    </Col>
    <Col xs={24} lg={12}>
      <SecondarySizeComparison />
    </Col>
    </Row>
  </>
);

type MarketDashboardSubMenuItemProps = {
  icon: ReactNode;
  title: ReactNode;
  link: string;
  reportType: MarketTypeEnum
  disabled?: boolean
}

const MarketDashboardSubMenuItem: FC<MarketDashboardSubMenuItemProps> = ({
  icon,
  title,
  link,
  reportType,
  disabled = false,
}) => {
  const setActiveReportId = useStoreActions((actions) => actions.secondaryMarket.setActiveReportId);
  const reports = useStoreState((state) => state.secondaryMarket.reports);
  const paramsSM = useStoreState((state) => state.filters.paramsSM);

  const handleClick = (): void => {
    if (paramsSM && reports) {
      switch (reportType) {
        case MarketTypeEnum.SECONDARY:
          setActiveReportId(
            reports.find((r) => r.name === paramsSM.name && r.reportType === 'sale')?.reportId || '',
          );
          break;
        case MarketTypeEnum.RENTAL:
          setActiveReportId(
            reports.find((r) => r.name === paramsSM.name && r.reportType === 'rent')?.reportId || '',
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    disabled ? (
      <div className='reports-submenu-item disabled select-none'>
        <div className='icon'>{icon}</div>
        <div className='title'>{title}</div>
        <div className='icon'>
          <LockClosedIcon className='w-8' />
        </div>
      </div>
    ) : (
      <NavLink to={link} onClick={handleClick} className={({ isActive }) => (isActive ? 'active' : '')} end aria-label={reportType}>
        <div className='reports-submenu-item'>
          <div className='icon'>{icon}</div>
          <div className='title'>{title}</div>
        </div>
      </NavLink>
    )
  );
};

type MarketDashboardSubMenuProps = {
  items: MarketDashboardSubMenuItemProps[];
};

const MarketDashboardSubMenu: FC<MarketDashboardSubMenuProps> = ({ items }) => (
  <Row className='reports-submenu'>
    {
      items.map((item) => (
        <MarketDashboardSubMenuItem
          key={item.reportType}
          title={item.title}
          link={item.link}
          icon={item.icon}
          reportType={item.reportType}
          disabled={item.disabled}
        />
      ))
    }
  </Row>
);

enum FilterEnum {
  AVAILABLE,
  SOLD,
}

export const MarketDashboard: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const [isLoadingDownload, setIsLoadingDownload] = useState(true);
  const isMarketEnabled = useStoreState((state) => state.user.profile?.market_enabled);
  const dashboardOverviewData = useStoreState(
    (state) => state.market.dashboardOverviewData,
  );
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const fetchDashboardOverview = useStoreActions(
    (actions) => actions.market.fetchDashboardOverview,
  );
  const fetchOverviewData = useStoreActions(
    (actions) => actions.filters.fetchOverviewData,
  );
  const reportId = useStoreState(
    (state) => state.secondaryMarket.activeReportId,
  );
  const { fetchSupplyOverview } = useStoreActions(
    (actions) => actions.secondaryMarket,
  );
  const reportsHierarchy = useStoreState(
    (state) => state.user.profile?.reports_hierarchy,
  );
  const marketDashboards = useStoreState((state) => state.filters.overviewData?.market_dashboards);
  const secondaryReports = useStoreState((state) => state.secondaryMarket.reports);
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { market_dashboard_frequency: frequency = 'daily' } =
    overviewData || {};
  const isStandardPlan =
    frequency === 'weekly' ||
    frequency === 'daily' ||
    frequency === null;
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const setSelectedReportName = useStoreActions(
    (actions) => actions.filters.setSelectedReportName,
  );
  const resetPolygons = useStoreActions(
    (actions) => actions.market.resetPolygons,
  );
  const fetchDashboardSettings = useStoreActions(
    (actions) => actions.market.fetchDashboardSettings,
  );
  const setParamsPM = useStoreActions((actions) => actions.filters.setParamsPM);
  const setParamsSM = useStoreActions((actions) => actions.filters.setParamsSM);
  const { filterValue, handleChange } = useFilter(FilterEnum.AVAILABLE);

  const filterProps: FilterProps = {
    filterValue,
    handleChange,
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pageRef1 = useRef(null);
  const pageRef2 = useRef(null);
  const pageRef3 = useRef(null);

  useEffect(() => {
    if (!paramsPM && !paramsSM) {
      fetchOverviewData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const marketType = useMemo(() => {
    const splittedPath = pathname.split('/');
    const lastPath = splittedPath[splittedPath.length - 1];
    switch (lastPath) {
      case 'secondary':
        return lastPath;
      case 'rentals':
        return lastPath;
      default:
        return 'primary';
    }
  }, [pathname]);

  const reportName = useMemo(() => {
    if (paramsSM) {
      return paramsSM.name;
    }
    if (paramsPM) {
      return paramsPM.name;
    }
    return '';
  }, [paramsPM, paramsSM]);

  const reportExists = marketDashboards?.find((d) => d.name === reportName) || secondaryReports?.find((r) => r.name === reportName);

  const MENU_ITEMS: MarketDashboardSubMenuItemProps[] = useMemo(() => [
    {
      reportType: MarketTypeEnum.PRIMARY,
      icon: <BuildingOfficeIcon />,
      title: t('reports.topbar.primary'),
      link: '/market/reports',
    },
    {
      reportType: MarketTypeEnum.SECONDARY,
      icon: <ArrowPathIcon />,
      title: t('reports.topbar.secondary'),
      link: '/market/reports/secondary',
      disabled: !isStandardPlan,
    },
    {
      reportType: MarketTypeEnum.RENTAL,
      icon: <KeyIcon />,
      title: t('reports.topbar.rentals', 'Rentals'),
      link: '/market/reports/rentals',
      disabled: !isStandardPlan,
    },
  ], [isStandardPlan, t]);

  const validReportTypes = useMemo(() => {
    const reportTypes: MarketTypeEnum[] = [];
    if (paramsPM) {
      reportTypes.push(MarketTypeEnum.PRIMARY);
    }
    if (paramsSM) {
      reportTypes.push(...paramsSM.reportTypes);
    }
    return reportTypes;
  }, [paramsPM, paramsSM]);

  useEffect(() => {
    if (isMarketEnabled) {
      if ((paramsPM || paramsSM) && reportExists) {
        setIsLoadingDownload(true);
        fetchDashboardOverview(paramsPM);
      }
      // We have a lot of data to load, so we need to implement artificial wait time before user can export canvas with PDF.
      setTimeout(() => {
        setIsLoadingDownload(false);
      }, marketType === 'primary' ? 10000 : 5000);
    }
  }, [paramsPM?.dashboardId, paramsSM, fetchDashboardOverview, marketType, reportExists?.name, isMarketEnabled]);

  useEffect(() => {
    if (isMarketEnabled) {
      if (reportId && paramsSM) {
        fetchSupplyOverview({ reportId, date: paramsSM.date || new Date().toISOString().split('T')[0] });
      }
    }
  }, [fetchSupplyOverview, isMarketEnabled, paramsSM, reportId]);

  useEffect(() => {
    const dashboard = marketDashboards?.[0];
    if (!reportExists && dashboard) {
      const validDates = dashboard?.dates && getUpdateFrequencyDates(dashboard.dates.sort(sortByString), frequency);
      const primaryInitialDate = validDates && validDates[validDates.length - 1];
      setMode(Mode.READ);
      resetPolygons();
      setSelectedReportName(dashboard.name);
      setParamsSM(null);
      setParamsPM({
        dashboardId: dashboard.id,
        name: dashboard.name,
        date: primaryInitialDate,
      });
      fetchDashboardSettings({
        dashboardId: dashboard.id,
        date: primaryInitialDate,
      });
    }
  });

  const fileName = `bm-${marketType}-market`;

  return (
    // eslint-disable-next-line no-nested-ternary
    isMarketEnabled ? (
      reportExists ? (
    <Page
      pageClassname='market-dashboard'
      subMenu={
        !isSmallScreen &&
        <MarketDashboardSubMenu
          items={MENU_ITEMS?.filter((item) => validReportTypes.includes(item.reportType))}
        />
      }
      title={
        <div className='reports-title'>
          <span>{t('market.submenu.reports', 'Reports')}</span>
          <span>/</span>
          <span className='active'>{reportName}</span>
        </div>
      }
      filters={
        !isSmallScreen &&
        <MarketHeaderFilters
          onDownloadPDF={() =>
            downloadPDF(fileName, pageRef1, pageRef2, pageRef3)}
          isLoadingDownload={isLoadingDownload}
          filterValue={filterValue}
        />
      }
    >
      <div className='report-container'>
        {reportsHierarchy === 'page' && (
          <Reports
            showAddReport
            showActions
            onEditClick={() => navigate('/market/projects')}
          />
        )}
        <Row gutter={[10, 10]}>
          {marketType === 'primary'
            ? getBlocks(dashboardOverviewData, filterProps, pageRef1, pageRef2, pageRef3)
            : getSecondaryBlocks(pageRef1, pageRef2, pageRef3)}
        </Row>
      </div>
    </Page>
    ) : (
        <div style={{ display: 'flex', height: '100%', flex: 1, alignItems: 'center', justifyContent: 'center' }}>This report was deleted.</div>
    )
  ) : (
    <Page
      pageClassname='market-dashboard'
      title={
        <span>{t('market.submenu.reports', 'Reports')}</span>
      }
    >
      <SubscribePage featureType='market' />
    </Page>
  )
  );
};
