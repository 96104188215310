import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RowData, InfoPopover } from '../../../../../InfoPopover';
import { useRegionFormatting } from '../../../../../../hooks';

interface Props {
  parking_indoor_price?: number | null;
  parking_outdoor_price?: number | null;
}

export const Parking: FC<Props> = ({
  parking_indoor_price,
  parking_outdoor_price,
}) => {
  const { formatCurrency } = useRegionFormatting();

  if (!parking_indoor_price && !parking_outdoor_price) {
    return <span className='font-bold'>-</span>;
  }

  return (
    <span className='font-bold'>{`${
      parking_outdoor_price ? formatCurrency(parking_outdoor_price) : ''
    }${parking_indoor_price && parking_outdoor_price ? ' - ' : ''}${
      parking_indoor_price ? formatCurrency(parking_indoor_price) : ''
      }`}
    </span>
  );
};
