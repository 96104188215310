/** @jsxImportSource @emotion/react */
import { FC, PropsWithChildren, useState } from 'react';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { Modal, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { styles as customStyles } from './styles';

export const Zoom: FC<PropsWithChildren> = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <div css={customStyles.container}>
      <ArrowsPointingOutIcon
        className='w-10 stroke-2 cursor-pointer'
        onClick={handleOpen}
      />
      <Modal
        className='bm-modal'
        css={customStyles.modal}
        open={isOpen}
        footer={null}
        onCancel={handleClose}
      >
        {children}
      </Modal>
    </div>
  );
};
