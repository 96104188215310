import { useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';
import { Mode } from '../../store/types';
import { Paths } from '../../routes/Paths';
import { CenteredSpinner } from '../CenteredSpinner';

export const ReportInProgress: FC = () => {
  const { setMode } = useStoreActions((actions) => actions.market);
  const { mode } = useStoreState((state) => state.market);
  const setActiveStep = useStoreActions(
    (actions) => actions.market.setAddReportStep,
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(
    () => () => {
      setActiveStep(null);
      setMode(Mode.READ);
      navigate(Paths.MARKET_PROJECTS);
    },
    [],
  );

  return (
    <div className='flex gap-4 flex-nowrap items-center justify-center w-[700px] h-[300px] border-[1px] bg-white rounded-[20px]'>
      <div>
        <CenteredSpinner />
      </div>
      <span className='text-bmblue font-bold'>
        {mode === Mode.CREATE
          ? t(
              'market.reports.report_being_generated',
              'Your report is being generated...',
            )
          : t(
              'market.reports.report_being_updated',
              'Your report is being updated...',
            )}
      </span>
    </div>
  );
};
