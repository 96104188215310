import { FC, MouseEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Button, message, Popconfirm, Popover } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../../hooks';
import { Mode } from '../../../store/types';
import { getUpdateFrequencyDates, sortByString } from '../../../utils/utils';
import { Card } from '../../Card';
import { defaultTableData } from '../../../store/market';
import { MergedReport } from '.';
import { MarketTypeEnum } from '../../../api/enums';

interface ReportProps {
  showActions?: boolean;
  value: MergedReport;
  onEditClick?: () => void;
}

export const Report: FC<ReportProps> = ({
  value: { name, primary, secondary, rental },
  showActions,
  onEditClick,
}) => {
  const selectedReportName = useStoreState(
    (state) => state.filters.selectedReportName,
  );
  const projects = useStoreState((state) => state.market.simplifiedProjects);
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { market_dashboard_frequency: frequency = 'daily' } =
    overviewData || {};
  const mode = useStoreState((state) => state.market.mode);
  const { t } = useTranslation();
  const setParamsPM = useStoreActions((actions) => actions.filters.setParamsPM);
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setParamsSM = useStoreActions((actions) => actions.filters.setParamsSM);
  const setSelectedReportName = useStoreActions(
    (actions) => actions.filters.setSelectedReportName,
  );
  const deleteDashboard = useStoreActions(
    (actions) => actions.market.deleteDashboard,
  );
  const fetchDashboardOverview = useStoreActions(
    (actions) => actions.market.fetchDashboardOverview,
  );
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const { resetPolygons, resetCircles } = useStoreActions(
    (actions) => actions.market,
  );
  const setTableData = useStoreActions(
    (actions) => actions.market.setTableData,
  );
  const setActiveReportId = useStoreActions(
    (actions) => actions.secondaryMarket.setActiveReportId,
  );

  const navigate = useNavigate();
  const location = useLocation();

  const validDates =
    primary?.dates &&
    getUpdateFrequencyDates(primary.dates.sort(sortByString), frequency);
  const primaryInitialDate = validDates && validDates[validDates.length - 1];

  const handleReportClick = (): void => {
    setMode(Mode.READ);
    resetPolygons();
    resetCircles();
    if (primary && (!primary.dates || primary.dates.length === 0)) {
      message.error(
        t('market.error', 'Data is not ready yet, try again later.'),
      );
      return;
    }

    setSelectedReportName(name);
    if (primaryInitialDate && primary) {
      setParamsPM({ dashboardId: primary.id, date: primaryInitialDate, name });
    } else {
      setParamsPM(null);
    }

    const reportTypes = [
      ...(primary ? [MarketTypeEnum.PRIMARY] : []),
      ...(secondary ? [MarketTypeEnum.SECONDARY] : []),
      ...(rental ? [MarketTypeEnum.RENTAL] : []),
    ];

    if (secondary?.reportId || rental?.reportId) {
      setParamsSM({
        reportId: secondary?.reportId || rental?.reportId || '',
        name,
        reportTypes,
        date: paramsSM?.date || new Date().toISOString().split('T')[0],
      });
      setActiveReportId(secondary?.reportId || rental?.reportId || '');
    } else {
      setParamsSM(null);
    }

    if (location.pathname === '/market/projects') return;

    if (reportTypes.includes(MarketTypeEnum.PRIMARY)) {
      navigate('/market/reports');
    } else if (reportTypes.includes(MarketTypeEnum.SECONDARY)) {
      navigate('/market/reports/secondary');
    } else if (reportTypes.includes(MarketTypeEnum.RENTAL)) {
      navigate('/market/reports/rentals');
    }
  };

  const handleEdit = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    if (onEditClick) onEditClick();
    setSelectedReportName(name);
    resetPolygons();
    resetCircles();
    let error = false;
    if (secondary || rental) {
      const types: MarketTypeEnum[] = [];
      if (secondary) {
        types.push(MarketTypeEnum.SECONDARY);
      } else {
        types.push(MarketTypeEnum.RENTAL);
      }
      setParamsSM({
        reportId: secondary?.reportId || rental?.reportId || '',
        name: secondary?.name || rental?.name || '',
        reportTypes: types,
      });
    }
    if (primaryInitialDate && primary) {
      setParamsPM({ dashboardId: primary.id, date: primaryInitialDate, name });
      fetchDashboardOverview({
        dashboardId: primary.id,
        date: primaryInitialDate,
      });
      setPrimaryParameters({
        included_project_ids: [],
        remaining_project_ids: [],
        dashboard_name: name,
        geometry: null,
        include_new_projects: true,
        markets: [MarketTypeEnum.PRIMARY],
      });
      setTableData({ ...defaultTableData, filteredData: projects });
    } else {
      error = true;
      message.error(
        t('market.error', 'Data is not ready yet, try again later.'),
      );
    }
    if ((primary || secondary) && !error) {
      setMode(Mode.EDITSTART);
    }
  };
  const handleDelete = async (e?: MouseEvent<HTMLElement>): Promise<void> => {
    if (e) {
      e.stopPropagation();
      await deleteDashboard({
        primaryId: primary?.id,
        secondaryId: secondary?.reportId,
        rentId: rental?.reportId,
      });
    }
  };

  const handlePropagation = (e?: MouseEvent): void => {
    e?.stopPropagation();
  };

  return (
    <Card
      className={clsx('report-card', {
        selected: mode !== Mode.CREATE && selectedReportName === name,
      })}
      onClick={handleReportClick}
    >
      {showActions && !primary?.default && (
        <Popover
          overlayClassName='report-actions'
          placement='bottom'
          content={
            <div className='actions'>
              <div>
                <Button className='item' type='text' onClick={handleEdit}>
                  {t('common.edit')}
                </Button>
              </div>
              <div>
                <Popconfirm
                  title={t('market.projects.report.confirm_message')}
                  okText={t('common.yes')}
                  cancelText={t('common.no')}
                  placement='bottom'
                  icon={<ExclamationCircleTwoTone twoToneColor='#FF0000' />}
                  onConfirm={handleDelete}
                  onCancel={handlePropagation}
                >
                  <Button
                    className='item'
                    type='text'
                    onClick={handlePropagation}
                  >
                    {t('common.delete')}
                  </Button>
                </Popconfirm>
              </div>
            </div>
          }
        >
          <div
            role='button'
            tabIndex={0}
            className='actions-dots'
            onClick={handlePropagation}
          >
            &middot;&middot;&middot;
          </div>
        </Popover>
      )}
      <div className='title'>{name}</div>
      {primary?.projects_count && (
        <div className='subtitle'>
          {t('market.reports.map.projects_count', {
            count: primary.projects_count,
          })}
        </div>
      )}
      {primary?.default && <div className='area'>Area</div>}
    </Card>
  );
};
