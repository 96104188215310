import { FC } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { ArrowsPointingOutIcon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';

type MaximizeProps = {
  open: boolean;
  className?: string;
};

export const MaximizeOption: FC<MaximizeProps> = ({ open, className }) => (
  <div className={clsx(className, 'flex items-center')}>
    <Dialog.Trigger>
      <div role='button' tabIndex={0}>
        {!open ? (
          <ArrowsPointingOutIcon className='stroke-2 w-10 h-10' />
        ) : (
          <XMarkIcon className='stroke-2 w-10 h-10 text-[#DBDBDB]' />
        )}
      </div>
    </Dialog.Trigger>
  </div>
);
