import { FC, useMemo } from 'react';
import { MarketProjectExtended } from '../../../../../../store/types';
import { formatPercent } from '../../../../../../utils';

type Props = Pick<
  MarketProjectExtended,
  'payment_construction' | 'payment_contract' | 'payment_occupancy'
>;

export const Payments: FC<Props> = ({
  payment_construction,
  payment_contract,
  payment_occupancy,
}) => {
  const label = useMemo(
    () =>
      [payment_contract, payment_construction, payment_occupancy]
        .map((v) => (v !== null && v !== 0 ? formatPercent(v) : v))
        .filter((v) => v !== null && v !== 0)
        .join(' - '),
    [payment_construction, payment_contract, payment_occupancy],
  );
  return (
    <span className='font-bold'>{label}</span>
  );
};
