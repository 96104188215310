import { useCallback } from 'react';
import { IFormatNumberOptions } from 'format-number';
import { t } from 'i18next';
import { CountryEnum, CurrencyEnum, MetricSystemEnum } from '../api/enums';
import { useStoreState } from '.';
import { VAT_RULES, VatRuleValue } from '../constants';
import {
  getLayoutOrderEU,
  getLayoutOrderUS,
  Layout,
  formatNumber,
} from '../utils';
import { Project } from '../api/mockData/projectData';
import { MarketProjectExtended } from '../store/types';

enum AreaUnit {
  SM = 'SM',
  SF = 'SF',
}

const getAreaUnit = (metric: MetricSystemEnum): string => {
  switch (metric) {
    case MetricSystemEnum.IMPERIAL:
      return t('enums.metric.SF', 'SF');
    case MetricSystemEnum.METRIC:
      return t('enums.metric.SM', 'SM');
    default:
      return t('enums.metric.SM', 'SM');
  }
};

export interface Formatters {
  formatCurrency: (
    value: number | null,
    perAreaUnit?: boolean,
    shortenMillions?: boolean,
    shortenThousands?: boolean,
  ) => string | null;
  formatAreaUnits: (value: number | null, noSuffix?: boolean) => string | null;
  calculateVatPrice: (
    value: number | null,
    floor_area: number | null,
    exterior_area?: number | null,
    override_VAT_included?: boolean | undefined,
  ) => number | null;
  areaUnit: string;
  isUsRegion: boolean;
  sortByLayouts: <T extends Layout>(array: T[]) => T[];
  sortLayouts: (a: string, b: string) => number;
  getCurrency: (symbol?: boolean) => string;
  getMinMaxProjectPrice: (data: Project | MarketProjectExtended | null) => { minPrice: number, maxPrice: number };
  getVATText: (boolReturn?: boolean) => string | boolean;
}

export const useRegionFormatting = (): Formatters => {
  const profile = useStoreState(
    (state) =>
      state.user.profile ?? {
        currency: CurrencyEnum.EUR,
        system_of_measurement: MetricSystemEnum.METRIC,
        country: CountryEnum.SLOVAKIA,
        VAT_included: true,
      },
  );
  const { currency, system_of_measurement, country, VAT_included } = profile;
  const areaUnit = getAreaUnit(system_of_measurement);
  const isUsRegion = areaUnit === AreaUnit.SF;
  const calculateVatPrice = useCallback(
    (
      value: number | null,
      floor_area: number | null,
      exterior_area?: number | null,
      override_VAT_included?: boolean | undefined,
    ): number | null => {
      if (!value) {
        return null;
      }
      const area = country === CountryEnum.CZECHIA
        ? floor_area ?? 0
        : (floor_area ?? 0) + (exterior_area ?? 0);
      const getRule = (arr: VatRuleValue[]): VatRuleValue => {
        if (!area || area === null) {
          return arr[1];
        }
        let numArea = Number(area);
        if (numArea === 0) {
          numArea = 1;
        }
        return arr.reduce((acc, obj) =>
          Math.abs(numArea - obj.rule) < Math.abs(numArea - acc.rule)
            ? obj
            : acc,
        );
      };

      if (override_VAT_included ?? VAT_included) {
        return value;
      }
      let rule: VatRuleValue;
      switch (country) {
        case CountryEnum.CZECHIA:
          rule = getRule(VAT_RULES[CountryEnum.CZECHIA]);
          break;
        case CountryEnum.SLOVAKIA:
          rule = getRule(VAT_RULES[CountryEnum.SLOVAKIA]);
          break;
        case CountryEnum.UK:
          rule = getRule(VAT_RULES[CountryEnum.UK]);
          break;
        case CountryEnum.LATVIA:
          rule = getRule(VAT_RULES[CountryEnum.LATVIA]);
          break;
        case CountryEnum.GERMANY:
          rule = getRule(VAT_RULES[CountryEnum.GERMANY]);
          break;
        case CountryEnum.AUSTRIA:
          rule = getRule(VAT_RULES[CountryEnum.AUSTRIA]);
          break;
        case CountryEnum.CROATIA:
          rule = getRule(VAT_RULES[CountryEnum.CROATIA]);
          break;
        default:
          rule = getRule(VAT_RULES[CountryEnum.US]);
          break;
      }
      return value / (rule.value ?? 1);
    },
    [country, VAT_included],
  );
  const formatCurrency = useCallback(
    (value: number | null | undefined, perAreaUnit = false, shortenMillions = false, shortenThousands = false) => {
      if (value === null || value === undefined || value === 0) {
        return null;
      }
      value = shortenMillions ? value / 1000000 : value;
      value = shortenThousands ? value / 1000 : value;
      // eslint-disable-next-line no-nested-ternary
      const shorten = shortenMillions ? 'm' : shortenThousands ? 'k' : '';
      const formatOptions: IFormatNumberOptions = {};
      switch (currency) {
        case CurrencyEnum.USD:
          formatOptions.integerSeparator = ',';
          formatOptions.suffix = `${shorten}${
            perAreaUnit ? ` / ${areaUnit}` : ''
          }`;
          formatOptions.prefix = '$';
          break;
        case CurrencyEnum.CZK:
          formatOptions.integerSeparator = ' ';
          formatOptions.suffix = `${shorten} Kč${
            perAreaUnit ? ` / ${areaUnit}` : ''
          }`;
          break;
        case CurrencyEnum.EUR:
          formatOptions.integerSeparator = ' ';
          formatOptions.suffix = `${shorten} €${
            perAreaUnit ? ` / ${areaUnit}` : ''
          }`;
          break;
        case CurrencyEnum.GBP:
          formatOptions.integerSeparator = ',';
          formatOptions.suffix = `${shorten}${
            perAreaUnit ? ` / ${areaUnit}` : ''
          }`;
          formatOptions.prefix = '£';
          break;
        default:
          break;
      }

      return formatNumber(value, formatOptions);
    },
    [areaUnit, currency],
  );
  const formatAreaUnits = useCallback(
    (value: number | null, noSuffix = false): string | null => {
      if (value === null) {
        return null;
      }
      return formatNumber(value, {
        suffix: !noSuffix ? ` ${areaUnit}` : undefined,
        integerSeparator: isUsRegion ? ',' : ' ',
      });
    },
    [areaUnit, isUsRegion],
  );

  const sortLayouts = useCallback(
    (a: string, b: string): number => {
      const getLayoutOrder = isUsRegion ? getLayoutOrderUS : getLayoutOrderEU;
      return getLayoutOrder(a) - getLayoutOrder(b);
    },
    [isUsRegion],
  );

  function sortByLayouts<T extends Layout>(array: T[]): T[] {
    return array.sort(({ layout: a }, { layout: b }) => sortLayouts(a, b));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getCurrency = (symbol?: boolean) => {
    switch (currency) {
      case CurrencyEnum.USD:
        return symbol ? '$' : 'USD';
      case CurrencyEnum.EUR:
        return symbol ? '€' : 'EUR';
      case CurrencyEnum.GBP:
        return symbol ? '£' : 'GBP';
      default:
        return 'CZK';
    }
  };

  type PriceListItem = {
    price_per_sm: number | null;
    floor_area: number;
    exterior_area: number | null;
};

  const getMinMaxProjectPrice = (data: Project | MarketProjectExtended | null): { minPrice: number, maxPrice: number } => {
    if (!data || !data.price_list || data.price_list.length === 0) {
        return { minPrice: 0, maxPrice: 0 };
    }

    const { minPriceUnit, maxPriceUnit } = data.price_list.reduce((acc: { minPriceUnit: PriceListItem | null, maxPriceUnit: PriceListItem | null }, item: PriceListItem) => {
        if (item.price_per_sm !== null) {
            if (!acc.minPriceUnit || (acc.minPriceUnit.price_per_sm !== null && item.price_per_sm < acc.minPriceUnit.price_per_sm)) {
                acc.minPriceUnit = item;
            }
            if (!acc.maxPriceUnit || (acc.maxPriceUnit.price_per_sm !== null && item.price_per_sm > acc.maxPriceUnit.price_per_sm)) {
                acc.maxPriceUnit = item;
            }
        }
        return acc;
    }, { minPriceUnit: null, maxPriceUnit: null });

    const minPrice = minPriceUnit ? calculateVatPrice(minPriceUnit.price_per_sm!, minPriceUnit.floor_area, minPriceUnit.exterior_area ?? 0) : 0;
    const maxPrice = maxPriceUnit ? calculateVatPrice(maxPriceUnit.price_per_sm!, maxPriceUnit.floor_area, maxPriceUnit.exterior_area ?? 0) : 0;

    return { minPrice: minPrice ?? 0, maxPrice: maxPrice ?? 0 };
  };

  const getVATText = (): string => profile.VAT_included ? '_with_vat' : '_without_vat';

  return {
    formatCurrency,
    formatAreaUnits,
    calculateVatPrice,
    areaUnit,
    isUsRegion,
    sortByLayouts,
    sortLayouts,
    getCurrency,
    getMinMaxProjectPrice,
    getVATText,
  };
};
