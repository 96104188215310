import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../hooks';
import { OverviewLoading } from '../OverviewLoading';
import { REVAMP_ALLOWED_DEVELOPERS } from '../../constants';

export const PriceListDetailFilters: FC = () => {
  const { projectId, phaseId, overviewData } = useStoreState(
    (state) => state.filters,
  );
  const projects = overviewData?.projects ?? [];
  const { t } = useTranslation();
  const currentProject = projects.find(
    (value) => value.project_id === projectId,
  );
  const developerId =
    useStoreState((state) => state.user.profile?.developer_id) ?? 0;
  const currentPhase = currentProject?.phases.find(
    (value) => value.phase_id === phaseId,
  );
  return (
    <>
      <div>
        {t('header.project')}:{' '}
        <OverviewLoading>
          <b>{currentProject?.name}</b>
        </OverviewLoading>
      </div>
      {!REVAMP_ALLOWED_DEVELOPERS.includes(developerId) && (
        <div>
          {t('header.phase')}:{' '}
          <OverviewLoading>
            <b>{currentPhase?.phase_name}</b>
          </OverviewLoading>
        </div>
      )}
    </>
  );
};
