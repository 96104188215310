import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SegmentedValue } from 'antd/es/segmented';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { Mode } from '../../store/types';
import { ProjectsTable } from '../market/ProjectsTable';
import { ColumnFiltersList } from '../ColumnFiltersList/ColumnFiltersList';
import { onSaveColumnFilters } from '../ColumnFiltersList/columnFilters';
import { Card } from '../Card';
import './ExtendedProjectsTable.css';

const TABLE_HEADER_HEIGHT = 120;

enum ColumnSwitchValue {
  PRICE,
  PRICE_PER_SM,
}

type Props = {
  open: boolean;
  handleModalClose: () => void;
};

export const ExtendedProjectTable: FC<Props> = ({ open, handleModalClose }) => {
  const isMaximized = useStoreState((state) => state.market.isMaximized);
  const { areaUnit } = useRegionFormatting();
  const showPricePerSM = useStoreState((state) => state.market.showPricePerSM);

  const setShowPricePerSM = useStoreActions(
    (actions) => actions.market.setShowPricePerSM,
  );
  const { t } = useTranslation();
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);

  const [columnDataIndexes, setColumnDataIndexes] = useState<string[]>([]);
  const [columnsFilterValues, setColumnFilterValues] = useState<string[]>([]);
  const cardRef = useRef<HTMLDivElement>(null); // Reference for the Card element
  const [cardHeight, setCardHeight] = useState<number | undefined>(undefined); // State to store the Card's height

  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.offsetHeight - TABLE_HEADER_HEIGHT);
    }
  }, [cardRef.current]);

  useEffect(() => {
    setColumnDataIndexes([]);
  }, [isMaximized]);

  const onChangeFilter = (value: string): void => {
    if (columnsFilterValues.includes(value)) {
      setColumnFilterValues(columnsFilterValues.filter((val) => val !== value));
    } else {
      if (columnDataIndexes.length - columnsFilterValues.length === 1) return;
      setColumnFilterValues((prev) => [...prev, value]);
    }
  };

  const onSaveFilters = (): void => {
    onSaveColumnFilters(
      'projectsDataIndexes',
      columnDataIndexes,
      'projectsFilters',
      columnsFilterValues,
    );
    setIsColumnFilterVisible(false);
  };

  const onResetFilters = (): void => {
    localStorage.removeItem('projectsDataIndexes');
    localStorage.removeItem('projectsFilters');
    setColumnFilterValues([]);
  };

  const titleExtra = useMemo(() => {
    const handleChange = (value: SegmentedValue): void => {
      setShowPricePerSM(value === ColumnSwitchValue.PRICE_PER_SM);
    };
    return (
      <div className='title-extra'>
        <div className='segmented-switch'>
          <Segmented
            value={
              showPricePerSM
                ? ColumnSwitchValue.PRICE_PER_SM
                : ColumnSwitchValue.PRICE
            }
            options={[
              {
                value: ColumnSwitchValue.PRICE,
                label: t('common.price', 'Price'),
              },
              {
                value: ColumnSwitchValue.PRICE_PER_SM,
                label: t('common.price_per_unit', 'Price per {{unit}}', {
                  unit: areaUnit,
                }),
              },
            ]}
            onChange={handleChange}
          />
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    areaUnit,
    isColumnFilterVisible,
    isMaximized,
    setShowPricePerSM,
    showPricePerSM,
    t,
  ]);

  const FilterTitleButton = (): ReactNode => (
    <div className='flex justify-end'>
      {titleExtra}
      <div
        className='items-center flex cursor-pointer z-10 ml-8'
        onClick={() => setIsColumnFilterVisible(!isColumnFilterVisible)}
      >
        <EllipsisHorizontalIcon className='w-10 storke-2 text-[#828282]' />
      </div>
      <XMarkIcon
        className='w-10 storke-2 text-[#828282] cursor-pointer'
        onClick={() => handleModalClose()}
      />
      {isColumnFilterVisible && (
        <div className='absolute right-0 top-14 flex flex-col bg-white rounded-2xl shadow-xl p-4 min-w-[300px] z-20 space-y-6 text-bmblue select-none'>
          <span className='font-bold p-4 flex items-center'>
            Selected Columns
          </span>
          <ColumnFiltersList
            isMarketProjects
            columnDataIndexes={columnDataIndexes}
            setColumnDataIndexes={setColumnDataIndexes}
            columnsFilterValues={columnsFilterValues}
            onChangeFilter={onChangeFilter}
          />
          <div className='flex justify-evenly items-center space-x-4 p-4'>
            <Button
              className='bm-default-btn'
              onClick={() => setIsColumnFilterVisible(false)}
            >
              Cancel
            </Button>
            <Button className='bm-default-btn' onClick={() => onResetFilters()}>
              Reset
            </Button>
            <Button className='bm-submit-btn' onClick={() => onSaveFilters()}>
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Card
      className='custom-padding absolute top-10 bottom-36 left-10 right-10 h-[calc(100vh-15rem)] rounded-[25px] z-10'
      ref={cardRef}
      titleExtra={FilterTitleButton()}
    >
      <ProjectsTable
        isReadMode={false}
        extended
        setColumnDataIndexes={setColumnDataIndexes}
        setColumnFilterValues={setColumnFilterValues}
        columnDataIndexes={columnDataIndexes}
        columnsFilterValues={columnsFilterValues}
        customHeight={cardHeight}
      />
    </Card>
  );
};
