import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaConfig } from '@ant-design/plots';
import { PresentationChartBarIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { Spin } from 'antd';
import {
  Card,
  CardContent,
  CardHeader,
  CardOptions,
  CardTitle,
} from '../card/Card';
import { useStoreState } from '../../../hooks';
import { ProjectType } from '../../../store/types';
import { DefaultCardOptions } from '../card/cardOptions/DefaultCardOptions';
import { MaximizeOption } from '../card/cardOptions/MaximizeOption';
import { LayoutKey } from '../../../api/mockData/dashboardData';
import { LayoutSelector } from './LayoutSelector';

type SalesProgressData = {
  date: string;
  type: string;
  value: number;
};

type SalesProgressChartProps = {
  data: SalesProgressData[];
  startDate: string;
  endDate: string;
};

const formatValues: LayoutKey[] = [
  'all',
  'layout_1',
  'layout_2',
  'layout_3',
  'layout_4_and_more',
];

const SalesProgressChart: FC<SalesProgressChartProps> = ({
  data,
  startDate,
  endDate,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const config: AreaConfig = {
    data,
    isStack: false,
    xField: 'date', // X-axis is the date
    yField: 'value', // Y-axis is the normalized value
    seriesField: 'type', // Group by type to create overlapping areas
    autoFit: true,
    // padding: 'auto',
    xAxis: {
      type: 'time',
      min: startDate,
      max: endDate,
      grid: null,
      tickCount: 6, // Limit the number of ticks
      nice: false, // Disable automatic range adjustment
      label: {
        formatter: (text) => {
          const date = new Date(text); // Parse the date string
          return date.toLocaleString('en-US', {
            month: 'short',
            year: 'numeric',
          }); // Format as 'Jan 2025'
        },
      },
    },
    appendPadding: [10, 0, 30, 0], // [top, right, bottom, left] padding
    yAxis: {
      grid: null,
      min: 0,
      max: 101, // Y-axis range from 0% to 100%
      tickInterval: 20, // Space ticks by 20% intervals
      label: {
        formatter: (text) => {
          if (text === '120') return null;
          return `${text} %`;
        },
      },
    },
    areaStyle: (type: { type: string }) => ({
      fill: type.type === 'Plan' ? '#D9D9D9' : '#CCD9EC', // Hex color for areas
      fillOpacity: type.type === 'Plan' ? 1 : 0.65, // Full opacity for 'Plan', 50% for 'Sale'
    }),
    line: {
      color: (datum: Record<string, any>) =>
        datum.type === 'Plan' ? '#828282' : '#5A72B1', // Hex color for top lines
      size: 4,
    },
    smooth: true,
    tooltip: {
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${t(
                `dashboard.sale_progress.tooltip.${item?.name.toLowerCase()}`,
                item?.name,
              )}:</div>
              <div class="bmt-value">${Number(item?.value)?.toFixed(2)} %</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    color: (datum: Record<string, any>) =>
      datum.type === 'Plan' ? '#828282' : '#5A72B1',
    legend: {
      itemName: {
        formatter: (text) =>
          t(`dashboard.sale_progress.tooltip.${text.toLowerCase()}`),
      },
      flipPage: false,
      position: 'bottom',
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
    },
  };

  return <Area {...config} />;
};

export const SaleProgressUpdated: FC = () => {
  const { t } = useTranslation();
  const { projectType } = useStoreState((state) => state.filters);
  const salesProgress = useStoreState(
    (state) => state.dashboard.dashboardData?.sale_progress,
  );
  const { dashboardLoading, includeReservations } = useStoreState(
    (state) => state.dashboard,
  );

  const isRent = projectType === ProjectType.RENT;

  const planDatapoints = salesProgress?.plan_data_points ?? [];
  const saleDataPoints = salesProgress?.sale_data_points ?? null;

  const mappedFormatValues = formatValues.filter((format) => {
    if (!saleDataPoints) return false;
    return !!saleDataPoints[format][
      includeReservations ? 'sold_plus_reserved' : 'sold'
    ].length;
  });

  const [selected, setSelected] = useState(mappedFormatValues[0]);
  const [modalOpen, setModalOpen] = useState(false);

  const planData =
    planDatapoints?.map((point) => ({
      date: new Date(point.date).toISOString(),
      type: 'Plan',
      value: point.value * 100,
    })) ?? [];

  const isValidKey = (key: string): key is LayoutKey =>
    key in (saleDataPoints || {});

  const dataPoints =
    saleDataPoints && isValidKey(selected) && saleDataPoints[selected];

  const sold = dataPoints ? dataPoints.sold : [];
  const soldPlusReserved = dataPoints ? dataPoints.sold_plus_reserved : [];

  const saleData = includeReservations ? soldPlusReserved : sold;

  const formatedSaleData =
    saleData.map((point) => ({
      date: new Date(point.date).toISOString(),
      type: 'Sale',
      value: point.value * 100,
    })) ?? [];

  const oneTimePriceList = !!!saleData?.length;

  const combinedData =
    selected === 'all' ? [...planData, ...formatedSaleData] : formatedSaleData;

  const sortedPlanData = planData.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const startDate = sortedPlanData[0]?.date;
  const endDate = sortedPlanData[sortedPlanData.length - 1]?.date;

  return (
    <Card open={modalOpen} onOpenChange={() => setModalOpen((open) => !open)}>
      <CardHeader>
        <CardTitle>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2'>
            <PresentationChartBarIcon className='stroke-2 w-10 h-10' />
          </div>
          <span>
            {isRent
              ? t('dashboard.rent_progress.title')
              : t('dashboard.sale_progress.title')}
          </span>
        </CardTitle>
        <CardOptions>
          {!modalOpen && !!combinedData.length && (
            <>
              <LayoutSelector
                selected={selected}
                setSelected={setSelected}
                formatValues={mappedFormatValues}
              />
              <DefaultCardOptions moreOptions />
              <MaximizeOption open={modalOpen} />
            </>
          )}
        </CardOptions>
      </CardHeader>
      <CardContent>
        {!dashboardLoading ? (
          <>
            {!oneTimePriceList && combinedData ? (
              <SalesProgressChart
                data={combinedData}
                startDate={startDate}
                endDate={endDate}
              />
            ) : (
              t(
                'dashboard.sale_progress.one_time_price_list',
                'Sale Progress is not available for one-time price lists. Reach out to us to enable this functionality.',
              )
            )}
          </>
        ) : (
          <div className='flex w-full justify-center items-center'>
            <Spin spinning />
          </div>
        )}
      </CardContent>
    </Card>
  );
};
