import { FC } from 'react';
import { ProjectSelect } from '..';
import { OverviewLoading } from '../OverviewLoading';

export const DashboardFilters: FC = () => (
  <div>
    <OverviewLoading>
      <ProjectSelect />
    </OverviewLoading>
  </div>
);
