import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Button } from 'antd';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';

type Props = {
  tableDataIds: number[];
};

export const PriceListDetailPagination: FC<Props> = ({ tableDataIds }) => {
  const unitId = Number(useParams().unitId);
  const unitIndex = tableDataIds.indexOf(unitId);
  const firstIndex = 0;
  const lastIndex = tableDataIds.length - 1;
  const nextIndex = unitIndex === lastIndex ? firstIndex : unitIndex + 1;
  const prevIndex = unitIndex === 0 ? lastIndex : unitIndex - 1;

  return (
    <div className='flex items-center space-x-2'>
      <Link
        to={`/price-list/${tableDataIds[prevIndex]}`}
        className='flex items-center justify-center'
      >
        <Button shape='circle' className='flex justify-center items-center'>
          <ChevronLeftIcon className='w-7 h-7 stroke-[3px] self-center' />
        </Button>
      </Link>
      <Link
        to={`/price-list/${tableDataIds[nextIndex]}`}
        className='flex items-center justify-center'
      >
        <Button shape='circle' className='flex justify-center items-center'>
          <ChevronRightIcon className='w-7 h-7 stroke-[3px] self-center' />
        </Button>
      </Link>
    </div>
  );
};
