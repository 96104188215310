/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useState } from 'react';
import { Affix, Card, Col, Collapse, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline';
import { Page } from '../../components';
import { useBreakpoints, useStoreActions, useStoreState } from '../../hooks';
import { styles } from './styles';
import { PriceUpdateFilters } from '../../components/priceUpdate/PriceUpdateFilters';
import { DetailPreviewCard } from '../../components/priceListDetail/DetailPreviewCard/DetailPreviewCard';
import { SubscribePage } from '../../components/SubscribePage/SubscribePage';
import { DetailPreviewCardUpdated } from '../../components/priceListDetail/DetailPreviewCard/DetailPreviewCardUpdated';
import { transformPricingListData } from '../../utils/utils';
import { REVAMP_ALLOWED_DEVELOPERS } from '../../constants';
import { SubtitleContainerUpdated } from './SubtitleContainer/SubtitleContainerUpdated';
import {
  UpdateType,
  Flat,
  UpdateDetails,
  PercentageChange,
} from '../../api/mockData/priceUpdateDataUpdated';
import { PriceUpdateTableUpdated } from '../../components/priceUpdate/priceUpdateTableUpdated';
import { ProjectType } from '../../store/types';
import { ActionButtons } from '../../components/priceUpdate/ActionButtons';
import { Unit } from '../../api/mockData/priceListData';
import { updatePriceListUserSeen } from '../../api';

export enum PriceTrendEnum {
  INCREASE = 'increase',
  DECREASE = 'decrease',
}

export const PriceUpdatePageUpdated: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );
  const data = useStoreState((state) => state.priceList.selectedData);
  const selectedPricingData = useStoreState(
    (state) => state.priceList.selectedPricingUpdateData,
  );
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const updateData = useStoreState(
    (state) => state.priceList.updateDataUpdated,
  );
  const { projectId, isLoading } = useStoreState((state) => state.filters);
  const fetchUpdateData = useStoreActions(
    (actions) => actions.priceList.fetchUpdateDataUpdated,
  );
  const updateLocalUserSeen = useStoreActions(
    (actions) => actions.filters.updateLocalUserSeen,
  );
  const updateAttributes = useStoreActions(
    (actions) => actions.priceList.updateAttributes,
  );
  const projectUpdate = useMemo(
    () =>
      overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id ?? 0,
  );

  const {
    unavailable_units = 0,
    total_units = 0,
    sale_start = null,
    sale_end = null,
    update_date = null,
    revenue_change_percentage = 0,
    current_revenue = 0,
    time_progress_percentage = 0,
    revenue_change = 0,
    recommended_revenue = 0,
  } = updateData?.key_metrics || {};

  const positiveUpdates = updateData?.updates?.positive as UpdateType;
  const negativeUpdates = updateData?.updates?.negative as UpdateType;

  const areTherePositiveUpdates =
    !!positiveUpdates?.disabled_updates.length ||
    !!positiveUpdates?.large_updates.length ||
    !!positiveUpdates?.small_updates.length;
  const areThereNegativeUpdates =
    !!negativeUpdates?.disabled_updates.length ||
    !!negativeUpdates?.large_updates.length ||
    !!negativeUpdates?.small_updates.length;

  const flats = updateData?.flats as Flat[];

  const [resetSignal, setResetSignal] = useState(0);

  const handleReset = (): void => {
    setResetSignal((prev) => prev + 1);
  };

  const initialDisabledAttributes = useMemo(
    () => updateData?.key_metrics?.disabled_attributes,
    [updateData],
  );
  const initialPercentageChange = useMemo(
    () => updateData?.key_metrics?.percentage_change,
    [updateData],
  );
  const [disabledAttributes, setDisabledAttributes] = useState<
    Partial<UpdateDetails>[]
  >([]);
  const [percentageChange, setPercentageChange] = useState<PercentageChange[]>(
    [],
  );

  const filteredPercentageChange = useMemo(
    () =>
      percentageChange?.filter(
        (change) =>
          change.new_revenue_change_percentage.toFixed(4) !==
          change.old_revenue_change_percentage.toFixed(4),
      ),
    [percentageChange],
  );

  useEffect(() => {
    if (updateData) {
      setPercentageChange(updateData?.key_metrics.percentage_change ?? []);
      setDisabledAttributes(updateData?.key_metrics.disabled_attributes ?? []);
    }
  }, [updateData]);

  const handleDisableAttribute = (attribute: Partial<UpdateDetails>): void => {
    setDisabledAttributes((currentAttributes) => {
      const updatedAttributes = [...currentAttributes, attribute];
      return updatedAttributes;
    });
  };

  const handleEnableAttribute = (attribute: Partial<UpdateDetails>): void => {
    setDisabledAttributes((currentAttributes) =>
      currentAttributes?.filter((attr) => {
        const attrString = `${attr.attribute_flag}${attr.attribute_by_layout}${attr.attribute_name}`;
        const enabledAttrString = `${attribute.attribute_flag}${attribute.attribute_by_layout}${attribute.attribute_name}`;
        return attrString !== enabledAttrString;
      }),
    );
  };

  const isDisabledAttribute = (attribute: Partial<UpdateDetails>): boolean =>
    disabledAttributes.some(
      (attr) =>
        attr.attribute_flag === attribute.attribute_flag &&
        attr.attribute_name === attribute.attribute_name &&
        attr.attribute_by_layout === attribute.attribute_by_layout,
    );

  // actions
  const [isEditing, setIsEditing] = useState(false);
  const handleCancel = (): void => {
    handleReset();
    setDisabledAttributes(initialDisabledAttributes ?? []);
    setPercentageChange(initialPercentageChange ?? []);
    setIsEditing(false);
  };
  const handleSave = async (): Promise<void> => {
    await updateAttributes({
      projectId: projectId ?? 0,
      disabled_attributes: disabledAttributes,
      percentage_change: filteredPercentageChange,
    });
    setIsEditing(false);
  };

  const mapUpdatesWithFlats = (
    updates: UpdateDetails[],
    flatsForMap: Flat[],
  ): UpdateDetails[] =>
    updates?.map((update) => {
      const flatsForUpdate = update.affected_units
        ?.map((affectedUnit) => {
          const flatForUpdate = flatsForMap.find(
            (flat) => flat.id === affectedUnit.id,
          );

          if (flatForUpdate) {
            return {
              ...flatForUpdate,
              attribute_update_percentage:
                affectedUnit.attribute_update.percentage ?? null,
            };
          }
          return undefined;
        })
        ?.filter(
          (flat): flat is Flat & { attribute_update_percentage: number } =>
            flat !== undefined,
        );

      const mappedFlats = flatsForUpdate?.map((flat) => ({
        ...flat,
        layout: `layout_${flat.layout}`,
        total_update: flat.update_percentage * 100,
        feature_update: flat.attribute_update_percentage * 100,
        internal_id: flat.name,
      }));

      return {
        ...update,
        flats: mappedFlats,
      };
    });

  const positiveLargeUpdatesWithFlats = mapUpdatesWithFlats(
    positiveUpdates?.large_updates,
    flats,
  );
  const positiveDisabledUpdatesWithFlats = mapUpdatesWithFlats(
    positiveUpdates?.disabled_updates,
    flats,
  );
  const positiveSmallUpdatesWithFlats = mapUpdatesWithFlats(
    positiveUpdates?.small_updates,
    flats,
  );
  const negativeLargeUpdatesWithFlats = mapUpdatesWithFlats(
    negativeUpdates?.large_updates,
    flats,
  );
  const negativeDisabledUpdatesWithFlats = mapUpdatesWithFlats(
    negativeUpdates?.disabled_updates,
    flats,
  );
  const negativeSmallUpdatesWithFlats = mapUpdatesWithFlats(
    negativeUpdates?.small_updates,
    flats,
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoading && projectId) {
      fetchUpdateData({ projectId });
    }
    if (projectUpdate && !projectUpdate.user_seen) {
      updateLocalUserSeen(projectUpdate.id);
      updatePriceListUserSeen({ price_list_update_id: projectUpdate.id });
    }
  }, [fetchUpdateData, isLoading, projectId, projectUpdate]);

  return (
    <Page
      pageClassname='price-list'
      filters={isDashboardEnabled && <PriceUpdateFilters />}
      title={
        isDashboardEnabled ? (
          <div className='reports-title'>
            <span>{t('price_list.title')}</span>
            <span>/</span>
            <span className='active'>
              {t('price_update.title', 'Price Update')}
            </span>
          </div>
        ) : (
          <span>{t('price_list.title')}</span>
        )
      }
    >
      {/* eslint-disable-next-line no-nested-ternary */}
      {isDashboardEnabled ? (
        !updateData ? (
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            {t('price_update.loading_message')}
          </div>
        ) : (
          <Row css={styles.contentContainer} gutter={[16, 16]}>
            <Col xs={24} md={18}>
              <Card css={styles.tableWrapper}>
                <div css={styles.cardWrapper}>
                  <h1 css={styles.title}>
                    {t('price_update.title', 'Price Update')}
                  </h1>
                  <div css={styles.titleWrapper}>
                    <div css={styles.projectIcon}>
                      <BuildingOfficeIcon />
                    </div>
                    <div css={styles.projectName}>
                      {projectUpdate?.name || 'Mock Project'}
                    </div>
                  </div>
                </div>
                <SubtitleContainerUpdated
                  revenue_change_percentage={revenue_change_percentage}
                  sale_end={sale_end ?? ''}
                  sale_start={sale_start ?? ''}
                  total_units={total_units}
                  unavailable_units={unavailable_units}
                  update_date={update_date ?? ''}
                  current_revenue={current_revenue}
                  revenue_change={revenue_change}
                  recommended_revenue={recommended_revenue}
                  time_progress_percentage={time_progress_percentage}
                />
                <div className='pt-12'>
                  {areTherePositiveUpdates && (
                    <>
                      <div className='flex gap-4 px-6'>
                        <ArrowTrendingUpIcon className='w-10' />
                        <span className='font-semibold text-[2.4rem]'>
                          {isRent
                            ? t('price_list.rent_increase')
                            : t('price_list.price_increase', 'Price Increases')}
                        </span>
                      </div>
                      <div className='px-[16px]'>
                        {!!(positiveLargeUpdatesWithFlats || []).length && (
                          <PriceUpdateTableUpdated
                            priceTrend={PriceTrendEnum.INCREASE}
                            // @ts-ignore TODO: Fix typing
                            data={positiveLargeUpdatesWithFlats}
                            handleDisableAttribute={handleDisableAttribute}
                            handleEnableAttribute={handleEnableAttribute}
                            isDisabledAttribute={isDisabledAttribute}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            percentageChange={percentageChange}
                            setPercentageChange={setPercentageChange}
                            resetSignal={resetSignal}
                            filteredPercentageChange={filteredPercentageChange}
                          />
                        )}
                      </div>
                      <Collapse
                        ghost
                        accordion
                        expandIconPosition='start'
                        className='pb-[5rem] w-full'
                      >
                        <Collapse.Panel
                          key='disabled-updates'
                          className='w-full pt-6'
                          header={
                            <span className='text-[#828282]'>
                              {`${t(
                                'price_updates.subtitle.disabled_updates',
                                'Disabled updates',
                              )} (${positiveDisabledUpdatesWithFlats.length})`}
                            </span>
                          }
                        >
                          {!!(positiveDisabledUpdatesWithFlats || [])
                            .length && (
                            <PriceUpdateTableUpdated
                              priceTrend={PriceTrendEnum.INCREASE}
                              disabledUpdatesGroup
                              // @ts-ignore TODO: Fix typing
                              data={positiveDisabledUpdatesWithFlats}
                              handleDisableAttribute={handleDisableAttribute}
                              handleEnableAttribute={handleEnableAttribute}
                              isDisabledAttribute={isDisabledAttribute}
                              isEditing={isEditing}
                              setIsEditing={setIsEditing}
                              percentageChange={percentageChange}
                              setPercentageChange={setPercentageChange}
                              resetSignal={resetSignal}
                              filteredPercentageChange={
                                filteredPercentageChange
                              }
                            />
                          )}
                        </Collapse.Panel>
                        <Collapse.Panel
                          className='w-full'
                          key='small-updates'
                          header={
                            <span className='text-[#828282]'>
                              {`${t(
                                'price_updates.subtitle.small_updates',
                                'Small updates',
                              )} (${positiveSmallUpdatesWithFlats.length})`}
                            </span>
                          }
                        >
                          {!!(positiveSmallUpdatesWithFlats || []).length && (
                            <PriceUpdateTableUpdated
                              priceTrend={PriceTrendEnum.INCREASE}
                              // @ts-ignore TODO: Fix typing
                              data={positiveSmallUpdatesWithFlats}
                              handleDisableAttribute={handleDisableAttribute}
                              handleEnableAttribute={handleEnableAttribute}
                              isDisabledAttribute={isDisabledAttribute}
                              isEditing={isEditing}
                              setIsEditing={setIsEditing}
                              percentageChange={percentageChange}
                              setPercentageChange={setPercentageChange}
                              resetSignal={resetSignal}
                              filteredPercentageChange={
                                filteredPercentageChange
                              }
                            />
                          )}
                        </Collapse.Panel>
                      </Collapse>
                    </>
                  )}
                  {areThereNegativeUpdates && (
                    <>
                      <div className='flex gap-4 px-6'>
                        <ArrowTrendingDownIcon className='w-10' />
                        <span className='font-semibold text-[2.4rem]'>
                          {isRent
                            ? t('price_list.rent_decrease')
                            : t('price_list.price_decrease', 'Price Decreases')}
                        </span>
                      </div>
                      <div className='px-[16px]'>
                        {!!(negativeLargeUpdatesWithFlats || []).length && (
                          <PriceUpdateTableUpdated
                            priceTrend={PriceTrendEnum.DECREASE}
                            // @ts-ignore TODO: Fix typing
                            data={negativeLargeUpdatesWithFlats}
                            handleDisableAttribute={handleDisableAttribute}
                            handleEnableAttribute={handleEnableAttribute}
                            isDisabledAttribute={isDisabledAttribute}
                            isEditing={isEditing}
                            setIsEditing={setIsEditing}
                            percentageChange={percentageChange}
                            setPercentageChange={setPercentageChange}
                            resetSignal={resetSignal}
                            filteredPercentageChange={filteredPercentageChange}
                          />
                        )}
                      </div>
                      <Collapse
                        ghost
                        accordion
                        expandIconPosition='start'
                        className='pb-[5rem] w-full'
                      >
                        {!!(negativeDisabledUpdatesWithFlats || []).length && (
                          <Collapse.Panel
                            key='disabled-updates'
                            className='w-full pt-6'
                            header={
                              <span className='text-[#828282]'>
                                {`${t(
                                  'price_updates.subtitle.disabled_updates',
                                  'Disabled updates',
                                )} (${
                                  negativeDisabledUpdatesWithFlats.length
                                })`}
                              </span>
                            }
                          >
                            <PriceUpdateTableUpdated
                              priceTrend={PriceTrendEnum.DECREASE}
                              disabledUpdatesGroup
                              // @ts-ignore TODO: Fix typing
                              data={negativeDisabledUpdatesWithFlats}
                              handleDisableAttribute={handleDisableAttribute}
                              handleEnableAttribute={handleEnableAttribute}
                              isDisabledAttribute={isDisabledAttribute}
                              isEditing={isEditing}
                              setIsEditing={setIsEditing}
                              percentageChange={percentageChange}
                              setPercentageChange={setPercentageChange}
                              resetSignal={resetSignal}
                              filteredPercentageChange={
                                filteredPercentageChange
                              }
                            />
                          </Collapse.Panel>
                        )}
                        {!!(negativeSmallUpdatesWithFlats || []).length && (
                          <Collapse.Panel
                            className='w-full'
                            key='small-updates'
                            header={
                              <span className='text-[#828282]'>
                                {`${t(
                                  'price_updates.subtitle.small_updates',
                                  'Small updates',
                                )} (${negativeSmallUpdatesWithFlats.length})`}
                              </span>
                            }
                          >
                            <PriceUpdateTableUpdated
                              priceTrend={PriceTrendEnum.DECREASE}
                              // @ts-ignore TODO: Fix typing
                              data={negativeSmallUpdatesWithFlats}
                              handleDisableAttribute={handleDisableAttribute}
                              handleEnableAttribute={handleEnableAttribute}
                              isDisabledAttribute={isDisabledAttribute}
                              isEditing={isEditing}
                              setIsEditing={setIsEditing}
                              percentageChange={percentageChange}
                              setPercentageChange={setPercentageChange}
                              resetSignal={resetSignal}
                              filteredPercentageChange={
                                filteredPercentageChange
                              }
                            />
                          </Collapse.Panel>
                        )}
                      </Collapse>
                    </>
                  )}
                </div>
              </Card>
            </Col>
            {isEditing && (
              <ActionButtons
                handleCancel={handleCancel}
                handleSave={handleSave}
              />
            )}
            {!isSmallScreen && (
              <Col xs={24} md={6} css={styles.rightColumn} ref={setContainer}>
                <Affix
                  css={styles.affix}
                  offsetTop={1}
                  target={() => container}
                >
                  <div css={styles.affix}>
                    {REVAMP_ALLOWED_DEVELOPERS.includes(developerId)
                      ? selectedPricingData && (
                          <DetailPreviewCardUpdated
                            showDetailButton
                            data={transformPricingListData(
                              selectedPricingData as Unit,
                            )}
                          />
                        )
                      : data && (
                          <DetailPreviewCard showDetailButton data={data} />
                        )}
                  </div>
                </Affix>
              </Col>
            )}
          </Row>
        )
      ) : (
        <SubscribePage featureType='dashboard' />
      )}
    </Page>
  );
};
