import { FC, useEffect, useState } from 'react';
import { Button, DatePicker, Select, message } from 'antd';
import dayjs from 'dayjs';
import { CalendarIcon, ChevronDownIcon, CubeIcon, DocumentChartBarIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { PlanData, Targets } from '../../../../api/mockData/sales';
import { useStoreActions, useStoreState } from '../../../../hooks';
import { round } from '../../../../utils/formatters';
import styles from './Plan.module.css';
import { PlanTable } from './PlanTable';

export type TargetType = {
  label: string
  value: 'units' | 'floor_area' | 'revenue'
}

type FrequencyType = {
  label: string
  value: 'monthly' | 'quarterly' | 'semiannualy'
}

const targetTypes: TargetType[] = [
  { label: 'Units',
    value: 'units',
  },
  { label: 'Floor Area',
    value: 'floor_area',
  },
  { label: 'Revenue',
    value: 'revenue',
  },
];
const frequencyTypes: FrequencyType[] = [
  { label: 'Monthly',
    value: 'monthly',
  },
  { label: 'Quarterly',
    value: 'quarterly',
  },
  { label: 'Semiannualy',
    value: 'semiannualy',
  },
];

type PlanSectionProps = {
  plan: PlanData[]
  targets: Targets;
}

export const PlanSection: FC<PlanSectionProps> = ({ plan, targets }) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { phaseId, projectId } = useStoreState(
    (state) => state.filters,
  );
  const { updatePlan, fetchPlanData, fetchTargets, updatePlanDate, setIsEditingSales } = useStoreActions((actions) => actions.sales);
  const { isEditingSales } = useStoreState((state) => state.sales);
  const [target, setTarget] = useState(targetTypes[0].value);
  const [freq, setFreq] = useState(frequencyTypes[0].value);
  const [saleCompletion, setSaleCompletion] = useState(targets.date_end_sale);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSaleCompletion, setIsEditingSaleCompletion] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editingPlan, setEditingPlan] = useState<PlanData[]>(plan);
  const ratios = {
    revenue: targets.ratio_revenue,
    floor_area: targets.ratio_floor_area,
  };

  useEffect(() => {
    if (!isEditingSales && editingPlan !== plan) {
      setEditingPlan(plan);
    }
  }, [editingPlan, isEditingSales, plan]);

  useEffect(() => {
    if (!isEditingSaleCompletion && saleCompletion !== targets.date_end_sale) {
      setSaleCompletion(targets.date_end_sale);
    }
  }, [isEditingSaleCompletion, saleCompletion, targets.date_end_sale]);

  const handleTarget = (value: 'units' | 'floor_area' | 'revenue'): void => {
    setTarget(value);
  };

  const handleFreq = (value: 'monthly' | 'quarterly' | 'semiannualy'): void => {
    setFreq(value);
  };

  const handleEditing = (): void => {
    if (isEditingSaleCompletion) {
      message.info(t('sales.error.finish_editing_date', 'Please, finish editing sale completion first.'));
      return;
    }
    setTarget('units');
    setFreq('monthly');
    setIsEditing(true);
    setIsEditingSales(true);
  };

  const handleUpdatePlan = async (): Promise<void> => {
    if (!projectId || !phaseId) {
      message.error(t('sales.error.no_project', 'No project or phase found.'), 1);
      return;
    }
    setIsProcessing(true);
    try {
      const updatedPlanData = editingPlan.map((item) => ({
        month: item.month,
        units_sold: item.plan_speed,
      }))
      .filter((item) => !dayjs(item.month).isBefore(dayjs(), 'month'));
      const response = await updatePlan({
        project_id: projectId,
        phase_id: phaseId,
        sales_data: updatedPlanData,
      });
      if (response.status === 200) {
        fetchPlanData();
      }
    } catch (error) {
      message.error(t('sales.error.plan_update', 'Failed to update sales plan.'), 1);
      setEditingPlan(plan);
    } finally {
      setIsProcessing(false);
      setIsEditing(false);
      setIsEditingSales(false);
    }
  };

  const handleRestore = (): void => {
    setEditingPlan(plan);
  };

  const handleCancelEdit = (): void => {
    if (editingPlan !== plan) {
      setEditingPlan(plan);
    }
    setIsEditing(false);
    setIsEditingSales(false);
  };

  const handlePlanEdit = (id: number, value: number): void => {
    const newPlan = editingPlan.map((item) => {
      if (item.id === id) {
        return { ...item, plan_speed: value };
      }
      return item;
    });
    setEditingPlan(newPlan);
  };

  const handleEditSaleCompletion = (): void => {
    if (isEditing) {
      message.info(t('sales.error.finish_editing_plan', 'Please, finish editing plan first.'));
      return;
    }
    setIsEditingSaleCompletion(true);
    setIsEditingSales(true);
  };

  const handleCancelDateEdit = (): void => {
    setSaleCompletion(targets.date_end_sale);
    setIsEditingSaleCompletion(false);
    setIsEditingSales(false);
  };

  const handleUpdatePlanDate = async (): Promise<void> => {
    if (!projectId || !phaseId) {
      message.error(t('sales.error.no_project', 'No project or phase found.'), 1);
      return;
    }
    setIsProcessing(true);
    try {
      const response = await updatePlanDate({
        project_id: projectId,
        phase_id: phaseId,
        date_end_sale: saleCompletion,
      });
      if (response.status === 200) {
        fetchPlanData();
        fetchTargets();
      }
    } catch (error) {
      message.error(t('sales.error.plan_update', 'Failed to update sales plan.'), 1);
    } finally {
      setIsProcessing(false);
      setIsEditingSaleCompletion(false);
      setIsEditingSales(false);
    }
  };

  const planAvailableUnits = plan.reduce((total, item) => total + item.plan_speed, 0);
  const editingPlanAvailableUnits = editingPlan.reduce((total, item) => total + item.plan_speed, 0);
  const availableUnits = round(planAvailableUnits - editingPlanAvailableUnits, 1);

  return (
  <div className={styles.sectionContainer}>
    <div className={styles.sectionHeaderWrapper}>
      <div className={styles.sectionTitleWrapper}>
        <div className={styles.badge}>
          <CalendarIcon className={styles.icon} />
        </div>
        <span className='select-none'>{t('sales.plan.title', 'Sales Plan')}</span>
      </div>
    </div>
    <div className={styles.sectionWrapper}>
      <div className={styles.sectionTableSelectors}>
        <div className={styles.sectionCompletionWrapper}>
          <span>{t('sales.plan.sale_completion', 'Sale Completion')}</span>
          <div className={styles.sectionRowFlexCenteredSpaced}>
            <CalendarIcon className={styles.icon} />
            {isEditingSaleCompletion ? (
              <div className={styles.sectionDatePickerWrapper}>
                <DatePicker
                  bordered={false}
                  picker='month'
                  suffixIcon={undefined}
                  format='MMMM YYYY'
                  value={dayjs(saleCompletion)}
                  onChange={(date) => setSaleCompletion(date ? date.date(1).format('YYYY-MM-DD') : targets.date_end_sale)}
                  className={styles.sectionDatePicker}
                  disabledDate={(current) => current < dayjs().add(1, 'month')}
                />
                <div className={styles.sectionRowFlexCenteredSpaced}>
                  <Button className='bm-default-btn' disabled={isProcessing} onClick={() => handleCancelDateEdit()}>{t('common.cancel')}</Button>
                  <Button className='bm-submit-btn' loading={isProcessing} disabled={isProcessing || saleCompletion === targets.date_end_sale || !saleCompletion} onClick={() => handleUpdatePlanDate()}>{t('common.confirm')}</Button>
                </div>
              </div>
            ) : (
              <span onClick={() => handleEditSaleCompletion()}>{dayjs(saleCompletion).format('MMMM YYYY')}</span>
            )}
          </div>
        </div>
        <div className={styles.sectionSelectionControlsWrapper}>
          <div className={styles.sectionRowFlex}>
            <CubeIcon className={styles.icon} />
            <Select
              bordered={false}
              value={target}
              onChange={handleTarget}
              dropdownMatchSelectWidth={false}
              suffixIcon={<ChevronDownIcon className={styles.sectionChevronDown} />}
              disabled={isEditing}
            >
              {targetTypes.map((v) => (
                <Option key={v.value} value={v.value}>
                  {t(`sales.targets.subtitle.${v.value}`, v.label)}
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.sectionRowFlex}>
            <DocumentChartBarIcon className={styles.icon} />
            <Select
              bordered={false}
              value={freq}
              onChange={handleFreq}
              dropdownMatchSelectWidth={false}
              suffixIcon={<ChevronDownIcon className={styles.sectionChevronDown} />}
              disabled={isEditing}
            >
              {frequencyTypes.map((v) => (
                <Option key={v.value} value={v.value}>
                  {t(`sales.targets.subtitle.${v.value}`, v.label)}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <PlanTable
        data={editingPlan}
        target={target}
        frequency={freq}
        ratios={ratios}
        isEditing={isEditing}
        onEdit={handlePlanEdit}
        handleEditing={handleEditing}
        handleRestore={handleRestore}
      />
    </div>
    {isEditing && (
      <div className={styles.sectionEditFooter}>
        {availableUnits !== 0 && (
          <span className='text-red-500'>{availableUnits < 0 ? t('sales.error.plan_units', 'Not enough units') : t('sales.error.remaining_units', 'You have to use all units')}</span>
        )}
        <div className='flex space-x-2'>
          <span className='font-semibold'>{t('sales.plan.subtitle.available_units', 'Available units')}:</span>
          <span className={availableUnits !== 0 ? 'text-red-500' : ''}>{availableUnits}</span>
        </div>
        <div className={styles.sectionEditBtns}>
          <Button className='bm-default-btn' onClick={() => handleCancelEdit()}>
            {t('common.cancel')}
          </Button>
          <Button className='bm-submit-btn' onClick={() => handleUpdatePlan()} disabled={editingPlan === plan || availableUnits !== 0} loading={isProcessing}>
            {t('common.confirm')}
          </Button>
        </div>
      </div>
    )}
  </div>
);
};
