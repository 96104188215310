import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Select, Table, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, generatePath } from 'react-router-dom';
import {
  FilterValue,
  SorterResult,
  SortOrder,
  ColumnType,
} from 'antd/lib/table/interface';
import clsx from 'clsx';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  useBreakpoints,
  useFlatLayout,
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { getNumericSorter } from '../../utils';
import { IncludeIcon } from './IncludeIcon';
import { Paths } from '../../routes/Paths';
import {
  getUniqueValues,
  sortByString,
  sortCompletionDates,
} from '../../utils/utils';
import { CenteredSpinner } from '../CenteredSpinner';
import { SimplifiedProjects } from '../../store/market';
import { InfoPopover } from '../InfoPopover';

const PADDING = 24;
const TABLE_HEADER_HEIGHT = 60;

interface Props extends TableProps<SimplifiedProjects> {
  extended?: boolean;
  isReadMode: boolean;
  setColumnDataIndexes: React.Dispatch<React.SetStateAction<string[]>>;
  setColumnFilterValues: (filterValues: string[]) => void;
  columnDataIndexes: string[];
  columnsFilterValues: string[];
  customHeight?: number;
}

export const ProjectsTable: FC<Props> = ({
  extended = false,
  isReadMode,
  setColumnDataIndexes,
  setColumnFilterValues,
  columnDataIndexes,
  columnsFilterValues,
  customHeight,
  ...rest
}) => {
  const storedIndexes = localStorage.getItem('projectsDataIndexes');
  const storedFilters = localStorage.getItem('projectsFilters');
  const { isSmallScreen } = useBreakpoints();
  const { projectsLoading, selectedProjectNames, selectedDeveloperNames } = useStoreState((state) => state.market);
  const { setSelectedProjectNames, setSelectedDeveloperNames } = useStoreActions((actions) => actions.market);
  const [parentHeight, setParentHeight] = useState(customHeight ?? 0);
  const ref = useRef<HTMLDivElement>(null);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const activeTags = useStoreState((state) => state.market.activeTags);
  const allProjects = useStoreState((state) => isSmallScreen ? state.market.simplifiedProjects : state.market.simplifiedProjects.filter(({ project_phase }) => !isSmallScreen && activeTags.includes(project_phase)));
  const { formatCurrency, isUsRegion, sortLayouts } =
    useRegionFormatting();
  const setPrimaryParameters = useStoreActions((actions) => actions.market.setPrimaryParameters);
  const primaryParameters = useStoreState((state) => state.market.primaryParameters);
  const reserved_as_sold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  const projectsData = useMemo(() => {
    if (!reserved_as_sold) {
      return allProjects.map((project) => ({
        ...project,
        available_units: project.available_units + project.reserved_units,
      }));
    }
    return allProjects;
  }, [allProjects, reserved_as_sold]);
  const {
    filteredData: filteredProjects,
    filters: savedFilters,
    pagination: savedPagination,
    sorter: savedSorter,
  } = useStoreState((state) => state.market.tableData);
  const [searchedProjects, setSearchedProjects] = useState(projectsData);

  useEffect(() => {
    const haveSelectedProjects = selectedProjectNames.length > 0;
    const haveSelectedDevelopers = selectedDeveloperNames.length > 0;
  if (haveSelectedProjects && haveSelectedDevelopers) {
    setSearchedProjects(projectsData.filter(
      (p) => selectedProjectNames.includes(p.project_name) && selectedDeveloperNames.includes(p.developer),
    ));
  } else if (haveSelectedProjects) {
    setSearchedProjects(projectsData.filter(
      (p) => selectedProjectNames.includes(p.project_name),
    ));
  } else if (haveSelectedDevelopers) {
    setSearchedProjects(projectsData.filter(
      (p) => selectedDeveloperNames.includes(p.developer),
    ));
  } else {
    setSearchedProjects(projectsData);
  }
}, [projectsData, selectedProjectNames, selectedDeveloperNames]);

  const projects = isReadMode
    ? searchedProjects.filter((p) => !!p.include)
    : searchedProjects;

  const showPricePerSM = useStoreState((state) => state.market.showPricePerSM);

  const selectedRowKeys = projects
    .filter((v) => v.include)
    .map((v) => v.project_id);

  const { t } = useTranslation();

  const handleInclude = useStoreActions(
    (actions) => actions.market.handleInclude,
  );

  const setSelectedProjectId = useStoreActions(
    (actions) => actions.market.setSelectedProjectId,
  );

  const setTableData = useStoreActions(
    (actions) => actions.market.setTableData,
  );

  const setFilteredProjects = (filteredData: SimplifiedProjects[]): void => {
    setTableData({
      filters: savedFilters,
      pagination: savedPagination,
      sorter: savedSorter,
      filteredData,
    });
  };

  const { getLayoutAbbr } = useFlatLayout();

  const handleClick = (id: number) => (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    handleInclude([id]);
    const newData = [...filteredProjects];
    const index = newData.findIndex((v) => v.project_id === id);
    newData[index].include = !!!newData[index].include;
    setFilteredProjects(newData);
  };

  const handleToggleAll = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    handleInclude(filteredProjects.map((fp) => fp.project_id));
    const data = projects.filter((p) =>
      filteredProjects.map((fp) => fp.project_id).includes(p.project_id),
    );
    const includedKeys = data.filter((p) => p.include);
    const newData = [...filteredProjects];
    setFilteredProjects(
      newData.map((nd) => ({
        ...nd,
        include: !(filteredProjects.length === includedKeys.length),
      })),
    );
  };

  useEffect(() => {
    if (primaryParameters) {
      const newParams = {
        ...primaryParameters,
        included_project_ids: filteredProjects.filter((p) => !!p.include)
          .map((p) => p.project_id),
      };
      setPrimaryParameters(newParams);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredProjects, setPrimaryParameters]);

  const rowSelection: TableProps<SimplifiedProjects>['rowSelection'] = {
    selectedRowKeys,
    renderCell: (checked, { project_id }) => (
      <IncludeIcon value={checked} onClick={handleClick(project_id)} />
    ),
    columnTitle: (
      <IncludeIcon
        value={
          filteredProjects.filter((fp) => fp.include).length ===
          filteredProjects.length
        }
        onClick={handleToggleAll}
      />
    ),
  };

  const columns: TableProps<SimplifiedProjects>['columns'] = useMemo(() => {
    const projectNames = getUniqueValues(
      projects.map((p) => p.project_name),
    ).sort(sortByString);
    const projectsOptions = getUniqueValues(
      allProjects.map((p) => p.project_name),
    ).sort(sortByString).map((name) => ({ label: name, value: name }));
    const developers = getUniqueValues(projects.map((p) => p.developer)).sort(
      sortByString,
    );
    const developerOptions = getUniqueValues(
      allProjects.map((p) => p.developer),
    ).sort(sortByString).map((name) => ({ label: name, value: name }));
    const districts = getUniqueValues(projects.map((p) => p.district)).sort(
      sortByString,
    );
    const municipalities = getUniqueValues(
      projects.map((p) => p.municipality),
    ).sort(sortByString);
    const cities = getUniqueValues(projects.map((p) => p.city)).sort(
      sortByString,
    );
    const unitTypes = getUniqueValues(projects.map((p) => p.unit_type)) as string[];
    const completions = getUniqueValues(
      projects.map((p) => p.date_construction_completion),
    ).sort(sortCompletionDates) as string[];
    const layouts: string[] = [];
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].layouts) {
        for (let j = 0; j < projects[i].layouts.length; j++) {
          const element = projects[i].layouts[j];
          if (!layouts.includes(element)) {
            layouts.push(element);
          }
        }
      }
    }
    const baths: string[] = [];
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].baths) {
        for (let j = 0; j < projects[i].baths.length; j++) {
          const element = projects[i].baths[j];
          if (!baths.includes(element.toString())) {
            baths.push(element.toString());
          }
        }
      }
    }
    const garages: string[] = [];
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].garages) {
        for (let j = 0; j < projects[i].garages.length; j++) {
          const element = projects[i].garages[j];
          if (!garages.includes(element.toString())) {
            garages.push(element.toString());
          }
        }
      }
    }

    const soldPriceDataIndex = showPricePerSM
      ? 'sold_avg_price_per_sm'
      : 'sold_avg_price';
    const availablePriceDataIndex = showPricePerSM
      ? 'avg_price_per_sm'
      : 'avg_price';

    const getStringSorter =
      (key: keyof SimplifiedProjects) =>
      (a: SimplifiedProjects, b: SimplifiedProjects) =>
        sortByString(a[key] as string, b[key] as string);

    const getSavedSortOrder = (
      key: keyof SimplifiedProjects,
    ): SortOrder | undefined =>
      savedSorter.field === key ? savedSorter.order : null;

    const getSavedFilteredValue = (
      key: keyof SimplifiedProjects,
    ): FilterValue | null => savedFilters[key] || null;

    const getOnFilter =
      (key: keyof SimplifiedProjects) =>
      (value: unknown, record: SimplifiedProjects) =>
        record[key] === value;

    const allColumns: ColumnType<SimplifiedProjects>[] = [
      {
        title: t('market.projects.table.project_name', 'Project'),
        align: 'center',
        dataIndex: 'project_name',
        render: (value, { project_id }) => (
          <Link
            to={generatePath(Paths.PROJECT_DETAIL, {
              projectId: project_id,
            })}
            target='_blank'
          >
            <b>{value}</b>
          </Link>
        ),
        sorter: getStringSorter('project_name'),
        sortOrder: getSavedSortOrder('project_name'),
        filterDropdown: () =>
          <div className='p-2 flex flex-col space-y-2 w-[20rem]'>
            <Select
              className='custom-select'
              value={selectedProjectNames}
              onChange={(value) => setSelectedProjectNames(value)}
              placeholder={t('menu.search')}
              mode='multiple'
              allowClear
              optionFilterProp='label'
              options={projectsOptions}
              onClear={() => setSelectedProjectNames([])}
            />
          </div>,
        filterIcon: () => <MagnifyingGlassIcon className='w-6 h-6 stroke-[3px]' />,
        filteredValue: getSavedFilteredValue('project_name'),
        onFilter: getOnFilter('project_name'),
        filters: projectNames.map((value) => ({
          text: value,
          value,
        })),
      },
      {
        title: t('market.projects.table.developer', 'Developer'),
        dataIndex: 'developer',
        align: 'center',
        filters: developers.map((value) => ({
          text: value,
          value,
        })),
        sorter: getStringSorter('developer'),
        sortOrder: getSavedSortOrder('developer'),
        filterDropdown: () =>
          <div className='p-2 flex flex-col space-y-2 w-[20rem]'>
            <Select
              className='custom-select'
              value={selectedDeveloperNames}
              onChange={(value) => setSelectedDeveloperNames(value)}
              placeholder={t('menu.search')}
              mode='multiple'
              allowClear
              optionFilterProp='label'
              options={developerOptions}
              onClear={() => setSelectedDeveloperNames([])}
            />
          </div>,
        filterIcon: () => <MagnifyingGlassIcon className='w-6 h-6 stroke-[3px]' />,
        filteredValue: getSavedFilteredValue('developer'),
        onFilter: getOnFilter('developer'),
      },
      {
        title: t('market.projects.table.district', 'District'),
        dataIndex: 'district',
        align: 'center',
        filters: districts.map((value) => ({
          text: value,
          value,
        })),
        sorter: getStringSorter('district'),
        sortOrder: getSavedSortOrder('district'),
        filteredValue: getSavedFilteredValue('district'),
        onFilter: getOnFilter('district'),
      },
      {
        title: t('market.projects.table.municipality', 'Municipality'),
        dataIndex: 'municipality',
        align: 'center',
        filters: municipalities.map((value) => ({
          text: value,
          value,
        })),
        sorter: getStringSorter('municipality'),
        sortOrder: getSavedSortOrder('municipality'),
        filteredValue: getSavedFilteredValue('municipality'),
        onFilter: getOnFilter('municipality'),
      },
      {
        title: t('market.projects.table.available_units', 'Available Units'),
        dataIndex: 'available_units',
        align: 'center',
        sorter: getNumericSorter('available_units'),
        sortOrder: getSavedSortOrder('available_units'),
      },
      {
        title: t('market.projects.table.sold_units', 'Sold Units'),
        dataIndex: 'sold_units',
        align: 'center',
        sorter: getNumericSorter('sold_units'),
        sortOrder: getSavedSortOrder('sold_units'),
      },
      {
        title: t('market.projects.table.total_number_of_units', 'Total Units'),
        dataIndex: 'total_number_of_units',
        align: 'center',
        sorter: getNumericSorter('total_number_of_units'),
        sortOrder: getSavedSortOrder('total_number_of_units'),
      },
      {
        title: t('market.projects.table.completion_date', 'Completion'),
        dataIndex: 'date_construction_completion',
        align: 'center',
        sorter: (a, b) =>
          sortCompletionDates(a.date_construction_completion, b.date_construction_completion),
        sortOrder: getSavedSortOrder('date_construction_completion'),
        filteredValue: getSavedFilteredValue('date_construction_completion'),
        filters: completions.map((value) => ({
          text: value,
          value,
        })),
        onFilter: getOnFilter('date_construction_completion'),
      },
      {
        title: t('market.projects.table.layouts', 'Layouts'),
        dataIndex: 'layouts',
        align: 'center',
        sorter: (a, b) =>
          sortLayouts(
            a.layouts?.length > 0 ? a.layouts.sort(sortLayouts)[0] : '',
            b.layouts?.length > 0 ? b.layouts.sort(sortLayouts)[0] : '',
          ),
        render: (value: string[], project) => {
          try {
            return value
              ?.sort(sortLayouts)
              .map((layout) => getLayoutAbbr(layout))
              .join(', ');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error, project);
            return null;
          }
        },
        filters: layouts.sort(sortLayouts).map((value) => ({
          text: getLayoutAbbr(value),
          value,
        })),
        onFilter: (value, record) => record.layouts?.includes(value as string),
        sortOrder: getSavedSortOrder('layouts'),
        filteredValue: getSavedFilteredValue('layouts'),
      },
      {
        title: t('market.projects.table.available_price', 'Available Price'),
        dataIndex: availablePriceDataIndex,
        sorter: getNumericSorter(availablePriceDataIndex),
        align: 'center',
        render: (value, record) =>
          (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                {formatCurrency(value)}
                {(record.using_total_area && showPricePerSM && value) && (
                  <span style={{ marginLeft: '4px' }}>
                    <InfoPopover
                      popoverProps={{
                        content: t('project.table.total_area_price_per_sm.info', 'The price per SM was calculated using the total area as the website doesn\'t contain the floor area.'),
                      }}
                    />
                  </span>
                )}
              </span>
            </div>
          ),
        sortOrder: getSavedSortOrder(availablePriceDataIndex),
      },
      {
        title: t('market.projects.table.sold_price', 'Sold Price'),
        dataIndex: soldPriceDataIndex,
        sorter: getNumericSorter(soldPriceDataIndex),
        align: 'center',
        render: (value, record) =>
          (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                {formatCurrency(value)}
                {(record.using_total_area && showPricePerSM && value) && (
                  <span style={{ marginLeft: '4px' }}>
                    <InfoPopover
                      popoverProps={{
                        content: t('project.table.total_area_price_per_sm.info', 'The price per SM was calculated using the total area as the website doesn\'t contain the floor area.'),
                      }}
                    />
                  </span>
                )}
              </span>
            </div>
          ),
        sortOrder: getSavedSortOrder(soldPriceDataIndex),
      },
      {
        title: t('market.projects.table.type', 'Type'),
        dataIndex: 'unit_type',
        align: 'center',
        filters: unitTypes.map((value) => ({
          text: value ? t(`project.overview.type.${value}`) : t('enums.state.null', 'Unknown'),
          value,
        })),
        render: (value) => value ? t(`project.overview.type.${value}`) : t('enums.state.null', 'Unknown'),
        sorter: getStringSorter('unit_type'),
        sortOrder: getSavedSortOrder('unit_type'),
        filteredValue: getSavedFilteredValue('unit_type'),
        onFilter: getOnFilter('unit_type'),
      },
    ];
    const allColumnsUS: ColumnType<SimplifiedProjects>[] = [
      {
        title: t('market.projects.table.community', 'Community'),
        align: 'center',
        dataIndex: 'community',
        render: (value, { project_id }) => (
          <Link
            to={generatePath(Paths.PROJECT_DETAIL, {
              projectId: project_id,
            })}
            target='_blank'
          >
            <b>{value}</b>
          </Link>
        ),
        sorter: getStringSorter('project_name'),
        sortOrder: getSavedSortOrder('project_name'),
        filteredValue: getSavedFilteredValue('project_name'),
        onFilter: getOnFilter('project_name'),
        filters: projectNames.map((value) => ({
          text: value,
          value,
        })),
      },
      {
        title: t('market.projects.table.city', 'City'),
        dataIndex: 'city',
        align: 'center',
        filters: cities.map((value) => ({
          text: value,
          value,
        })),
        sorter: getStringSorter('city'),
        sortOrder: getSavedSortOrder('city'),
        filteredValue: getSavedFilteredValue('city'),
        onFilter: getOnFilter('city'),
      },
      {
        title: t('market.projects.table.builder', 'Builder'),
        dataIndex: 'developer',
        align: 'center',
        filters: developers.map((value) => ({
          text: value,
          value,
        })),
        sorter: getStringSorter('developer'),
        sortOrder: getSavedSortOrder('developer'),
        filteredValue: getSavedFilteredValue('developer'),
        onFilter: getOnFilter('developer'),
      },
      {
        title: t('market.projects.table.district', 'District'),
        dataIndex: 'district',
        align: 'center',
        filters: districts.map((value) => ({
          text: value,
          value,
        })),
        sorter: getStringSorter('district'),
        sortOrder: getSavedSortOrder('district'),
        filteredValue: getSavedFilteredValue('district'),
        onFilter: getOnFilter('district'),
      },
      {
        title: t('market.projects.table.units', 'Units'),
        dataIndex: 'available_units',
        align: 'center',
        sorter: getNumericSorter('available_units'),
        sortOrder: getSavedSortOrder('available_units'),
      },
      {
        title: t('market.projects.table.amenities', 'Amenities'),
        dataIndex: 'amenities',
        align: 'center',
        sorter: getNumericSorter('amenities'),
        sortOrder: getSavedSortOrder('amenities'),
      },
      {
        title: t('market.projects.table.schools', 'Schools'),
        dataIndex: 'schools',
        align: 'center',
        sorter: getNumericSorter('schools'),
        sortOrder: getSavedSortOrder('schools'),
      },
      {
        title: t('market.projects.table.beds', 'Beds'),
        dataIndex: 'layouts',
        align: 'center',
        sorter: (a, b) =>
          sortLayouts(
            a.layouts?.length > 0 ? a.layouts.sort(sortLayouts)[0] : '',
            b.layouts?.length > 0 ? b.layouts.sort(sortLayouts)[0] : '',
          ),
        render: (value: string[], project) => {
          try {
            return value
              ?.sort(sortLayouts)
              .map((layout) => getLayoutAbbr(layout))
              .join(', ');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error, project);
            return null;
          }
        },
        filters: layouts.sort(sortLayouts).map((value) => ({
          text: getLayoutAbbr(value),
          value,
        })),
        onFilter: (value, record) => record.layouts?.includes(value as string),
        sortOrder: getSavedSortOrder('layouts'),
        filteredValue: getSavedFilteredValue('layouts'),
      },
      {
        title: t('market.projects.table.baths', 'Baths'),
        dataIndex: 'baths',
        align: 'center',
        sorter: (a, b) => {
          const varA = a.baths.length > 0 ? Number(a.baths.sort()[0]) : -1;
          const varB = b.baths.length > 0 ? Number(b.baths.sort()[0]) : -1;
          return varA - varB;
        },
        render: (value: string[], project) => {
          try {
            return value?.sort().join(', ');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error, project);
            return null;
          }
        },
        filters: baths.sort().map((value) => ({
          text: value,
          value,
        })),
        onFilter: (value, record) => record.baths?.includes(Number(value)),
        sortOrder: getSavedSortOrder('baths'),
        filteredValue: getSavedFilteredValue('baths'),
      },
      {
        title: t('market.projects.table.garages', 'Garages'),
        dataIndex: 'garages',
        align: 'center',
        sorter: (a, b) => {
          const varA = a.garages.length > 0 ? Number(a.garages.sort()[0]) : -1;
          const varB = b.garages.length > 0 ? Number(b.garages.sort()[0]) : -1;
          return varA - varB;
        },
        render: (value: string[], project) => {
          try {
            return value?.sort().join(', ');
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error, project);
            return null;
          }
        },
        filters: garages.sort().map((value) => ({
          text: value,
          value,
        })),
        onFilter: (value, record) => record.garages?.includes(Number(value)),
        sortOrder: getSavedSortOrder('garages'),
        filteredValue: getSavedFilteredValue('garages'),
      },
      {
        title: t('market.projects.table.available_price', 'Available Price'),
        dataIndex: availablePriceDataIndex,
        sorter: getNumericSorter(availablePriceDataIndex),
        align: 'center',
        render: (value) => formatCurrency(value),
        sortOrder: getSavedSortOrder(availablePriceDataIndex),
      },
      {
        title: t('market.projects.table.sold_price', 'Sold Price'),
        dataIndex: soldPriceDataIndex,
        sorter: getNumericSorter(soldPriceDataIndex),
        align: 'center',
        render: (value) => formatCurrency(value),
        sortOrder: getSavedSortOrder(soldPriceDataIndex),
      },
    ];
    const otherColumns = extended
      ? allColumns
      : allColumns.filter(({ dataIndex }) =>
          [
            'project_name',
            'developer',
            'available_units',
            'date_construction_completion',
            'layouts',
            'total_number_of_units',
            availablePriceDataIndex,
          ].includes(dataIndex as string),
        );

    const usColumns = extended
      ? allColumnsUS
      : allColumnsUS.filter(({ dataIndex }) =>
          [
            'project_name',
            'city',
            'developer',
            'available_units',
            availablePriceDataIndex,
          ].includes(dataIndex as string),
        );

    return isUsRegion ? usColumns : otherColumns;
  }, [projects, showPricePerSM, t, sortLayouts, extended, isUsRegion, savedSorter.field, savedSorter.order, savedFilters, selectedProjectNames, allProjects, selectedDeveloperNames, getLayoutAbbr, formatCurrency]);

  const handleChange: TableProps<SimplifiedProjects>['onChange'] = (
    changed_pagination,
    changed_filters,
    changed_sorter,
    extra,
  ) => {
    setTableData({
      filteredData: extra.currentDataSource,
      filters: changed_filters,
      pagination: changed_pagination,
      sorter: changed_sorter as SorterResult<SimplifiedProjects>,
      // ^ multiple sorts are disabled, so type cast is safe
    });
  };

  const onHeaderRow: TableProps<SimplifiedProjects>['onHeaderRow'] = () => ({
    onMouseEnter: () => setIsMouseOver(true),
    onMouseLeave: () => setIsMouseOver(false),
  });

  useEffect(() => {
    const height = ref.current?.parentElement?.offsetHeight;
    if (height && !customHeight) {
      setParentHeight(height - 2 * PADDING - TABLE_HEADER_HEIGHT);
    }
  }, []);

  useEffect(() => {
    if (!columnDataIndexes.length) {
      // @ts-ignore
      const columnIndexes = columns.map((column) => column.dataIndex as string);
      setColumnDataIndexes(storedIndexes ? JSON.parse(storedIndexes) : columnIndexes);
    }
  }, [columnDataIndexes, columns, setColumnDataIndexes, storedIndexes]);

  useEffect(() => {
    if (storedFilters) {
      setColumnFilterValues(JSON.parse(storedFilters));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedFilters]);

  useEffect(() => {
    setColumnDataIndexes((prevIndexes) => {
      if (showPricePerSM) {
        return prevIndexes.map((item) => {
          if (item === 'avg_price') {
            return 'avg_price_per_sm';
          }
          if (item === 'sold_avg_price') {
            return 'sold_avg_price_per_sm';
          }
          return item;
        });
      }
        return prevIndexes.map((item) => {
          if (item === 'avg_price_per_sm') {
            return 'avg_price';
          }
          if (item === 'sold_avg_price_per_sm') {
            return 'sold_avg_price';
          }
          return item;
        });
    });
  }, [showPricePerSM, setColumnDataIndexes]);

  const filteredColumns = columns
    // @ts-ignore
    .filter((col) => col.dataIndex && !columnsFilterValues.includes(col.dataIndex.toString()))
    // @ts-ignore
    .sort((a, b) => columnDataIndexes.indexOf(a.dataIndex as string) - columnDataIndexes.indexOf(b.dataIndex as string));

  return (
    projectsLoading
      ? <CenteredSpinner />
      : (
        <Table<SimplifiedProjects>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          rowKey='project_id'
          columns={filteredColumns}
          rowClassName='table-row'
          className={clsx('h-auto', !isMouseOver ? 'hide-filters' : '')}
          size='small'
          scroll={{ y: customHeight ?? parentHeight }}
          onHeaderRow={onHeaderRow}
          pagination={false}
          onRow={(data) => ({
            onClick: () => setSelectedProjectId(data.project_id),
          })}
          rowSelection={isReadMode ? undefined : rowSelection}
          dataSource={projects}
          onChange={handleChange}
          {...rest}
        />
      )
  );
};
