import { FC } from 'react';
import officeIcon from './officeIcon.svg';
import secondaryIcon from './secondaryIcon.svg';
import rentalIcon from './rentalIcon.svg';

type Market = 'primary' | 'secondary' | 'rental';

type Props = {
  market?: Market
  disabled?: boolean
}

const marketIcons: { [key in Market]: string } = {
  primary: officeIcon,
  secondary: secondaryIcon,
  rental: rentalIcon,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const MarketIcon: FC<Props> = ({ market = 'primary', disabled = 'false' }) => (
  <div className={`flex items-center justify-center rounded-full w-[24px] h-[24px] p-1 ${disabled ? 'bg-[#BDBDBD]' : 'bg-[#5A72B1]'}`}>
    <img src={marketIcons[market]} alt={`${market} market`} />
  </div>
);
