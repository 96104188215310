import { useTranslation } from 'react-i18next';
import { useStoreState } from '.';
import { MetricSystemEnum } from '../api/enums';

export function useFlatLayout(): {
  getLayout: (value: string, notAggregated?: boolean) => string;
  getLayoutAbbr: (value: string, notAggregated?: boolean) => string;
} {
  const metricSystem = useStoreState(
    (state) => state.user.profile?.system_of_measurement,
  );
  const { t } = useTranslation();
  const getLayout = (value: string | null, notAggregated?: boolean): string => {
    if (value === null) {
      return t('common.null', 'Unknown');
    }
    if (metricSystem === MetricSystemEnum.METRIC) {
      if (!notAggregated && value.includes('layout_4')) {
        return t('enums.eu_layouts.aggregated.layout_4');
      }
      return t(`enums.eu_layouts.${value}`);
    }
    if (!notAggregated && value.includes('layout_4')) {
      return t('enums.layouts.aggregated.layout_4');
    }
    return t(`enums.layouts.${value}`);
  };

  const getLayoutAbbr = (value: string | null, notAggregated?: boolean): string => {
    if (value === null) {
      return t('common.null', 'Unknown');
    }
    if (metricSystem === MetricSystemEnum.METRIC) {
      if (!notAggregated && value.includes('layout_4')) {
        return t('enums.eu_layouts_abbreviation.aggregated.layout_4');
      }
      return t(`enums.eu_layouts_abbreviation.${value}`);
    }
    if (!notAggregated && value.includes('layout_4')) {
      return t('enums.layouts_abbreviation.aggregated.layout_4');
    }
    return t(`enums.layouts_abbreviation.${value}`);
  };

  return { getLayout, getLayoutAbbr };
}
