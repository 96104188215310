import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { MetricsRowProps } from './LayoutsCol';

export const LayoutsPopover: FC<PropsWithChildren<MetricsRowProps>> = ({
  children,
  layout,
  total,
  sold,
  reserved,
}) => {
  const { t } = useTranslation();
  const is4r = layout.includes('4');
  const popoverTitle = is4r
    ? t('dashboard.kpi.layouts.tooltip.larger_than_4', { layout })
    : t('sales.targets.subtitle.units');
  return (
    <Popover
      placement='bottom'
      trigger='hover'
      content={
        <div className='w-[200px]'>
          <div className='font-bold'>{`${layout} ${popoverTitle}`}</div>
          <div className='flex justify-between'>
            <div>{t('dashboard.kpi.sold')}</div>
            <div>{`${sold}/${total}`}</div>
          </div>
          <div className='flex justify-between'>
            <div>{t('dashboard.kpi.reserved')}</div>
            <div>{`${reserved}/${total}`}</div>
          </div>
          <div className='flex justify-between'>
            <div>{t('dashboard.kpi.sold_reserved')}</div>
            <div>{`${sold + reserved}/${total}`}</div>
          </div>
          <div className='font-bold flex justify-between'>
            <div>{`${t('dashboard.kpi.sold_reserved')} %`}</div>
            <div>
              {!!!total
                ? '0 %'
                : `${Math.round(((sold + reserved) * 100) / total)} %`}
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );
};
