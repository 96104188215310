import { FC, memo, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ArrowTrendingUpIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { Card, Divider, Pagination, Popover, Spin } from 'antd';
import { DefaultCardOptions } from './card/cardOptions/DefaultCardOptions';
import { useRegionFormatting, useStoreState } from '../../hooks';
import { formatPercent } from '../../utils';
import { PriceListUpdateData } from '../../api/mockData/priceUpdateData';
import { AVAILABLE, SOLD } from '../../styles/constants';
import { K, M } from '../../constants';

type ChartPieProps = {
  total: number;
  sold: number;
};

const ChartPie: FC<ChartPieProps> = ({ total, sold }) => (
  <div className='grid grid-cols-2 items-center gap-4'>
    <div className='w-[24px] justify-self-end'>
      <PieChart
        lineWidth={60}
        startAngle={-90}
        animate
        className='w-[24px]'
        data={[
          { value: sold, color: AVAILABLE },
          { value: total - sold, color: SOLD },
        ]}
      />
    </div>
    <span className='font-semibold text-[1.2rem] text-left w-[40px]'>
      {sold === 0 ? '0%' : formatPercent(sold / total, { truncate: 0 })}
    </span>
  </div>
);

type PriceUpdatesRowProps = {
  attributeName: string;
  attributeFlag: string;
  attributeByLayout: string | null;
  totalPriceChange: number;
  availableCount: number;
  totalCount: number;
};

const PriceUpdatesRow: FC<PriceUpdatesRowProps> = ({
  attributeFlag,
  attributeName,
  attributeByLayout,
  totalPriceChange,
  availableCount,
  totalCount,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleRowClick = (): void => {
    navigate(`/price-update?name=${attributeName}&flag=${attributeFlag}`);
  };
  const isLayoutClass = attributeName === 'layout_class';

  return (
    <div>
      <div className='flex justify-between'>
        <div className='flex gap-2 items-center flex-wrap'>
          <span className='font-semibold w-auto'>
            {t(`price_update.title.${attributeName}`)}
          </span>
          {attributeByLayout && (
            <div className='text-center'>
              <span className='text-[#828282]'> / </span>
              <span>
                {t(`enums.house_parts.layout_class.${attributeByLayout}`)}
              </span>
            </div>
          )}
          <span className='text-[#828282]'> /</span>
          <span className='text-[#828282] whitespace-nowrap'>
            {t(
              isLayoutClass
                ? attributeFlag
                : `price_update.subtitle.${attributeFlag}`,
            )}
          </span>
          <span
            className={clsx(
              totalPriceChange > 0 ? 'bg-[#CCD9EC]' : 'bg-[#DEDEDE]',
              'font-semibold rounded-[14.5px] py-[2px] px-[8px] mr-[0.5rem]',
            )}
          >
            {formatPercent(totalPriceChange)}
          </span>
        </div>
        <div className='flex gap-6'>
          <ChartPie total={totalCount} sold={totalCount - availableCount} />
          <ChevronRightIcon
            className='w-10 text-[#BDBDBD] cursor-pointer'
            onClick={() => handleRowClick()}
          />
        </div>
      </div>
      <Divider className='mt-4' />
    </div>
  );
};

export const PriceUpdatesComponent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { formatCurrency } = useRegionFormatting();
  const { dashboardLoading } = useStoreState((state) => state.dashboard);
  const priceUpdates = useStoreState(
    (state) => state.dashboard.dashboardData?.price_updates,
  );

  const updateData = useStoreState(
    (state) => state.priceList.updateDataUpdated,
  );

  const { projectId } = useStoreState((state) => state.filters);
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const showUpdates = useMemo(
    () =>
      overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );

  const largeUpdates = [
    ...(updateData?.updates?.positive?.large_updates ?? []),
    ...(updateData?.updates?.negative?.large_updates ?? []),
  ];

  const { current_price_per_sm, recommended_price_per_sm } = priceUpdates ?? {
    current_price_per_sm: 0,
    recommended_price_per_sm: 0,
  };

  const change = formatPercent(
    (recommended_price_per_sm - current_price_per_sm) / current_price_per_sm,
  );

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 3;

  // Reset page when data changes
  useEffect(() => {
    setCurrentPage(1);
  }, [largeUpdates.length]);

  const paginatedData = largeUpdates.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const handlePageChange = (page: number): void => {
    setCurrentPage(page);
  };

  const handleMaximize = (): void => {
    navigate('/price-update');
  };

  return (
    <Card bordered={false} className='flex flex-col h-full rounded-[1.6rem]'>
      <div className='flex items-center justify-between pb-8'>
        <div className='flex gap-4 items-center'>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2'>
            <ArrowTrendingUpIcon className='stroke-2 w-10 h-10' />
          </div>
          <div className='font-bold'>{t('dashboard.price_updates.title')}</div>
        </div>
        {!!paginatedData.length && showUpdates && (
          <DefaultCardOptions handleMaximize={handleMaximize} />
        )}
      </div>
      {!dashboardLoading ? (
        <>
          {priceUpdates && (
            <div className='flex justify-around'>
              <Popover
                placement='bottom'
                trigger='hover'
                content={
                  <div>{t('dashboard.kpi.price_update.tooltip.current')}</div>
                }
              >
                <div className='text-center'>
                  <div className='font-bold text-[2.4rem]'>
                    {formatCurrency(
                      priceUpdates.current_price_per_sm,
                      false,
                      Math.abs(priceUpdates.current_price_per_sm) > M,
                      Math.abs(priceUpdates.current_price_per_sm) < M &&
                        Math.abs(priceUpdates.current_price_per_sm) > K,
                    )}
                  </div>
                  <div>{t('dashboard.kpi.current')}</div>
                </div>
              </Popover>
              <Popover
                placement='bottom'
                trigger='hover'
                content={
                  <div>
                    {t('dashboard.kpi.price_update.tooltip.recommended')}
                  </div>
                }
              >
                <div className='text-center'>
                  <div className='font-bold text-[2.4rem]'>
                    {formatCurrency(
                      priceUpdates.recommended_price_per_sm,
                      false,
                      Math.abs(priceUpdates.recommended_price_per_sm) > M,
                      Math.abs(priceUpdates.recommended_price_per_sm) < M &&
                        Math.abs(priceUpdates.recommended_price_per_sm) > K,
                    )}
                  </div>
                  <div>{t('dashboard.kpi.recommended')}</div>
                </div>
              </Popover>
              <Popover
                placement='bottom'
                trigger='hover'
                content={
                  <div>{t('dashboard.kpi.price_update.tooltip.change')}</div>
                }
              >
                <div className='text-center'>
                  <div className='font-bold text-[2.4rem]'>{`${change}`}</div>
                  <div>{t('enums.state.change')}</div>
                </div>
              </Popover>
            </div>
          )}
          {!!paginatedData.length && showUpdates && (
            <>
              <Divider />
              <div className='px-10 pt-10 flex flex-col items-center w-full'>
                <div className='w-full grid grid-cols-1 grid-rows-3'>
                  {paginatedData.map((table) => (
                    <PriceUpdatesRow
                      key={`${table.attribute_flag}-${table.attribute_name}-${table.attribute_by_layout}`}
                      attributeFlag={table.attribute_flag}
                      attributeName={table.attribute_name}
                      attributeByLayout={table.attribute_by_layout}
                      totalPriceChange={table.revenue_change_percentage}
                      availableCount={
                        table.total_count - table.unavailable_count
                      }
                      totalCount={table.total_count}
                    />
                  ))}
                </div>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={largeUpdates.length}
                  onChange={handlePageChange}
                  className='mt-4'
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div className='flex w-full justify-center items-center'>
          <Spin spinning />
        </div>
      )}
    </Card>
  );
};

export const PriceUpdates = memo(PriceUpdatesComponent);
