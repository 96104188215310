import { Button } from 'antd';
import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { ReactComponent as AddPolygonSvg } from '../../assets/map-controls/add-polygon.svg';
import { ReactComponent as AddCircleSvg } from '../../assets/map-controls/add-circle.svg';
import { ReactComponent as PointerSvg } from '../../assets/map-controls/pointer.svg';
import { ReactComponent as HandSvg } from '../../assets/map-controls/hand.svg';
import { ReactComponent as TrashSvg } from '../../assets/map-controls/trash.svg';
import styles from './MapControls.module.css';
import { useStoreActions, useStoreState } from '../../hooks';

type Props = {
  setDrawingMode: (drawingMode: google.maps.drawing.OverlayType | null) => void;
};

export const ControlButtonsGroup: FC<Props> = ({ setDrawingMode }) => {
  const { mapControls } = useStoreState((state) => state.market);
  const { setMapControls } = useStoreActions((actions) => actions.market);

  useEffect(() => () => setMapControls('move'), []);

  return (
    <Button.Group
      className={clsx(
        'rounded-[25px] h-fit pointer-events-auto min-w-[258px]',
        styles.zoomBtnGrp,
      )}
    >
      <Button
        className='rounded-[25px] border-none'
        style={{
          background: mapControls === 'edit' ? '#9EB9DA' : 'white',
        }}
        onClick={() => {
          setDrawingMode(null);
          setMapControls('edit');
        }}
      >
        <PointerSvg className={styles.icon} />
      </Button>
      <div className='bg-bmlightgrey w-1' />
      <Button
        className={clsx('rounded-[25px] border-none')}
        style={{
          background: mapControls === 'move' ? '#9EB9DA' : 'white',
        }}
        onClick={() => {
          setDrawingMode(null);
          setMapControls('move');
        }}
      >
        <HandSvg className={styles.icon} />
      </Button>
      <div className='bg-bmlightgrey w-1' />
      <Button
        className={clsx('rounded-[25px] border-none')}
        style={{
          background: mapControls === 'polygon' ? '#9EB9DA' : 'white',
        }}
        onClick={() => {
          setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
          setMapControls('polygon');
        }}
      >
        <AddPolygonSvg className={styles.icon} />
      </Button>
      <div className='bg-bmlightgrey w-1' />
      <Button
        className='rounded-[25px] border-none'
        style={{
          background: mapControls === 'circle' ? '#9EB9DA' : 'white',
        }}
        onClick={() => {
          setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
          setMapControls('circle');
        }}
      >
        <AddCircleSvg className={styles.icon} />
      </Button>
      <div className='bg-bmlightgrey w-1' />
      <Button
        className='rounded-[25px] border-none'
        style={{
          background: mapControls === 'delete' ? '#9EB9DA' : 'white',
        }}
        onClick={() => {
          setDrawingMode(null);
          setMapControls('delete');
        }}
      >
        <TrashSvg className={styles.icon} />
      </Button>
    </Button.Group>
  );
};
