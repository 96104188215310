import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';
import { ProjectPhase } from '../../api/types';

export const TAGS_MOCK = [
  { name: ProjectPhase.COMPLETED, color: '#5A72B1' },
  { name: ProjectPhase.DEVELOPMENT, color: '#9EB9DA' },
  { name: ProjectPhase.PLANNED, color: '#FFFFFF' },
  { name: ProjectPhase.ARCHIVED, color: '#828282' },
];

export enum MapType {
  PROJECTS = 'Projects',
  CREATE_REPOROT = 'Create report',
  DAILY_NEWS = 'Daily news',
  REPORT = 'Report',
}

type TagProps = {
  tag: ProjectPhase;
  color: string;
  isClickable: boolean;
  isActive?: boolean;
  onToggle?: (tag: ProjectPhase) => void;
};

export const MapProjectStatusTag: FC<TagProps> = ({
  tag,
  color,
  isActive = false,
  isClickable,
  onToggle,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'text-bmblue pointer-events-auto w-fit flex border gap-2 py-1 pr-4 pl-3 items-center bg-white rounded-[25px]',
        isActive ? 'border-bmblue' : 'border-bmgray',
        isClickable ? 'cursor-pointer drop-shadow' : 'cursor-normal',
      )}
      onClick={() => isClickable && onToggle && onToggle(tag)}
    >
      <span
        className={clsx(
          'h-6 w-6 rounded-full',
          tag === 'Planned' ? 'border-bmblue border-[1px]' : '',
          !isActive && isClickable ? 'opacity-50' : '', // Apply opacity when not active
        )}
        style={{ backgroundColor: color }}
      />
      <span
        className={clsx(
          'select-none',
          // eslint-disable-next-line no-nested-ternary
          isClickable
            ? isActive
              ? 'font-normal text-bmblue'
              : 'font-light text-bmgray'
            : 'font-semibold text-bmblue',
        )}
      >
        {t(`market.reports.project_phase.${tag.toLowerCase()}`, tag)}
      </span>
    </div>
  );
};

type Props = {
  mapType?: MapType;
};

export const MapProjectStatusTags: FC<Props> = ({ mapType }) => {
  const { availableMapTags, activeTags } = useStoreState(
    (state) => state.market,
  );
  const { setActiveTags } = useStoreActions((actions) => actions.market);
  const isCreateReport = mapType === MapType.CREATE_REPOROT;

  // Filter TAGS_MOCK to include only the tags available in availableMapTags
  const filteredTags = TAGS_MOCK.slice(0, isCreateReport ? 3 : 4).filter(
    (tag) => availableMapTags.includes(tag.name),
  );

  const handleToggle = (tag: ProjectPhase): void => {
    if (activeTags.includes(tag)) {
      setActiveTags(activeTags.filter((t) => t !== tag)); // Remove the tag if it's active
    } else {
      setActiveTags([...activeTags, tag]); // Add the tag if it's inactive
    }
  };

  return (
    <div className='flex gap-4 h-fit flex-wrap'>
      {filteredTags.map((tag) => (
        <MapProjectStatusTag
          key={tag.name}
          tag={tag.name}
          color={tag.color}
          isActive={activeTags.includes(tag.name)}
          isClickable
          onToggle={handleToggle}
        />
      ))}
    </div>
  );
};
