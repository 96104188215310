import { FC } from 'react';
import { Interior as InteriorData } from '../../../api/mockData/priceListDetailData';
import { useRegionFormatting } from '../../../hooks';
import { PriceBreakdownRow } from './PriceBreakdownRow';

interface Props {
  data: InteriorData;
  isHouse: boolean;
}

export const Interior: FC<Props> = ({
  data: { difference_floor_area, floor_area, layout, layout_class },
  isHouse,
}) => {
  const { formatAreaUnits } = useRegionFormatting();
  const showLayout = !!layout && !!layout.value;
  const showLayoutClass = !!layout_class && !!layout_class.value;

  return (
    <div>
      {showLayout && (
        <PriceBreakdownRow
          houseAreaKey='interior'
          className='row-top-spacing'
          valueContent={layout.value}
          updatePrice={layout.update_price}
          housePartKey='layout'
          layoutValue
        />
      )}
      {showLayoutClass && (
        <PriceBreakdownRow
          valueContent={layout_class.value}
          updatePrice={layout_class.update_price}
          housePartKey='layout_class'
          {...(!showLayout
            ? {
                className: 'row-top-spacing',
                houseAreaKey: 'interior',
              }
            : {})}
        />
      )}
      <PriceBreakdownRow
        housePartKey='floor_area'
        valueContent={formatAreaUnits(floor_area.value)}
        price={floor_area.price}
        updatePrice={floor_area.update_price}
        {...(!showLayout && !showLayoutClass
          ? {
              className: 'row-top-spacing',
              houseAreaKey: 'interior',
            }
          : null)}
      />
      {!isHouse ? (
        <PriceBreakdownRow
          housePartKey='difference_floor_area'
          valueContent={formatAreaUnits(difference_floor_area?.value ?? null)}
          price={difference_floor_area?.price ?? null}
          updatePrice={difference_floor_area?.update_price ?? null}
        />
      ) : null}
    </div>
  );
};
