/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useJsApiLoader } from '@react-google-maps/api';
import { fetchBlockMapData } from '../../../../api';
import {
  useStoreActions,
  useStoreState,
} from '../../../../hooks';
import { Card } from '../../../Card';
import { styles } from './styles';
import { CenteredSpinner } from '../../../CenteredSpinner';
import { parseGeometry } from '../../../../utils/utils';
import { GOOGLE_MAPS_LIBRARIES } from '../../../../constants';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { Map as MapComponent } from '../../../addReport/Map';
import { SimplifiedProjects } from '../../../../store/market';
import { MapType } from '../../../MapControls/MapProjectStatusTags';

export const Map: FC = () => {
  const { setPolygons, setCircles } = useStoreActions((state) => state.market);
  const [data, setData] = useState<SimplifiedProjects[]>([]);
  const [cities, setCities] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(true);
  const dashboardName = useStoreState(
    (state) => state.market.dashboardOverviewData?.dashboard_name,
  );
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const projects = useStoreState((state) => state.market.simplifiedProjects);

  const { t } = useTranslation();

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY) {
    throw new Error('GOOGLE API KEY not filled');
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  const dataWithoutNullGPS = useMemo(
    () =>
      data.filter(
        ({ gps_latitude, gps_longitude, include }) =>
          gps_latitude && gps_longitude && include,
      ),
    [data],
  );

  // this is just workaround to get rid of duplicated Bratislava region - should be handled on BE in the future
  // https://www.notion.so/builtmind/Region-Reload-string-21fad263b2a0419fabbf0c8a0289243d?pvs=4
  const removeDuplicatedRegion = (regions: string[]): string[] =>
    regions.filter(
      (region: string) => region.toLowerCase() !== 'bratislava region reload',
    );

  const convertToRegionNames = (citiesArray: string[]): string[] =>
    citiesArray.map((city) =>
      city
        .split('_')
        .map((_city) => _city.charAt(0).toUpperCase() + _city.slice(1))
        .join(' ')
        .replace('Tmp', 'Region'),
    );

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockMapData(
        paramsPM.dashboardId,
        paramsPM.date,
        pricePerSmCalculation,
      )
        .then(({ data: { cities: _cities, map: mapData, geometry } }) => {
          const convertedCities = removeDuplicatedRegion(
            convertToRegionNames(_cities),
          );
          const mergedData = mapData.map((mapProject) => {
            const project = projects.find(
              ({ project_id }) => project_id === mapProject.project_id,
            );
            if (project) {
              return {
                ...project,
                ...mapProject,
              };
            }

            return {
              ...mapProject,
              available_units: 1,
              include: true,
            };
          });
          if (geometry) {
            const { parsedPolygons, parsedCircles } = parseGeometry(geometry);
            setPolygons(JSON.parse(JSON.stringify(parsedPolygons)));
            setCircles(JSON.parse(JSON.stringify(parsedCircles)));
          }
          setData(mergedData as SimplifiedProjects[]);
          setCities(convertedCities);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsPM, pricePerSmCalculation]);

  return (
    <Card css={styles.card}>
      <div css={styles.mapContainer}>
        {isLoaded && !isLoading ? (
          <MapComponent projects={dataWithoutNullGPS} type={MapType.REPORT} />
        ) : (
          <CenteredSpinner />
        )}
      </div>
      <div css={styles.metaContainer}>
        <div css={styles.metaTitle}>{dashboardName}</div>
        <div>{cities?.join(', ')}</div>
        <div>
          {t('market.reports.map.projects_count', '{{count}} projects', {
            count: data.length,
          })}
        </div>
      </div>
    </Card>
  );
};
