import { css } from '@emotion/react/macro';

export const styles = {
  headerContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '4px',
    marginBottom: '1rem',
  }),
  arrowIcon: css({
    height: '24px',
    width: '24px',
  }),
  fontXl: css({
    fontWeight: '600',
    fontSize: '22px',
  }),
  panelHeaderContainer: css({
    borderBottom: '2px solid #DEDEDE',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  }),
  panelHeaderLeft: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  }),
  panelHeaderRight: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  totalPriceChange: css({
    marginLeft: '1rem',
    borderRadius: '14.5px',
    padding: '2px 8px',
    backgroundColor: '#DEDEDE',
  }),
  panelHeaderContainerTitle: css({
    fontWeight: '600',
    fontSize: '14px',
    marginRight: '0.5rem',
  }),
  panelHeaderContainerSubtitle: css({
    fontSize: '14px',
    fontWeight: '400',
    color: '#828282',
    marginLeft: '0.5rem',
  }),
  tooltipContainer: css({
    display: 'flex',
    padding: 5,
  }),
  tooltipColumnAvailability: css({
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 700,
  }),
  tooltipColumn: css({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '2.5rem',
  }),
};
