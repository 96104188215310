import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatNumber } from '../../../utils/formatters';
import { PriceBreakdownRow } from './PriceBreakdownRow';
import { Layout as LayoutData } from '../../../api/mockData/pricingPriceListDetaildata';

interface Props {
  data: LayoutData;
  isHouse: boolean;
}
export const LayoutUpdated: FC<Props> = ({
  data: {
    bathrooms,
    bedrooms_ratio,
    category,
    living_area_ratio,
    next_to_elevator,
    noise,
    permit_regular,
    sunny_kitchen,
    walk_through_room,
    house_type,
    // masters_bedroom,
  },
  isHouse,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <PriceBreakdownRow
        className='row-top-spacing'
        houseAreaKey='layout'
        housePartKey='num_of_bathrooms'
        valueContent={bathrooms.value}
        price={bathrooms.price}
        updatePrice={bathrooms.update_price}
      />
      <PriceBreakdownRow
        housePartKey='walk_through_room'
        valueContent={walk_through_room.value ? t('common.yes') : t('common.no')}
        price={walk_through_room.price}
        updatePrice={walk_through_room.update_price}
      />
      <PriceBreakdownRow
        housePartKey='kitchen_luminosity'
        valueContent={sunny_kitchen.value ? t('common.yes') : t('common.no')}
        price={sunny_kitchen.price}
        updatePrice={sunny_kitchen.update_price}
      />
      {!isHouse ? (
        <PriceBreakdownRow
          housePartKey='next_to_elevator'
          valueContent={next_to_elevator.value ? t('common.yes') : t('common.no')}
          price={next_to_elevator.price}
          updatePrice={next_to_elevator.update_price}
        />
      ) : null}
      {typeof bedrooms_ratio.value === 'number' && (
        <PriceBreakdownRow
          housePartKey='ratio_bedrooms'
          valueContent={formatNumber(bedrooms_ratio.value, { round: 2 })}
          price={bedrooms_ratio.price}
          updatePrice={bedrooms_ratio.update_price}
        />
      )}
      {living_area_ratio &&
        typeof living_area_ratio.value === 'number' &&
        living_area_ratio.value > 0 && (
          <PriceBreakdownRow
            housePartKey='ratio_living'
            valueContent={formatNumber(living_area_ratio.value, { round: 2 })}
            price={living_area_ratio.price}
            updatePrice={living_area_ratio.update_price}
          />
        )}
      <PriceBreakdownRow
        housePartKey='noise'
        valueContent={noise.value}
        popoverContent={
          <Trans i18nKey='price_list.hints.noise.desc'>
            3 if there is nature or no building in the next 100m. <br />
            2 if the next building is 15- 100m away. <br />1 if the next
            building is less than 15m away from the unit from the living room.
          </Trans>
        }
        price={noise.price}
        updatePrice={noise.update_price}
      />
      {!isHouse ? (
        <>
          <PriceBreakdownRow
            housePartKey='permit_regular'
            valueContent={permit_regular.value ? t('common.yes') : t('common.no')}
            price={permit_regular.price}
            updatePrice={permit_regular.update_price}
          />
          <PriceBreakdownRow
            housePartKey='category'
            valueContent={t(`enums.category.${category.value}`, category.value)}
            price={category.price}
            updatePrice={category.update_price}
            className='row-spacing'
          />
        </>
      ) : (
        <>
          <PriceBreakdownRow
            housePartKey='house_type'
            valueContent={t(
              `enums.house_type.${house_type.value}`,
              house_type.value,
            )}
            price={house_type.price}
            updatePrice={house_type.update_price}
            className='row-spacing'
          />
        </>
      )}
    </div>
  );
};
