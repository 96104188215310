import { FC, useMemo, useState } from 'react';
import { ArrowsPointingOutIcon, CalendarIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { Card } from '../../../Card';
import { PlanData } from '../../../../api/mockData/sales';
import { getPage, paginatePlan } from './paginatePlan';
import styles from './Plan.module.css';
import { useBreakpoints } from '../../../../hooks';

type PlanProps = {
  plan: PlanData[];
  onMaximize: () => void;
}

export const Plan: FC<PlanProps> = ({ plan, onMaximize }) => {
  const { isSmallScreen } = useBreakpoints();
  const { t } = useTranslation();
  const pagesResults = useMemo(() => paginatePlan(plan, 4, 'monthly'), [plan]);
  const pages = Object.keys(pagesResults);
  const initialPage = getPage(pagesResults);
  const [page, setPage] = useState(initialPage);

  if (page > pages.length) {
    return <Spin />;
  }

  const handlePaginate = (direction: 'next' | 'prev'): void => {
    if (direction === 'next') {
      setPage(page < pages.length ? page + 1 : page);
    } else {
      setPage(page > 1 ? page - 1 : page);
    }
  };

  const emptyDivsCount = Math.max(0, 4 - (pagesResults[page] ? pagesResults[page].length : 0));

  return (
  <Card bordered={false} className='plan'>
    <div className={styles.planContainer}>
        <div className={styles.planHeaderWrapper}>
          <div className={styles.planTitleWrapper}>
          <div className={styles.badge}>
            <CalendarIcon className={styles.icon} />
          </div>
          <div>{t('sales.plan.title', 'Sales Plan')}</div>
          </div>
          {!isSmallScreen && <ArrowsPointingOutIcon className={styles.maximize} onClick={onMaximize} />}
        </div>
        <div className={styles.planTableContainer}>
          <div className={styles.planTitleRow}>
            <span className='col-span-2 text-end cursor-pointer' onClick={() => handlePaginate('prev')}>{page > 1 && '<'}</span>
              {pagesResults && pagesResults[page].map((planItem: PlanData) => (
                <span>{dayjs(planItem.month).format('MM/YY')}</span>
              ))}
              {Array.from({ length: emptyDivsCount }).map(() => (
                <div />
              ))}
            <span className='text-start cursor-pointer' onClick={() => handlePaginate('next')}>{page < pages.length && '>'}</span>
          </div>
            <div className={styles.planTableRowTop}>
              <span className='col-span-2 font-bold text-start'>{t('sales.plan.subtitle.plan', 'Plan')}</span>
                {pagesResults && pagesResults[page].map((planItem: PlanData) => (
                  <span>{planItem.plan_speed.toFixed(1)}</span>
                ))}
            </div>
            <div className={styles.planTableRowBottom}>
              <span className='col-span-2 font-bold text-start'>{t('sales.plan.subtitle.normal_speed', 'Normal Speed')}</span>
                {pagesResults && pagesResults[page].map((planItem: PlanData) => (
                  <span>{planItem.normal_speed.toFixed(1)}</span>
                ))}
            </div>
        </div>
    </div>
  </Card>
  );
};
