import { Button } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleCancel?: () => void;
  handleSave?: () => void;
};

export const ActionButtons: FC<Props> = ({ handleCancel, handleSave }) => {
  const { t } = useTranslation();
  return (
    <div className='fixed bottom-4 left-1/2 transform -translate-x-1/2 space-x-4 pointer-events-auto z-20'>
      <Button shape='round' onClick={handleCancel} className='py-3 h-fit'>
        <div className='px-12'>{t('common.cancel')}</div>
      </Button>
      <Button
        className='h-fit py-3'
        shape='round'
        type='primary'
        onClick={handleSave}
      >
        <span className='px-12'>{t('common.save')}</span>
      </Button>
    </div>
  );
};
