import React from 'react';
import { Menu } from 'antd';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { useBreakpoints, useStoreActions, useStoreState } from '../../hooks';

export const TopBar: React.FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const username = useStoreState((actions) => actions.user.profile?.username);
  const logout = useStoreActions((actions) => actions.reset);
  const handleLogout = (): void => {
    logout();
  };
  const { t } = useTranslation();

  return (
    <Menu
      mode='horizontal'
      className='title-bar'
      triggerSubMenuAction='click'
      items={[
        {
          key: 'user',
          icon: <UserCircleIcon className='bm-stroke w-10 h-10' />,
          label: !isSmallScreen ? username : '',
          children: [
            ...(isSmallScreen ? [{ key: 'profile', label: username, className: 'font-bold custom-disabled', disabled: true }] : []),
            { key: 'logout', onClick: handleLogout, label: t('menu.logout') },
          ],
        },
      ]}
    />
  );
};
