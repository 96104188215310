import React from 'react';
import { Card, Row } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useStoreState } from '../../../hooks';
import { BuildingData, ProjectType } from '../../../store/types';
import { State } from '../../../api/enums';
import { FlatContainer } from './FlatContainer';
import { ViewType } from './types';
import { REVAMP_ALLOWED_DEVELOPERS } from '../../../constants';
import { FlatContainerUpdated } from './FlatContainerUpdated';

const Building = React.memo(
  ({
    data: { name, floors },
    viewType,
  }: {
    data: BuildingData;
    viewType: ViewType;
  }) => {
    const { t } = useTranslation();
    const developerId = useStoreState(
      (state) => state.user.profile?.developer_id ?? 0,
    );

    return (
      <div className='building-wrapper'>
        <div className='building-container'>
          <div className='title'>{name}</div>
          <div className='floors-wrapper'>
            <div className='floors-label'>
              {t('price_list.graphic.floor_label')}
            </div>
            <div className='floors'>
              {floors.map(({ flats: flatsOnFloor, floorNumber }) => (
                <div key={floorNumber} className='floor'>
                  <div className='number'>{floorNumber}</div>
                  <div className='flats'>
                    {flatsOnFloor.map((flat) =>
                      REVAMP_ALLOWED_DEVELOPERS.includes(developerId) ? (
                        <FlatContainerUpdated
                          viewType={viewType}
                          key={flat.id}
                          flat={flat}
                        />
                      ) : (
                        <FlatContainer
                          viewType={viewType}
                          key={flat.id}
                          flat={flat}
                        />
                      ),
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export const PriceListGrid = React.memo(
  ({ viewType }: { viewType: ViewType }) => {
    const { projectType } = useStoreState((state) => state.filters);
    const isRent = projectType === ProjectType.RENT;
    const developerId = useStoreState(
      (state) => state.user.profile?.developer_id ?? 0,
    );
    const { buildingsData, buildingsDataUpdated } = useStoreState(
      (state) => state.priceList,
    );
    const data = REVAMP_ALLOWED_DEVELOPERS.includes(developerId)
      ? buildingsDataUpdated
      : buildingsData;
    const hasUnavailableData = data.some((building) =>
      building.floors.some((floor) =>
        floor.flats.some((flat) => flat.availability === State.UNAVAILABLE),
      ),
    );

    const legendData =
      viewType === 'heatmap' || viewType === 'analytics'
        ? ['increasing', 'stable', 'decreasing']
        : [State.AVAILABLE, State.RESERVED, isRent ? State.LEASED : State.SOLD];
    if (hasUnavailableData) {
      legendData.push(State.UNAVAILABLE);
    }

    const { t } = useTranslation();

    return (
      <Card className='price-list-graphic'>
        <Row className='buildings-map'>
          {data.map((building) => (
            <Building viewType={viewType} data={building} key={building.id} />
          ))}
        </Row>
        <Row>
          <div className='legend'>
            {legendData.map((value) => (
              <div key={value} className='legend-item'>
                <div
                  className={clsx(
                    'color-box',
                    {
                      availability: viewType !== 'heatmap',
                      price: viewType === 'heatmap',
                      analytics: viewType === 'analytics',
                    },
                    value,
                  )}
                />
                <div>
                  {viewType === 'analytics'
                    ? t(`enums.state.analytics.${value}`)
                    : t(`enums.state.${value}`)}
                </div>
              </div>
            ))}
          </div>
        </Row>
      </Card>
    );
  },
);
