import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getPriceListDetailData, getPricingPriceListDetailData } from '../api';
import { StoreModel } from './types';
import { PriceListDetailData } from '../api/mockData/priceListDetailData';
import { getValidPriceListDate, logError } from '../utils/utils';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { PriceDetailFilter } from '../api/enums';
import { PricingPriceListDetailData } from '../api/mockData/pricingPriceListDetaildata';
import { REVAMP_ALLOWED_DEVELOPERS } from '../constants';

export interface PriceListDetailStore {
  data?: PriceListDetailData;
  pricePricingDetailData?: PricingPriceListDetailData;
  comparisonData?: PriceListDetailData[];
  showPrediction: Computed<PriceListDetailStore, boolean, StoreModel>;
  showPredictionUpdated: Computed<PriceListDetailStore, boolean, StoreModel>;
  comparisonPrediction: Computed<PriceListDetailStore, number[], StoreModel>;
  comparisonPredictionUpdated: Computed<
    PriceListDetailStore,
    number[],
    StoreModel
  >;
  setData: Action<PriceListDetailStore, PriceListDetailData>;
  setPricingPriceDetailData: Action<
    PriceListDetailStore,
    PricingPriceListDetailData
  >;
  setComparisonData: Action<PriceListDetailStore, PriceListDetailData[]>;
  fetchData: Thunk<
    PriceListDetailStore,
    number,
    unknown,
    StoreModel,
    Promise<void>
  >;
  fetchComparisonData: Thunk<
    PriceListDetailStore,
    number[],
    unknown,
    StoreModel,
    Promise<PriceListDetailData[] | undefined>
  >;
  fetchComparisonDataUpdated: Thunk<
    PriceListDetailStore,
    number[],
    unknown,
    StoreModel,
    Promise<PricingPriceListDetailData[] | undefined>
  >;
  priceFilter?: PriceDetailFilter;
  setPriceDetailFilter: Action<PriceListDetailStore, PriceDetailFilter>;

  comparisonDataUpdated?: PricingPriceListDetailData[];
  setComparisonDataUpdated: Action<
    PriceListDetailStore,
    PricingPriceListDetailData[]
  >;
}

export const priceListDetailStore: PriceListDetailStore = {
  showPrediction: computed(
    [
      (state) => state.data,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (data, predictionAvalability) => {
      if (data && predictionAvalability) {
        return predictionAvalability.includes(data.description.availability);
      }
      return false;
    },
  ),
  showPredictionUpdated: computed(
    [
      (state) => state.pricePricingDetailData,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (data, predictionAvalability) => {
      if (data && predictionAvalability) {
        return predictionAvalability.includes(data.common_data.availability);
      }
      return false;
    },
  ),
  comparisonPrediction: computed(
    [
      (state) => state.comparisonData,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (comparisonData, predictionAvalability) => {
      if (comparisonData && predictionAvalability) {
        return comparisonData
          .filter((cd) =>
            predictionAvalability.includes(cd.description.availability),
          )
          .map((cd) => cd.id);
      }
      return [];
    },
  ),
  comparisonPredictionUpdated: computed(
    [
      (state) => state.comparisonDataUpdated,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (comparisonData, predictionAvalability) => {
      if (comparisonData && predictionAvalability) {
        return comparisonData
          .filter((cd) =>
            predictionAvalability.includes(cd.common_data.availability),
          )
          .map((cd) => cd.common_data.id);
      }
      return [];
    },
  ),
  setData: action((state, newData: PriceListDetailData) => {
    state.data = newData;
  }),
  setPricingPriceDetailData: action((state, data) => {
    state.pricePricingDetailData = data;
  }),
  setComparisonData: action((state, data) => {
    state.comparisonData = data;
  }),
  setComparisonDataUpdated: action((state, data) => {
    state.comparisonDataUpdated = data;
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setPhaseId, setProjectId, setLoading },
    } = helpers.getStoreActions();
    const {
      filters: { projectId },
      user: { profile },
    } = helpers.getStoreState();

    const lastValidDate = getValidPriceListDate(null, 120);
    const { price_per_sm_calculation } = profile || {};
    try {
      setGlobalLoading(true);
      setLoading(true);
      if (!REVAMP_ALLOWED_DEVELOPERS.includes(profile?.developer_id ?? 0)) {
        const { data } = await getPriceListDetailData(
          payload,
          lastValidDate,
          price_per_sm_calculation as PricePerSmCalcType,
        );
        if (data.phase_id) {
          setPhaseId(data.phase_id);
        }
        if (data.project_id) {
          setProjectId(data.project_id);
        }
        actions.setData(data);
      } else if (projectId) {
        const pricingData = await getPricingPriceListDetailData(
          projectId,
          payload,
        );
        actions.setPricingPriceDetailData(pricingData.data);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
  }),
  // eslint-disable-next-line consistent-return
  fetchComparisonData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setLoading },
    } = helpers.getStoreActions();
    const {
      user: { profile },
    } = helpers.getStoreState();

    const lastValidDate = getValidPriceListDate(null, 120);
    const { price_per_sm_calculation } = profile || {};
    try {
      setGlobalLoading(true);
      setLoading(true);
      const comparisonData = await Promise.all(
        payload.map(async (id) => {
          const { data } = await getPriceListDetailData(
            id,
            lastValidDate,
            price_per_sm_calculation as PricePerSmCalcType,
          );
          return data;
        }),
      );
      actions.setComparisonData(comparisonData);
      return comparisonData;
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
    return [];
  }),
  // eslint-disable-next-line consistent-return
  fetchComparisonDataUpdated: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setLoading },
    } = helpers.getStoreActions();
    const {
      user: { profile },
      filters: { projectId },
    } = helpers.getStoreState();

    try {
      setGlobalLoading(true);
      setLoading(true);
      if (projectId) {
        const comparisonData = await Promise.all(
          payload.map(async (id) => {
            const { data } = await getPricingPriceListDetailData(projectId, id);
            return data;
          }),
        );
        actions.setComparisonDataUpdated(comparisonData);
        return comparisonData;
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
    return [];
  }),
  setPriceDetailFilter: action((state, data) => {
    state.priceFilter = data;
  }),
};
