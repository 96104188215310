import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { ChartCard } from '../ChartCard';
import { getColumnOptions } from '../../utils/defaultChartConfig';
import { GoogleAnalytics } from '../../api/mockData/priceListDetailData';
import {
  AVAILABLE as AVERAGE_COLOR,
  SOLD as UNIT_COLOR,
} from '../../styles/constants';
import { useStoreState } from '../../hooks';

enum GoogleAnalyticItemType {
  UNIT = 'unit',
  AVERAGE = 'average',
}

type Props = {
  data: GoogleAnalytics | undefined;
  internalId: string | undefined;
};

const adaptAnalyticsData = (
  data: GoogleAnalytics | undefined,
): GoogleAnalytics | undefined => {
  if (!data) return undefined;
  const mappedItems = data.items.map((item) => ({
    ...item,
    average:
      typeof item.average === 'string'
        ? Number(item.average.split(' ')[0])
        : item.average,
    unit:
      typeof item.unit === 'string'
        ? Number(item.unit.split(' ')[0])
        : item.unit,
  }));
  return { ...data, items: mappedItems };
};

export const GoogleAnalyticsChart: FC<Props> = ({ data, internalId }) => {
  const adaptedData = adaptAnalyticsData(data);
  const showAnalytics = useStoreState((state) => state.priceList.showAnalytics);
  const { t } = useTranslation();

  const uniqueSessionsData = [
    {
      decider: t('price_list.hints.google.unique_sessions'),
      part: GoogleAnalyticItemType.AVERAGE,
      value: Number(Number(adaptedData?.items[0].average).toFixed(1)),
    },
    {
      decider: t('price_list.hints.google.unique_sessions'),
      part: GoogleAnalyticItemType.UNIT,
      value: Number(Number(adaptedData?.items[0].unit).toFixed(1)),
    },
  ];

  const timeSpentData = [
    {
      decider: t('price_list.hints.google.average_duration'),
      part: GoogleAnalyticItemType.AVERAGE,
      value: adaptedData?.items[1].average,
    },
    {
      decider: t('price_list.hints.google.average_duration'),
      part: GoogleAnalyticItemType.UNIT,
      value: adaptedData?.items[1].unit,
    },
  ];

  const uniqueSessionsConfig: ColumnConfig = getColumnOptions({
    data: uniqueSessionsData,
    isGroup: true,
    xField: 'decider',
    yField: 'value',
    seriesField: 'part',
    color: [UNIT_COLOR, AVERAGE_COLOR],
    legend: {
      itemName: {
        formatter: (value) =>
          value === GoogleAnalyticItemType.UNIT
            ? internalId
            : t(`price_list.hints.google.${value}`),
      },
    },
    yAxis: {
      label: {
        formatter: (value) => value,
      },
    },
    xAxis: {
      label: {
        formatter: (value) => value,
      },
    },
    tooltip: {
      formatter: ({ value, part }) => ({
        name:
          part === GoogleAnalyticItemType.UNIT
            ? internalId ?? ''
            : t('price_list.hints.google.average'),
        value: value ?? '',
      }),
      title: (title) => title,
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
                <div class="bmt-color" style="background-color: ${item?.color}"></div>
                <div class="bmt-label">${item?.name}:</div>
                <div class="bmt-value">${item?.value}</div>
              </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });

  const timeSpentConfig: ColumnConfig = getColumnOptions({
    data: timeSpentData,
    isGroup: true,
    xField: 'decider',
    yField: 'value',
    seriesField: 'part',
    color: [UNIT_COLOR, AVERAGE_COLOR],
    legend: {
      itemName: {
        formatter: (value) =>
          value === GoogleAnalyticItemType.UNIT
            ? internalId
            : t(`price_list.hints.google.${value}`),
      },
    },
    yAxis: {
      label: {
        formatter: (value) => `${value} s`,
      },
    },
    xAxis: {
      label: {
        formatter: (value) => value,
      },
    },
    tooltip: {
      formatter: ({ value, part }) => ({
        name:
          part === GoogleAnalyticItemType.UNIT
            ? internalId ?? ''
            : t('price_list.hints.google.average'),
        value: `${value} s`,
      }),
      title: (title) => title,
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
                <div class="bmt-color" style="background-color: ${item?.color}"></div>
                <div class="bmt-label">${item?.name}:</div>
                <div class="bmt-value">${item?.value}</div>
              </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });

  return (
    <ChartCard
      title='Google Analytics'
      showZoom={showAnalytics}
      zoomInHeader
      chart={
        showAnalytics ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            {uniqueSessionsData.length > 0 ? (
              <div style={{ flex: '1 1 45%', maxWidth: '45%' }}>
                <Column className='chart-container' {...uniqueSessionsConfig} />
              </div>
            ) : (
              <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
            {timeSpentData.length > 0 ? (
              <div style={{ flex: '1 1 45%', maxWidth: '45%' }}>
                <Column className='chart-container' {...timeSpentConfig} />
              </div>
            ) : (
              <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        ) : (
          <div className='flex justify-center items-center min-h-[400px]'>{t('price_list.hints.google.analytics_not_connected')}</div>
        )
      }
    />
  );
};
