import { FC } from 'react';
import { ExportToCsv, Options } from 'export-to-csv';
import { message } from 'antd';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { ExportDropdown } from '../../components/ExportDropdown/ExportDropdown';
import { useFlatLayout, useRegionFormatting, useStoreState } from '../../hooks';
import { logError } from '../../utils/utils';
import { round } from '../../utils/formatters';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-projects`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

export const csvExporter = new ExportToCsv(options);

export const ProjectsExport: FC = () => {
  const { t } = useTranslation();
  const activeTags = useStoreState((state) => state.market.activeTags);
  const allProjects = useStoreState((state) => state.market.simplifiedProjects.filter(({ project_phase }) => activeTags.includes(project_phase)));
  const { formatCurrency, isUsRegion, sortLayouts, getVATText } =
    useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();

  const handleButtonClick = async (format: string): Promise<void> => {
      try {
        message.loading(t('market.export.preparing_data'), 0);
          const exportData = allProjects.map(
              ({
                project_name,
                developer,
                district,
                municipality,
                available_units,
                sold_units,
                total_number_of_units,
                date_construction_completion,
                layouts,
                avg_price,
                sold_avg_price,
                avg_price_per_sm,
                sold_avg_price_per_sm,
                unit_type,
              }) => ({
                project_name,
                developer,
                district,
                municipality,
                available_units: available_units || 0,
                sold_units: sold_units || 0,
                total_number_of_units: total_number_of_units || 0,
                construction_completion: date_construction_completion ?? '-',
                layouts: layouts.map((l) => getLayoutAbbr(l)).join(', '),
                price: round(avg_price || 0, 2),
                sold_price: round(sold_avg_price || 0, 2),
                price_per_sm: round(avg_price_per_sm || 0, 2),
                sold_price_per_sm: round(sold_avg_price_per_sm || 0, 2),
                type: t(`project.overview.type.${unit_type}`),
              }));
          if (exportData.length === 0) {
            message.destroy();
            message.info(t('market.export.no_data'), 2);
            return;
          }
          if (format === 'csv') {
            csvExporter.options.filename = `${options.filename}${getVATText()}`;
            csvExporter.generateCsv(exportData);
          } else {
            const worksheet = XLSX.utils.json_to_sheet(exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, `${options.filename}${getVATText()}.xlsx`);
          }
          message.destroy();
          message.success(t('market.export.data_prepared'), 2);
      } catch (error) {
        logError(error);
        message.error(t('error.generic'), 2);
      }
  };

  return (
    <ExportDropdown type='projects' onDownload={handleButtonClick} />
  );
};
