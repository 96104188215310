import React, { useCallback, useMemo } from 'react';
import { useFlatLayout, useStoreActions, useStoreState } from '../../../hooks';
import { FlatDetail } from './FlatDetail';
import { Flat } from './Flat';
import { ViewType } from './types';
import { PriceListTableData } from '../../../api/mockData/priceListData';

export const FlatContainerUpdated = React.memo(
  ({ viewType, flat }: { viewType: ViewType; flat: PriceListTableData }) => {
    const { getLayoutAbbr } = useFlatLayout();

    const layoutAbbreviation = useMemo(
      () => getLayoutAbbr(flat.layout),
      [flat.layout, getLayoutAbbr],
    );

    const selectedID = useStoreState((state) => state.priceList.selectedId);
    const setSelectedId = useStoreActions(
      (actions) => actions.priceList.setSelectedId,
    );
    const setSelectedPricingId = useStoreActions(
      (actions) => actions.priceList.setSelectedPricingId,
    );
    const showPricePerSM = useStoreState(
      (state) => state.priceList.showPricePerSM,
    );

    const layoutFilter = useStoreState((state) => state.priceList.layoutFilter);
    const isDisabled =
      layoutFilter.length > 0 &&
      !layoutFilter.includes(getLayoutAbbr(flat.layout));

    const priceChange = !flat.new_price
      ? 0
      : flat.new_price / flat.current_price - 1;

    const handleClick = useCallback(() => {
      setSelectedId(flat.id);
      setSelectedPricingId(flat.id);
    }, [flat.id, setSelectedId]);

    if (viewType === 'graphic') {
      return (
        <Flat
          layoutAbbreviation={layoutAbbreviation}
          availability={flat.availability}
          isSelected={selectedID === flat.id}
          isDisabled={isDisabled}
          id={flat.id}
          onClick={handleClick}
        />
      );
    }

    return (
      <FlatDetail
        viewType={viewType}
        newPrice={showPricePerSM ? flat.new_price_per_sm : flat.new_price}
        currentPrice={
          showPricePerSM ? flat.current_price_per_sm : flat.current_price
        }
        priceChange={priceChange}
        layoutAbbreviation={layoutAbbreviation}
        floorArea={flat.floor_area}
        availability={flat.availability}
        isSelected={selectedID === flat.id}
        isDisabled={isDisabled}
        internalId={flat.internal_id}
        id={flat.id}
        uniqueSessions={flat.unique_sessions ?? 0}
        averageDurationSeconds={flat.average_duration_seconds ?? 0}
        googleAnalyticsNormalized={flat.google_analytics_normalized ?? 0}
        onClick={handleClick}
      />
    );
  },
);
