/* eslint-disable react/no-danger */
import { TFunction } from 'i18next';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { Col } from 'antd';
import { FormatDailyNewsObjectOutput } from '../../utils/utils';
import { Icon } from './Icon';

const LIMIT = 100;

type Item = {
  variables: Record<string, string>[];
};

export type DailyNewsTransProps = {
  i18key: string;
  params: Record<string, string>[];
  onClick?: () => void;
  icon: {
    source: string;
    color: string;
  };
  t: TFunction;
  date: string;
  language: string;
  hoverable: boolean;
};

export const DailyNewsTrans: FC<DailyNewsTransProps> = ({
  i18key,
  language,
  params,
  onClick,
  hoverable,
  date,
  icon,
  t,
}) => {
  const combinedObject = params.reduce((result, currentObject) => {
    Object.keys(currentObject).forEach((key) => {
        result[key] = currentObject[key];
    });
    return result;
  }, {} as Record<string, string>);
  const trans = t(`daily_news.kpi.${i18key}`, { ...combinedObject });
  const len = trans.length;

  return (
    <div className='flex space-x-2 items-start justify-start'>
      <Col className='daily_news_list_item_date'>
        {new Date(date).toLocaleDateString(language, {
          month: 'short',
          day: '2-digit',
          year: '2-digit',
        })}
      </Col>
      <Col
        flex='auto'
        className='daily_news_list_item_container_value'
      >
        <div className={`daily_news_list_item_value border ${hoverable ? 'hoverable' : ''}`}>
          <div className='daily_news_list_item_trans'>
              <div className='min-w-[30px]'>
                <Icon color={icon.color} source={icon.source} />
              </div>
                <Trans
                  t={t}
                  onClick={onClick}
                  i18nKey={`daily_news.kpi.${i18key}`}
                  values={{ ...combinedObject }}
                  shouldUnescape
                >
                  <strong>variable</strong>
                  <strong>variable</strong>
                  <strong>variable</strong>
                  <strong>variable</strong>
                </Trans>
          </div>
        </div>
      </Col>
    </div>
  );
};

export type FormatDailyNewsHelperProps = {
  date: string;
  language: string;
  icon: {
    source: string;
    color: string;
  };
  items: {
    i18key: string;
    project_id: number;
    onClick?: (item: Item) => void;
    variables: Record<string, string>[];
  }[];
}

export const formatDailyNewsObjectHelper = (
  arr: FormatDailyNewsHelperProps[],
  t: TFunction,
): FormatDailyNewsObjectOutput[] => {
  const result: FormatDailyNewsObjectOutput[] = [];
  arr.forEach((obj) => {
    obj.items.forEach((item) => {
      result.push({
        date: obj.date,
        project_id: item.project_id,
        value: () => (
          <DailyNewsTrans
            onClick={() => typeof item.onClick === 'function' ? item.onClick(item) : undefined}
            t={t}
            hoverable={typeof item.onClick === 'function'}
            date={obj.date}
            icon={obj.icon}
            i18key={item.i18key}
            language={obj.language}
            params={item.variables}
          />
          ),
      });
    });
  });

  return result;
};
