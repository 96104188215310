import { FC, useMemo, useState } from 'react';
import { PolygonF, PolygonProps } from '@react-google-maps/api';
import { useStoreState } from '../../hooks';
import { Mode } from '../../store/types';
import { LatLng } from '../../utils/types';

type CustomPolygonProps = PolygonProps & {
  name: string;
  paths: LatLng[];
  onUpdate?: (polygon: google.maps.Polygon, name: string) => void;
  onDelete?: (name: string) => void;
  isHoveredGeometry?: boolean;
};

export const CustomPolygon: FC<CustomPolygonProps> = ({
  name,
  paths,
  onUpdate,
  onDelete,
  isHoveredGeometry,
}) => {
  const { mode, mapControls } = useStoreState((state) => state.market);
  const [polygonRef, setPolygonRef] = useState<google.maps.Polygon | null>(
    null,
  );
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isPolygonBeingEdited, setIsPolygonBeingEdited] =
    useState<boolean>(false);

  const deleteMode = useMemo(() => mapControls === 'delete', [mapControls]);
  const edit = useMemo(() => mapControls === 'edit', [mapControls]);

  // Polygon options
  const polygonOptions: google.maps.PolygonOptions = {
    editable: mode !== Mode.READ && (isHovered || isEditing) && edit,
    fillColor: deleteMode && isHovered ? '#FF9A9A99' : '#5A72B1',
    fillOpacity: isHoveredGeometry ? 0.8 : 0.5,
    strokeColor: deleteMode && isHovered ? '#EA5A5A' : '#5A72B1',
  };

  const handlePolygonClick = (): void => {
    if (deleteMode && polygonRef) {
      if (onDelete) {
        onDelete(name);
      }
    }
  };

  const handleOnMouseDown = (e: google.maps.MapMouseEvent): void => {
    const originalPath = [e.latLng?.lat(), e.latLng?.lng()];
    const parsedPaths = paths?.map((path) => [path.lat, path.lng]);
    if (originalPath[0] && originalPath[1]) {
      const coordinateChanged = (parsedPaths as number[][]).some(
        (path) => path[0] === originalPath[0] && path[1] === originalPath[1],
      );
      if (coordinateChanged) {
        setIsPolygonBeingEdited(true);
        setIsEditing(true);
      }
    }
  };

  const handleOnMouseUp = (): void => {
    const polygonPaths = polygonRef?.getPath()?.getArray();
    const coordinateAdded = (polygonPaths || []).length > (paths || []).length;

    if (onUpdate && polygonRef && (isPolygonBeingEdited || coordinateAdded)) {
      onUpdate(polygonRef, name);
      setIsPolygonBeingEdited(false);
    }

    setIsEditing(false);
  };

  return (
    <PolygonF
      options={polygonOptions}
      paths={paths}
      onLoad={(initialPolygon) => setPolygonRef(initialPolygon)}
      onMouseDown={handleOnMouseDown}
      onMouseUp={handleOnMouseUp}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onClick={handlePolygonClick}
    />
  );
};
