import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import {
  Card,
  CardContent,
  CardHeader,
  CardOptions,
  CardTitle,
} from '../card/Card';
import { MaximizeOption } from '../card/cardOptions/MaximizeOption';
import { PriceSelector } from './PriceSelector';
import { LayoutToggle } from './LayoutToggle';
import { useStoreState } from '../../../hooks';
import { AddCompetitors } from './AddCompetitors';
import { Availability } from '../../../store/types';
import { PositioningScatterChart } from './PositioningScatterChart';
import { MoreOptions } from './MoreOptions';

const UNITS_TRESHOLD = 150;
const SELECTOR_VALUES = ['price', 'price_per_sm'];

export const Positioning: FC = () => {
  const { t } = useTranslation();

  const positioning = useStoreState(
    (state) => state.dashboard.dashboardData?.positioning,
  );
  const projectId = useStoreState((state) => state.filters.projectId ?? 0);
  const pricedUnits = positioning?.priced_units ?? [];
  const selectedCompetitors = positioning?.selected_competitors ?? [];
  const competitorsData = selectedCompetitors.flatMap((competitor) =>
    competitor.units.map((unit) => ({
      ...unit,
      projectId: competitor.id,
      projectName: competitor.name,
    })),
  );
  const data = pricedUnits.map((unit) => ({
    ...unit,
    projectId,
    projectName: t('dashboard.positoning.tooltip.my_project', 'My Project'),
  }));
  const combinedData = [...data, ...competitorsData];

  const layouts = positioning?.layout_grouping ?? [];

  const [showAvailabile, setShowAvailable] = useState(true);
  const [showSoldAndReserved, setShowSoldAndReserved] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState(SELECTOR_VALUES[0]);
  const [value, setValue] = useState(
    combinedData.length > UNITS_TRESHOLD ? layouts[1] : layouts[0],
  );

  const filteredData = combinedData.filter((unit) => {
    const layoutCondition =
      value === 'all' || (value && value.includes(unit.layout));

    const availabilityCondition =
      (showAvailabile || unit.availability !== Availability.AVAILABLE) &&
      (showSoldAndReserved ||
        (unit.availability !== Availability.SOLD &&
          unit.availability !== Availability.RESERVED));

    return layoutCondition && availabilityCondition;
  });

  useEffect(() => {
    if (layouts.length > 0) {
      setValue(combinedData.length > UNITS_TRESHOLD ? layouts[1] : layouts[0]);
    }
  }, [layouts]);

  return (
    <Card open={modalOpen} onOpenChange={() => setModalOpen((open) => !open)}>
      <CardHeader>
        <CardTitle>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2'>
            <ChartBarSquareIcon className='stroke-2 w-10 h-10' />
          </div>
          <span>{t('dashboard.positioning.title')}</span>
        </CardTitle>
        <CardOptions>
          {!modalOpen && (
            <div className='flex flex-wrap-reverse items-center justify-end gap-4 order-1'>
              <div className='flex items-center'>
                <PriceSelector
                  values={SELECTOR_VALUES}
                  selected={selected}
                  setSelected={setSelected}
                />
                <AddCompetitors />
              </div>
              <div className='flex items-center gap-4'>
                <LayoutToggle
                  layouts={layouts}
                  value={value}
                  setValue={setValue}
                />
                <MoreOptions
                  setShowAvailable={setShowAvailable}
                  showAvailabile={showAvailabile}
                  setShowSoldAndReserved={setShowSoldAndReserved}
                  showSoldAndReserved={showSoldAndReserved}
                />
                <MaximizeOption open={modalOpen} />
              </div>
            </div>
          )}
        </CardOptions>
      </CardHeader>
      <CardContent>
        {!!filteredData.length && selected && (
          <PositioningScatterChart data={filteredData} priceFormat={selected} />
        )}
      </CardContent>
    </Card>
  );
};
