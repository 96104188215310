/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import { FC, ReactNode } from 'react';
import { BuildingOffice2Icon, ClipboardDocumentListIcon, KeyIcon, LockClosedIcon, MapIcon, Squares2X2Icon, SwatchIcon, WalletIcon } from '@heroicons/react/24/outline';
import { PieChart } from 'react-minimal-pie-chart';
import { Car } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { ReactComponent as ConstructionIcon } from '../../../../../../assets/project-card/construction.svg';
import { AVAILABLE, GREY_2, SOLD } from '../../../../../../styles/constants';
import { RatingBadge } from './RatingBadge';
import { DescriptionRow } from './DescriptionRow';
import { formatPercent, round } from '../../../../../../utils/formatters';
import { Payments } from '../Description/Payments';
import { Parking } from '../Description/Parking';
import { useRegionFormatting } from '../../../../../../hooks';
import { MarketProjectExtended } from '../../../../../../store/types';
import dayjs from '../../../../../../utils/dayjs';

type ProjectCardDescriptionProps = {
  data: MarketProjectExtended;
}

const translationBase = 'project_card.description';

export const ProjectCardDescription: FC<ProjectCardDescriptionProps> = ({
  data: {
    cellar_price,
    cellar_price_per_sm,
    cellar_included,
    date_construction_completion,
    date_construction_start,
    date_sale_completion,
    date_sale_start,
    materials,
    max_price_per_sm,
    min_price_per_sm,
    parking_indoor_price,
    parking_outdoor_price,
    payment_construction,
    payment_contract,
    payment_occupancy,
    public_transport_to_center,
    ride_to_center,
    total_number_of_units,
    parking_included,
    renovation,
    building_use,
    cooperative_housing,
    unit_type,
    city,
    ratings,
    permit_regular,
  },
}) => {
  const { t } = useTranslation();
  const { formatCurrency, areaUnit } = useRegionFormatting();
  const calculateDateProgress = (startDate: string | null, completionDate: string | null): { progress: number, startDate: string | null, completionDate: string | null, completed: boolean } => {
    const start = dayjs(startDate);
    const completion = dayjs(completionDate);
    const now = dayjs();

    const totalDuration = completion.diff(start, 'day');
    const daysPassed = now.diff(start, 'day');

    const percentage = (daysPassed / totalDuration) * 100;

    if (percentage >= 100) {
      const startCompleted = dayjs(startDate).add(totalDuration, 'day');
      const daysPassedCompleted = now.diff(startCompleted, 'day');

      const percentageCompleted = (daysPassedCompleted / totalDuration) * 100;

      return {
        progress: startDate && completionDate ? round(Math.min(Math.max(percentageCompleted, 0), 100), 2) : 0,
        startDate: startDate ? start.format('M/YY') : null,
        completionDate: completionDate ? completion.format('M/YY') : null,
        completed: true,
      };
    }

    return {
      progress: startDate && completionDate ? round(Math.min(Math.max(percentage, 0), 100), 2) : 0,
      startDate: startDate ? start.format('M/YY') : null,
      completionDate: completionDate ? completion.format('M/YY') : null,
      completed: false,
    };
  };
  const getCellarValue = (): string => {
    if (cellar_price) {
      return `${formatCurrency(cellar_price)}`;
    }
    if (cellar_price_per_sm) {
      return `${formatCurrency(cellar_price_per_sm)} / ${areaUnit}`;
    }
    if (cellar_included) {
      return t('enums.state.included');
    }
    return '-';
  };

  const { progress, startDate, completionDate, completed } = calculateDateProgress(date_sale_start, date_construction_completion);
  // eslint-disable-next-line no-nested-ternary
  const dateText = startDate && completionDate
    ? `${startDate} - ${completionDate}`
      : completionDate !== null
        ? `until ${completionDate}`
        : '-';

  const ratingsIcons: { [key in string]: ReactNode } = {
    location: <MapIcon className='w-8 h-8 stroke-2' />,
    materials: <SwatchIcon className='w-8 h-8 stroke-2' />,
  };

  const parseRideLength = (minutes: number | null): string => {
    if (minutes === null || minutes === 0) {
      return '-';
    }
    const durationObj = dayjs.duration(minutes, 'minutes');
    const hours = durationObj.hours();
    const mins = durationObj.minutes();

    const hourString = hours > 1 ? `${hours} hours` : hours === 1 ? `${hours} hour` : '';
    const minuteString = mins > 1 ? `${mins} mins` : mins === 1 ? `${mins} min` : '';

    if (hourString && minuteString) {
      return `${hourString} ${minuteString}`;
    }
    return hourString || minuteString;
  };

  const parseMaterials = (material: string, value: string | boolean): string => {
    switch (material) {
      case 'floors':
        return `${t(`project.materials_popup.floors.values.${value}`)}`;
      case 'heating':
        const heatingsArray = materials.heating?.split(', ');
        const heatingTranslated = heatingsArray?.map((heating) => t(`project.materials_popup.heating.values.${heating}`)).join(', ');
        return heatingTranslated ?? '-';
      case 'overall_quality':
        return `${t(`enums.materials.${value}`)}`;
      case 'partition_walls':
        return `${t(`project.materials_popup.partition_walls.values.${value}`)}`;
      case 'windows':
        return `${t(`project.materials_popup.windows.values.${value}`)}`;
      default:
        return value === 'true' || value === true ? t('common.yes') : t('common.no');
    }
  };

  const parseDate = (date: string): string => {
    const format = date.includes('-') ? 'M/YYYY' : 'YYYY';
    return dayjs(date).format(format);
  };

  const popoverContent: { [key in string]: ReactNode } = {
    location:
      <div className='flex flex-col space-y-3'>
        <div className='flex space-x-2'>
          <span>{t(`${translationBase}.distance_to_city`, 'Distance to')}</span>
          <span className='font-semibold capitalize'>{city}</span>
        </div>
        <div className='flex flex-col space-y-1'>
          <div className='flex space-x-3'>
            <span>{t('project.overview.ride_to_center', 'Ride to center')}:</span>
            <span className='font-semibold'>{parseRideLength(ride_to_center)}</span>
          </div>
          <div className='flex space-x-3'>
            <span>{t('project.overview.public_transport', 'Public transport')}:</span>
            <span className='font-semibold'>{parseRideLength(public_transport_to_center)}</span>
          </div>
        </div>
      </div>,
      materials:
        <div className='flex flex-col space-y-3 w-[30rem]'>
          {Object.entries(materials).map(([key, value]) => (
            key !== 'overall_quality' && value !== null &&
            <div key={key} className='flex justify-between'>
              <span>
                {t(`project.materials_popup.${key}`)}
              </span>
              <span className='font-semibold capitalize'>
                {parseMaterials(key, value)}
              </span>
            </div>
          ))}
        </div>,
      timeline:
        <div className='flex flex-col space-y-3 w-[30rem]'>
          {date_sale_start ? (
            <div className='flex justify-between'>
              <span>{t(`${translationBase}.sale_start`, 'Sale start')}</span>
              <span className='font-semibold'>{parseDate(date_sale_start)}</span>
            </div>
          ) : null}
          {date_sale_completion ? (
            <div className='flex justify-between'>
              <span>{t(`${translationBase}.sale_completion`, 'Sale completion')}</span>
              <span className='font-semibold'>{parseDate(date_sale_completion)}</span>
            </div>
          ) : null}
          {date_construction_start ? (
            <div className='flex justify-between'>
              <span>{t(`${translationBase}.construction_start`, 'Construction completion')}</span>
              <span className='font-semibold'>{parseDate(date_construction_start)}</span>
            </div>
          ) : null}
          {date_construction_completion ? (
            <div className='flex justify-between'>
              <span>{t(`${translationBase}.construction_completion`, 'Construction completion')}</span>
              <span className='font-semibold'>{parseDate(date_construction_completion)}</span>
            </div>
          ) : null}
        </div>,
      parking:
        <div className='flex flex-col space-y-3 w-[20rem]'>
          {parking_indoor_price ? (
            <div className='flex justify-between'>
              <span>{t(`${translationBase}.parking_indoor`, 'Indoor parking')}</span>
              <span className='font-semibold'>{formatCurrency(parking_indoor_price)}</span>
            </div>
          ) : null}
          {parking_outdoor_price ? (
            <div className='flex justify-between'>
              <span>{t(`${translationBase}.parking_outdoor`, 'Outdoor parking')}</span>
              <span className='font-semibold'>{formatCurrency(parking_outdoor_price)}</span>
            </div>
          ) : null}
        </div>,
      payments:
      <div className='flex flex-col space-y-3 w-[25rem]'>
        {payment_contract ? (
          <div className='flex justify-between'>
            <span>{t(`${translationBase}.payment.payment_contract`, 'Payments - contract')}</span>
            <span className='font-semibold'>{formatPercent(payment_contract)}</span>
          </div>
        ) : null}
        {payment_construction ? (
          <div className='flex justify-between'>
            <span>{t(`${translationBase}.payment.payment_construction`, 'Payments - construction')}</span>
            <span className='font-semibold'>{formatPercent(payment_construction)}</span>
          </div>
        ) : null}
        {payment_occupancy ? (
          <div className='flex justify-between'>
            {t(`${translationBase}.payment.payment_occupancy`, 'Payments - completion')}
            <span className='font-semibold'>{formatPercent(payment_occupancy)}</span>
          </div>
        ) : null}
      </div>,
  };

  const ratingsData = ratings.length ? ratings.map((r) => ({
    title: t(`project.overview.${r.type}`, r.type),
    icon: ratingsIcons[r.type],
    content:
    <Popover content={
        popoverContent[r.type]
      }
    >
      <div className='flex space-x-2 justify-start items-center font-bold min-w-[135px] min-h-[2rem]'>
        <PieChart
          lineWidth={70}
          startAngle={-90}
          animate
          className='w-8 h-8'
          data={[
            { value: round(r.rating_value, 1), color: AVAILABLE },
            { value: round(10 - r.rating_value, 1), color: SOLD },
          ]}
        />
        <span>{round(r.rating_value, 1)}</span>
        <RatingBadge rating={t(`${translationBase}.${r.rating_badge}`, r.rating_badge)} />
      </div>
    </Popover>,
  })) : [];

  const rowsData = [
    ...ratingsData,
    {
      title: t(`${translationBase}.timeline`, 'Timeline'),
      icon: <MapIcon className='w-8 h-8 stroke-2' />,
      content:
        startDate || completionDate
          ? <Popover content={popoverContent.timeline}>
              <div className='flex space-x-2 justify-start items-center font-bold'>
                {startDate && completionDate && (
                  <PieChart
                    lineWidth={70}
                    startAngle={-90}
                    animate
                    className='w-8 h-8'
                    data={[
                      { value: progress, color: completed ? GREY_2 : AVAILABLE },
                      { value: round(100 - progress, 2), color: SOLD },
                    ]}
                  />
                )}
                <span>{dateText}</span>
              </div>
            </Popover>
          : <span className='font-bold'>{dateText}</span>,
    },
    {
      title: t('project.overview.type', 'Type'),
      icon: <Squares2X2Icon className='w-8 h-8 stroke-2' />,
      content: <span className='font-bold'>{t(`enums.${unit_type}`)}</span>,
    },
    {
      title: t('project.overview.construction', 'Construstion'),
      icon: <ConstructionIcon className='w-8 h-8 stroke-2' />,
      content: <span className='font-bold'>{t(`project.overview.${renovation ? 'renovation' : 'newly_built'}`)}</span>,
    },
    {
      title: t('project.ownership', 'Ownership'),
      icon: <KeyIcon className='w-8 h-8 stroke-2' />,
      content: <span className='font-bold'>{t(`project.ownership.${!!cooperative_housing ? 'cooperative' : 'private'}`)}</span>,
    },
    {
      title: t('project.overview.units_count', 'Units Count'),
      icon: <BuildingOffice2Icon className='w-8 h-8 stroke-2' />,
      content: <span className='font-bold'>{total_number_of_units || '-'}</span>,
    },
    {
      title: t('project.overview.parking', 'Parking'),
      icon: <Car className='w-8 h-8 stroke-2' />,
      content:
        parking_indoor_price || parking_outdoor_price
          ? <Popover content={popoverContent.parking}>
              <div>
                <Parking
                  parking_indoor_price={parking_indoor_price}
                  parking_outdoor_price={parking_outdoor_price}
                />
              </div>
            </Popover>
          : <span className='font-bold'>-</span>,
    },
    ...(cellar_price || cellar_included || cellar_price_per_sm
      ? [{
        title: t('project.overview.cellar', 'Cellar'),
        icon: <LockClosedIcon className='w-8 h-8 stroke-2' />,
        content: <span className='font-bold'>{getCellarValue()}</span>,
      }]
      : []),
    ...(payment_construction || payment_contract || payment_occupancy
      ? [{
        title: t('project.overview.payments', 'Payments'),
        icon: <WalletIcon className='w-8 h-8 stroke-2' />,
        content:
          <Popover content={popoverContent.payments}>
            <div>
              <Payments
                payment_construction={payment_construction}
                payment_contract={payment_contract}
                payment_occupancy={payment_occupancy}
              />
            </div>
          </Popover>,
      }]
      : []),
    {
      title: t('project.overview.building_use', 'Building use'),
      icon: <ClipboardDocumentListIcon className='w-8 h-8 stroke-2' />,
      content: <span className='font-bold'>{t(`project.overview.building_use.${building_use}`, building_use || '-')}</span>,
    },
  ];

  const mapIndex = (Math.floor(rowsData.length / 2 - 1)) + (rowsData.length % 2);
  const leftColumn = rowsData.filter((_, i) => i <= mapIndex);
  const rightColumn = rowsData.filter((_, i) => i > mapIndex);

  return (
    <div className='grid grid-cols-2 gap-3'>
      <div className='flex flex-col space-y-2'>
        {leftColumn.map((r) => (
          <DescriptionRow {...r} />
        ))}
      </div>
      <div className='flex flex-col space-y-2'>
        {rightColumn.map((r) => (
          <DescriptionRow {...r} />
        ))}
      </div>
    </div>
  );
};
