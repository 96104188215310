import { ProjectPhase } from '../../api/types';
import selected_completed from '../../assets/map-pins/selected_completed.svg';
import selected_development from '../../assets/map-pins/selected_development.svg';
import unselected_completed from '../../assets/map-pins/unselected_completed.svg';
import unselected_development from '../../assets/map-pins/unselected_development.svg';
import selected_archived from '../../assets/map-pins/archived_selected.svg';
import unselected_archived from '../../assets/map-pins/archived_unselected.svg';
import selected_planned from '../../assets/map-pins/planned_selected.svg';
import unselected_planned from '../../assets/map-pins/unselected_planned.svg';

export const getMapPin = (
  projectPhase: ProjectPhase,
  include: boolean,
): string => {
  const isSelected = include;

  switch (projectPhase) {
    case ProjectPhase.COMPLETED:
      return isSelected ? selected_completed : unselected_completed;

    case ProjectPhase.DEVELOPMENT:
      return isSelected ? selected_development : unselected_development;

    case ProjectPhase.PLANNED:
      return isSelected ? selected_planned : unselected_planned;

    case ProjectPhase.ARCHIVED:
      return isSelected ? selected_archived : unselected_archived;

    default:
      // If phase is NONE or any other undefined value, you can return a default image or an empty string
      return '';
  }
};
