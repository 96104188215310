import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { DocumentChartBarIcon } from '@heroicons/react/24/outline';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownPortal,
  DropdownTrigger,
} from '../dropdown/Dropdown';
import { LayoutKey } from '../../../api/mockData/dashboardData';

type LayoutSelectorProps = {
  selected: string;
  setSelected: (item: LayoutKey) => void;
  formatValues: LayoutKey[];
};

export const LayoutSelector: FC<LayoutSelectorProps> = ({
  selected,
  setSelected,
  formatValues,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Dropdown>
        <DropdownTrigger>
          <div className='flex items-center gap-4'>
            <DocumentChartBarIcon className='w-10 stroke-2' />
            {t(`enums.eu_layouts_abbreviation.${selected}`)}
          </div>
        </DropdownTrigger>
        <DropdownPortal>
          <DropdownContent>
            {formatValues.map((item) => (
              <DropdownItem
                key={item}
                active={item === selected}
                onSelect={() => setSelected(item)}
              >
                {t(`enums.eu_layouts_abbreviation.${item}`)}
              </DropdownItem>
            ))}
          </DropdownContent>
        </DropdownPortal>
      </Dropdown>
    </div>
  );
};
