import { FC, ReactNode, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PriceBreakdownRow } from './PriceBreakdownRow';
import { Orientation as OrientationData } from '../../../api/mockData/pricingPriceListDetaildata';

const orientationMapping: Record<string, string> = {
  N: 'north',
  S: 'south',
  E: 'east',
  W: 'west',
  NE: 'northeast',
  NW: 'northwest',
  SE: 'southeast',
  SW: 'southwest',
  SN: 'northsouth',
  WE: 'eastwest',
};

interface Props {
  data: OrientationData;
  isHouse: boolean;
}

export const OrientationUpdated: FC<Props> = ({
  data: { cardinal_direction, distance, sides, view },
  isHouse,
}) => {
  const { t } = useTranslation();
  const popoverContent: ReactNode = useMemo(() => {
    const { value } = view;
    if (!value) {
      return (
        <div>
          <p>{t('price_list.hints.default.desc')}</p>
        </div>
      );
    }
    const blocked = value.includes('blocked') ? (
      <Trans i18nKey='price_list.hints.view.blocked.desc'>
        <b>Blocked</b> - No clear view.
      </Trans>
    ) : null;
    const nature = value.includes('nature') ? (
      <Trans i18nKey='price_list.hints.view.nature.desc'>
        <b>Nature</b> - The view of a natural landscape.
      </Trans>
    ) : null;
    const buildings = value.includes('building') ? (
      <Trans i18nKey='price_list.hints.view.building.desc'>
        <b>Buildings</b> - The view of the surrounding buildings.
      </Trans>
    ) : null;
    const panoramic = value.includes('panoramic') ? (
      <Trans i18nKey='price_list.hints.view.panoramic.desc'>
        <b>Panoramic</b> - The panoramic view of the city.
      </Trans>
    ) : null;

    return (
      <div>
        {blocked}
        {nature}
        {buildings}
        {panoramic}
      </div>
    );
  }, [t, view]);

  const viewValueContent: ReactNode = useMemo(() => {
    const { value } = view;
    return value.map((val) => t(`enums.view.${val}`, val)).join(', ');
  }, [view, t]);

  const cardinalDirectionValueContent: ReactNode = useMemo(() => {
    const { value } = cardinal_direction;
    return value
      .map((val) => {
        const mappedKey = orientationMapping[val];
        return t(`enums.world_sides.${mappedKey}`, val);
      })
      .join(', ');
  }, [cardinal_direction, t]);

  return (
    <div>
      <PriceBreakdownRow
        className='row-top-spacing'
        houseAreaKey='orientation'
        housePartKey='cardinal_direction'
        valueContent={cardinalDirectionValueContent}
        price={cardinal_direction.price}
        updatePrice={cardinal_direction.update_price}
      />
      <PriceBreakdownRow
        housePartKey='view'
        valueContent={viewValueContent}
        price={view.price}
        popoverContent={popoverContent}
        updatePrice={view.update_price}
      />
      <PriceBreakdownRow
        housePartKey='distance'
        valueContent={distance.value}
        price={distance.price}
        popoverContent={
          <Trans i18nKey='price_list.hints.distance.desc'>
            3 if there is nature or no building in the next 100m. <br />
            2 if the next building is 15- 100m away. <br />1 if the next
            building is less than 15m away from the unit from the living room.
          </Trans>
        }
        updatePrice={distance.update_price}
      />
      {!isHouse ? (
        <PriceBreakdownRow
          housePartKey='num_sides'
          valueContent={sides.value}
          price={sides.price}
          updatePrice={sides.update_price}
        />
      ) : null}
    </div>
  );
};
