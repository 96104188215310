import { FC, useMemo } from 'react';
import { useStoreActions, useStoreState } from '../../hooks';
import styles from './PriceUpdateNotifications.module.css';
import { NotificationCard } from './NotificationCard';
import { REVAMP_ALLOWED_DEVELOPERS } from '../../constants';

export const PriceUpdateNotifications: FC = () => {
  // @ts-ignore TODO: Fix typing
  // const updateData = useStoreState((state) => state.priceList.updateData) as PriceListUpdateData;
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { setProjectId } = useStoreActions(
    (actions) => actions.filters,
  );
  const { phaseId, projectId, isLoading } = useStoreState(
    (state) => state.filters,
  );
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id ?? 0,
  );
  const { fetchUpdateData } = useStoreActions((actions) => actions.priceList);
  const projectUpdate = useMemo(
    () =>
      overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );

  const handleSeeMore = (selectProjectId: number): void => {
    if (!isLoading && projectId && phaseId) {
      if (projectUpdate && !REVAMP_ALLOWED_DEVELOPERS.includes(developerId)) {
        setProjectId(selectProjectId);
        fetchUpdateData({ price_list_update_id: projectUpdate.id });
        // if (projectUpdate.user_seen === false) {
        //   updatePriceListUserSeen({ price_list_update_id: projectUpdate.id });
        // }
      }
    }
    if (REVAMP_ALLOWED_DEVELOPERS.includes(developerId) && !isLoading) {
      setProjectId(selectProjectId);
    }
  };

  return (
    <div className={styles.notificationsContainer}>
      {overviewData?.updates.map((update) => (
        <NotificationCard
          key={update.name}
          projectName={update.name || ''}
          userSeen={update.user_seen}
          handleHasSeen={() => handleSeeMore(update.project_id)}
        />
      ))}
    </div>
  );
};
