import { FC, useState } from 'react';
import { Image } from 'antd';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components';
import styles from './ComparisonBreakdown.module.css';
import { useRegionFormatting, useStoreState } from '../../hooks';
import { getSafeSuffix } from '../../utils/utils';
import {
  Exterior,
  Floor,
  Interior,
  Layout,
  Orientation,
  PricingPriceListDetailData,
} from '../../api/mockData/pricingPriceListDetaildata';

const orientationMapping: Record<string, string> = {
  N: 'north',
  S: 'south',
  E: 'east',
  W: 'west',
  NE: 'northeast',
  NW: 'northwest',
  SE: 'southeast',
  SW: 'southwest',
  SN: 'northsouth',
  WE: 'eastwest',
};

type Props = {
  unit: PricingPriceListDetailData;
  showPrediction: boolean;
};

type PriceRowProps = {
  priceData: Interior | Exterior | Floor | Orientation | Layout;
};

export const ComparisonBreakdownUnitUpdated: FC<Props> = ({
  unit,
  showPrediction,
}) => {
  const { t } = useTranslation();
  const { common_data, price_breakdown } = unit;
  const { areaUnit, formatCurrency } = useRegionFormatting();
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const [imageIndex, setImageIndex] = useState(0);

  const handleChevron = (direction: 'prev' | 'next'): void => {
    const lastIndex = common_data.images.length - 1;
    if (direction === 'prev') {
      setImageIndex(imageIndex > 0 ? imageIndex - 1 : lastIndex);
    }
    if (direction === 'next') {
      setImageIndex(imageIndex < lastIndex ? imageIndex + 1 : 0);
    }
  };

  const getValueByKey = (
    value: string | string[] | number,
    key: string,
  ): string | number => {
    const parsedValue = Number.isNaN(Number(value)) ? value : Number(value);
    const numberValue = Number(parsedValue);

    if (key === 'layout') {
      return t(`enums.eu_layouts_abbreviation.layout_${value}`);
    }
    if (key === 'bedrooms_ratio' || key === 'living_area_ratio') {
      return numberValue.toFixed(2);
    }
    if (key === 'difference_floor_area') {
      return Number(value) < 0
        ? `${numberValue.toFixed(1)} ${areaUnit}`
        : `+${numberValue.toFixed(1)} ${areaUnit}`;
    }
    if (key === 'top_floor') {
      return parsedValue === 1 ? t('common.yes') : t('common.no');
    }
    if (key === 'bottom_floor') {
      return parsedValue === 1 ? t('common.yes') : t('common.no');
    }
    if (
      key === 'floor_area' ||
      key === 'exterior_area' ||
      key === 'balcony_area' ||
      key === 'terrace_area' ||
      key === 'garden_area'
    ) {
      return `${parsedValue} ${areaUnit}`;
    }
    if (
      key === 'next_to_elevator' ||
      key === 'kitchen_luminosity' ||
      key === 'permit_regular' ||
      key === 'has_exterior' ||
      key === 'next_to_elevator' ||
      key === 'sunny_kitchen' ||
      key === 'walk_through_room'
    ) {
      return parsedValue === 1 ? t('common.yes') : t('common.no');
    }
    if (key === 'ratio_bedrooms' || key === 'ratio_living') {
      return `${numberValue.toFixed(2)}%`;
    }
    if (key === 'cardinal_direction') {
      return (value as string[])
        .map((val) => {
          const mappedKey = orientationMapping[val];
          return t(`enums.world_sides.${mappedKey}`, val);
        })
        .join(', ');
    }
    if (key === 'view') {
      return (value as string[])
        .map((val) => t(`enums.view.${val}`, val))
        .join(', ');
    }
    if (key === 'category') {
      return t(`enums.category.${value}`);
    }
    if (key === 'masters_bedroom') {
      return t(`enums.masters_bedroom.${value}`);
    }
    if (key === 'house_type') {
      return t(`enums.house_type.${value}`);
    }

    return parsedValue as string | number;
  };

  const PriceRow: FC<PriceRowProps> = ({ priceData }) => {
    const entriesData = Object.entries(priceData);
    return (
      <div className='grid grid-cols-1'>
        {entriesData.map((item) => {
          const key = item[0];
          const { value } = item[1];
          // eslint-disable-next-line no-nested-ternary
          const price = `${
            // eslint-disable-next-line no-nested-ternary
            item[1].update_price
              ? Math.round(item[1].update_price)
              : item[1].price
              ? Math.round(item[1].price)
              : 0
          }`;
          return (
            <div className='flex items-center h-10 justify-between'>
              <div>{getValueByKey(value, key)}</div>
              <div className='font-bold '>
                {showPrediction && formatCurrency(Number(price))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Card title={unit.common_data.name}>
      <div className='space-y-10'>
        {imageIndex < common_data.images.length && (
          <div className={styles.imagesWrapper}>
            {common_data.images.length > 1 && (
              <>
                <ChevronLeftIcon
                  className={styles.chevronIconLeft}
                  onClick={() => handleChevron('prev')}
                />
                <ChevronRightIcon
                  className={styles.chevronIconRight}
                  onClick={() => handleChevron('next')}
                />
              </>
            )}
            <Image
              preview={{
                mask: false,
              }}
              src={`https://built-mind-internaldata.s3.eu-central-1.amazonaws.com/${developerId}/resources/images/flats/${getSafeSuffix(
                common_data.images[imageIndex].toString(),
              )}`}
              alt='Floor plan'
              className={styles.image}
            />
          </div>
        )}
        <div className='grid auto-rows-auto gap-10 px-6'>
          {/* INTERIOR */}
          <PriceRow priceData={price_breakdown.interior} />
          {/* EXTERIOR */}
          <PriceRow priceData={price_breakdown.exterior} />
          {/* FLOOR */}
          <PriceRow priceData={price_breakdown.floor} />
          {/* ORIENTATION */}
          <PriceRow priceData={price_breakdown.orientation} />
          {/* LAYOUT */}
          <PriceRow priceData={price_breakdown.layout} />
        </div>
      </div>
    </Card>
  );
};
