import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartBarSquareIcon } from '@heroicons/react/24/outline';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Card, Spin } from 'antd';
import { DefaultCardOptions } from './card/cardOptions/DefaultCardOptions';
import { Availability } from '../../store/types';
import { BLUE_1, BLUE_4 } from '../../styles/constants';
import { useStoreState } from '../../hooks';

export const FloorAreaChart: FC = () => {
  const { t } = useTranslation();
  const sales_by_floor_area = useStoreState(
    (state) => state.dashboard.dashboardData?.sales_by_floor_area ?? [],
  );
  const { includeReservations } = useStoreState((state) => state.dashboard);

  const getLabelNaming = (value: Availability): string => {
    if (value === Availability.AVAILABLE) return t('enums.state.available');
    if (includeReservations) return t('enums.state.sold_reserved');
    return t('enums.state.sold');
  };

  const transformedData = sales_by_floor_area.flatMap(
    ({ floor_area_range, availability_counts }) => {
      const { total, sold, reserved } = availability_counts;
      const transformedSold = includeReservations ? sold + reserved : sold;
      const start = floor_area_range[0];
      const end = floor_area_range[1];
      return [
        {
          midpoint: (Number(start) + Number(end)) / 2,
          type: Availability.AVAILABLE,
          value: total - transformedSold,
          title: `${start} - ${end}`,
        },
        {
          midpoint: (Number(start) + Number(end)) / 2,
          type: Availability.SOLD,
          value: transformedSold,
          title: `${start} - ${end}`,
        },
      ];
    },
  );

  const config: ColumnConfig = {
    data: transformedData,
    isStack: true,
    xField: 'midpoint',
    yField: 'value',
    appendPadding: [10, 0, 20, 0],
    height: 300,
    color: (datum: any) => (datum.type === Availability.SOLD ? BLUE_4 : BLUE_1),
    seriesField: 'type',
    xAxis: {
      type: 'linear',
      tickInterval: 5,
    },
    legend: {
      flipPage: false,
      position: 'bottom',
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
      itemName: {
        formatter: (value) => getLabelNaming(value as Availability),
      },
    },
    columnWidthRatio: 0.9,
    yAxis: { grid: null, nice: true },
    autoFit: true,
    padding: 'auto',
    tooltip: {
      fields: ['title', 'type', 'value'],
      formatter: (datum) => ({
        name: datum.type,
        value: datum.value ?? '-',
      }),
      title: (_, items) => items[0]?.title,
      customContent: (_, items) => {
        if (!items || items.length === 0) return '';
        const title = items[0]?.data?.title;

        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color ?? ''
              }"></div>
              <div class="bmt-label">${getLabelNaming(
                item.name as Availability,
              )}:</div>
              <div class="bmt-value">${item?.value ?? ''}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  };

  return <Column {...config} />;
};

export const SalesByFloorArea: FC = () => {
  const { t } = useTranslation();
  const { dashboardLoading } = useStoreState((state) => state.dashboard);

  return (
    <Card bordered={false} className='flex flex-col h-full rounded-[1.6rem]'>
      <div className='flex items-center justify-between pb-8'>
        <div className='flex gap-4 items-center'>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2'>
            <ChartBarSquareIcon className='stroke-2 w-10 h-10' />
          </div>
          <div className='font-bold'>
            {t('dashboard.sales_by_floor_area.title')}
          </div>
        </div>
        <DefaultCardOptions moreOptions />
      </div>
      {!dashboardLoading ? (
        <div className='px-8 pt-8'>
          <FloorAreaChart />
        </div>
      ) : (
        <div className='flex w-full justify-center items-center'>
          <Spin spinning />
        </div>
      )}
    </Card>
  );
};
