import { FC, useMemo, useState } from 'react';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { PricePerSM } from './PricePerSM';
import { SaleSpeed } from './SaleSpeed';
import { useRegionFormatting, useStoreState } from '../../../hooks';
import { ChartCard } from '../../ChartCard';
import { Availability } from '../../../store/types';
import { CardPopover } from '../../market/blocks/CardPopover';

enum SegmentedEnum {
  PRICE_PER_SM,
  SALE_SPEED,
}

export const ProgressCharts: FC = () => {
  const [value, setValue] = useState<string | number>(
    SegmentedEnum.PRICE_PER_SM,
  );
  const [availibility, setAvailability] = useState<string | number>(
    Availability.SOLD,
  );
  const { t } = useTranslation();
  const { areaUnit } = useRegionFormatting();
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  const chart = useMemo(() => {
    switch (value) {
      case SegmentedEnum.PRICE_PER_SM:
        return <PricePerSM />;
      case SegmentedEnum.SALE_SPEED:
        return <SaleSpeed availability={availibility as Availability} />;
      default:
        return <PricePerSM />;
    }
  }, [value, availibility]);
  return (
    <ChartCard
      title={t('project.progress_charts.title', 'Price and Sale History')}
      extra={<CardPopover showValues />}
      subtitle={t(
        'project.progress_charts.subtitle',
        'The history of the price and the sale speed of the project in terms of the units sold per month.',
      )}
      chart={chart}
      controls={
        <>
          {value === SegmentedEnum.SALE_SPEED && (
            <Segmented
              style={{ marginRight: '1.2rem' }}
              value={availibility}
              options={[
                {
                  value: Availability.SOLD,
                  label: isSold
                    ? t('enums.state.sales_reservations')
                    : t('enums.state.sales'),
                },
                {
                  value: Availability.RESERVED,
                  label: t('enums.state.reserved'),
                },
              ]}
              onChange={setAvailability}
            />
          )}
          <Segmented
            value={value}
            options={[
              {
                value: SegmentedEnum.PRICE_PER_SM,
                label: t('common.price_per_unit', 'Price per {{unit}}', {
                  unit: areaUnit,
                }),
              },
              {
                value: SegmentedEnum.SALE_SPEED,
                label: t('project.progress_charts.sale_speed', 'Sale Speed'),
              },
            ]}
            onChange={setValue}
          />
        </>
      }
    />
  );
};
