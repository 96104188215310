import { FC } from 'react';
import { PriceBreakdownRow } from './PriceBreakdownRow';
import { SalesPlan as SalesPlanData } from '../../../api/mockData/pricingPriceListDetaildata';

interface Props {
  data: SalesPlanData;
}
export const SalesPlanUpdated: FC<Props> = ({ data: { price_effect } }) => {
  const roundedValue = Math.round(Number(price_effect.value) * 100 * 10) / 10;
  const value = roundedValue > 0 ? `+${roundedValue}%` : `${roundedValue}%`;
  return (
    <PriceBreakdownRow
      className='row-spacing'
      houseAreaKey='sales_plan'
      housePartKey='price_effect'
      valueContent={value}
      price={price_effect.price}
      updatePrice={price_effect.update_price}
    />
  );
};
