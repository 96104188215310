import { FC, createRef, useState, useEffect, useMemo, memo } from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { KPI } from './KPI';
import { useRegionFormatting, useStoreState } from '../../../hooks';
import { Chevron } from '../Chevron';
import { SupplyChart } from '../SupplyChart';
import { InfoPopover } from '../..';

const SupplyComponent: FC = () => {
  const { t } = useTranslation();
  const KPIRef = createRef<HTMLDivElement>();
  const [height, setHeight] = useState(0);
  const { currentData } = useStoreState((state) => state.dailyNews);
  const { calculateVatPrice } = useRegionFormatting();

  useEffect(() => {
    if (KPIRef.current) {
      setHeight(KPIRef.current.offsetHeight);
    }
  }, [KPIRef]);

  const chartData = useMemo(() => {
    const graph_data = currentData?.graph_data;
    if (!currentData || !graph_data) return undefined;
    return {
      ...graph_data,
      available_timeline_price_per_sm: graph_data.available_timeline_price_per_sm
        .map((d) => ({
          ...d,
          avg_price: Number(calculateVatPrice(d.avg_price, 119)),
        })),
        sold_timeline_price_per_sm: graph_data.sold_timeline_price_per_sm
        .map((d) => ({
          ...d,
          avg_price: Number(calculateVatPrice(d.avg_price, 119)),
        })),
    };
  }, [currentData, calculateVatPrice]);

  return (
    <KPI
      ref={KPIRef}
      small
      title={t('daily_news.kpi.supply')}
      icon={
        <div className='supply daily_news_kpi_icon'>
          <img
            src='/assets/building_icon_dark_blue_compressed.png'
            alt='house'
          />
        </div>
      }
      secondary={{
        title: (
          <>
            {t('daily_news.kpi.available_units')}
            <InfoPopover
              popoverProps={{
                content:
                  <div className='popover-tooltip'>
                    {t(
                      'daily_news.kpi.tooltip.available_units_count',
                      'Calculated as the available and reserved units.',
                    )}
                  </div>,
              }}
            />
          </>),
        label: currentData?.graph_data?.available_today ?? '',
        value: currentData ? (
          <Chevron number={currentData?.graph_data?.available_last_month_pct_change ?? 0} />
        ) : (
          <Spin spinning />
        ),
        percent: currentData?.graph_data?.available_last_month_pct_change ?? 0,
      }}
      tertiary={{
        title: (
        <>
          {t('daily_news.kpi.price_per_sm')}
          <InfoPopover
            popoverProps={{
              content:
                <div className='popover-tooltip'>
                  {t(
                    'daily_news.kpi.tooltip.price_per_sm_available',
                    'Calculated as the price per SM of the available and reserved units including the exterior.',
                  )}
                </div>,
            }}
          />
        </>),
        label: calculateVatPrice(
          currentData?.graph_data?.available_price_per_sm_today ?? null,
          119,
        ),
        value: currentData ? (
          <Chevron
            number={
              calculateVatPrice(
                currentData?.graph_data?.available_price_per_sm_pct_change ?? null,
                119,
                )
              ?? 0
            }
          />
        ) : (
          <Spin spinning />
        ),
        percent: currentData?.graph_data?.available_price_per_sm_pct_change ?? 0,
      }}
      chart={chartData && <SupplyChart chartData={chartData} height={height} />}
    />
  );
};

export const Supply = memo(SupplyComponent);
