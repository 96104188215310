import { ReactNode, forwardRef, FC, ReactElement } from 'react';
import { Row, Col, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { Card } from '../../Card';
import { useBreakpoints, useRegionFormatting, useStoreState } from '../../../hooks';

interface KPIProps {
  title: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
  type?: string;
  children?: ReactNode;
  controls?: ReactNode;
  secondary?: {
    title?: ReactElement;
    value?: ReactNode;
    label?: ReactNode;
    dateFormat?: Intl.DateTimeFormatOptions;
    type?: string;
    percent?: number;
  };
  tertiary?: {
    title?: ReactElement;
    value?: ReactNode;
    label?: ReactNode;
    dateFormat?: Intl.DateTimeFormatOptions;
    percent?: number;
  };
  chart?: ReactNode;
  small?: boolean;
}

type PopOverProps = {
  children: ReactNode;
  title: ReactElement;
  label: ReactNode;
  secondaryLabel?: ReactNode;
  tertiaryLabel?: ReactNode;
};

const KPIPopver: FC<PopOverProps> = ({
  children,
  title,
  label,
  secondaryLabel,
  tertiaryLabel,
}) => (
  <Popover
    content={
      <div className='daily_news_popover'>
        <div className='title'>{title?.props?.children[0]}</div>
        <div>{label}</div>
        <div>{secondaryLabel}</div>
        <div>{tertiaryLabel}</div>
      </div>
    }
    trigger='hover'
  >
    {children}
  </Popover>
);

export const KPI = forwardRef<HTMLDivElement, KPIProps>((props, ref) => {
  const { isSmallScreen } = useBreakpoints();
  const {
    title,
    subtitle,
    icon,
    chart,
    secondary,
    tertiary,
    small,
    children,
    controls,
    type = 'horizontal',
  } = props;
  const {
    title: secondaryTitle,
    label: secondaryLabel,
    value: secondaryValue,
    dateFormat: secondaryDateFormat,
    type: secondaryType,
    percent,
  } = secondary || {};
  const {
    title: tertiaryTitle,
    label: tertiaryLabel,
    value: tertiaryValue,
    percent: secondaryPercent,
    dateFormat: tertiaryDateFormat,
  } = tertiary || {};

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { formatCurrency } = useRegionFormatting();
  const { currentData } = useStoreState((state) => state.dailyNews);

  return (
    <div className={`kpi daily_news ${type}`} ref={ref}>
      <Card bordered={false} style={{ height: small ? '20rem' : '80rem' }}>
        <Row className='kpi-container' align='top'>
          {type === 'horizontal' ? (
            <>
              <Col span={3} xs={7} xl={4} xxl={3}>
                <Row justify='center' className='title bold'>
                  {title}
                </Row>
                {subtitle && (
                  <Row justify='center' className='subtitle'>
                    {subtitle}
                  </Row>
                )}
                {icon && (
                  <Row justify='center' className='icon'>
                    {icon}
                  </Row>
                )}
              </Col>
              <Col span={5} xs={7} xl={5} xxl={4}>
                <Row justify='center' className='title'>
                  {secondaryTitle}
                </Row>
                <Row justify='center' className='icon'>
                  {secondaryLabel}
                </Row>
                <KPIPopver
                  title={secondaryTitle as ReactElement}
                  label={
                    <Row className='popover-label'>
                      <Col>
                        {new Date(
                          `${
                            secondaryType !== 'demand'
                              ? new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth() - 1,
                                  1,
                                ).toDateString()
                              : new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth() - 2,
                                  1,
                                ).toDateString()
                          }`,
                        ).toLocaleDateString(
                          language,
                          secondaryDateFormat || {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </Col>
                      <Col className='value' flex='auto'>
                        {secondaryType !== 'demand'
                          ? (
                              Number(secondaryLabel) *
                              ((100 + Number(percent) * -1) / 100)
                            ).toFixed(0)
                          : currentData?.graph_data?.sold_availability_timeline?.find(
                              (d) =>
                                d.date ===
                                `${new Date().getFullYear()}-${
                                  new Date().getMonth() - 1 > 9
                                    ? `${new Date().getMonth() - 1}`
                                    : `0${new Date().getMonth() - 1}`
                                }`,
                            )?.value ?? 0}
                      </Col>
                    </Row>
                  }
                  secondaryLabel={
                    <Row className='popover-label'>
                      <Col>
                        {new Date(
                          `${
                            secondaryType !== 'demand'
                              ? new Date().toDateString()
                              : new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth() - 1,
                                  1,
                                ).toDateString()
                          }`,
                        ).toLocaleDateString(
                          language,
                          secondaryDateFormat || {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </Col>
                      <Col className='value' flex='auto'>
                        {secondaryLabel}
                      </Col>
                    </Row>
                  }
                  tertiaryLabel={
                    <Row className='popover-label'>
                      <Col>{t('enums.state.change')}</Col>
                      <Col className='value' flex='auto'>
                        {secondaryValue}
                      </Col>
                    </Row>
                  }
                >
                  <Row justify='center' className='subtitle'>
                    {secondaryValue}
                  </Row>
                </KPIPopver>
              </Col>
              <Col span={5} xs={7} xl={5} xxl={4}>
                <Row justify='center' className='title'>
                  {tertiaryTitle}
                </Row>
                <Row justify='center' className='icon'>
                  {formatCurrency((tertiaryLabel as number) ?? null)}
                </Row>
                <KPIPopver
                  title={tertiaryTitle as ReactElement}
                  label={
                    <Row className='popover-label'>
                      <Col>
                        {new Date(
                          new Date().getFullYear(),
                          new Date().getMonth() - 1,
                          1,
                        ).toLocaleDateString(
                          language,
                          tertiaryDateFormat || {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </Col>
                      <Col className='value' flex='auto'>
                        {formatCurrency(
                          Number(
                            (
                              Number(tertiaryLabel) *
                              ((100 + Number(secondaryPercent) * -1) / 100)
                            ).toFixed(0) ?? null,
                          ),
                        )}
                      </Col>
                    </Row>
                  }
                  secondaryLabel={
                    <Row className='popover-label'>
                      <Col>
                        {new Date().toLocaleDateString(
                          language,
                          tertiaryDateFormat || {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}
                      </Col>
                      <Col className='value' flex='auto'>
                        {formatCurrency(Number(tertiaryLabel))}
                      </Col>
                    </Row>
                  }
                  tertiaryLabel={
                    <Row className='popover-label'>
                      <Col>{t('enums.state.change')}</Col>
                      <Col className='value' flex='auto'>
                        {tertiaryValue}
                      </Col>
                    </Row>
                  }
                >
                  <Row justify='center' className='subtitle'>
                    {tertiaryValue}
                  </Row>
                </KPIPopver>
              </Col>
              {!isSmallScreen && (
                <Col xl={10} xxl={11} span={11}>
                  {chart}
                </Col>
              )}
            </>
          ) : (
            <Col flex='auto' style={{ position: 'relative', height: '100%' }}>
              <Row justify='space-between' className='title'>
                <Row className='title'>{title}</Row>
                <div>{controls}</div>
              </Row>
              {subtitle && (
                <Row justify='start' className='subtitle'>
                  {subtitle}
                </Row>
              )}
              {icon && (
                <Row justify='start' className='icon'>
                  {icon}
                </Row>
              )}
              <div
                style={{
                  width: 'calc(100% + 48px)',
                  height: '1px',
                  background: '#DEDEDE',
                  marginLeft: '-24px',
                  marginTop: '12px',
                }}
              />
              <Row className='infinite-scroll-container'>{children}</Row>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
});
