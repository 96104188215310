/** @jsxImportSource @emotion/react */
import { ReactElement, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Collapse, Table, TableProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import {
  useStoreActions,
  useStoreState,
  useRegionFormatting,
  useBreakpoints,
  useFlatLayout,
} from '../../hooks';
import { getUniqueValues, sortByString } from '../../utils/utils';
import { getUpdateColumns } from './columnConfig';
import { PriceListTableData } from '../../api/mockData/priceListData';
import { PriceTrendEnum } from '../../pages/PriceUpdate';
import { PriceUpdateTableData } from '../../api/mockData/priceUpdateData';
import { styles } from './styles';
import { ColumnFiltersList } from '../ColumnFiltersList/ColumnFiltersList';
import { onSaveColumnFilters } from '../ColumnFiltersList/columnFilters';
import { ProjectType } from '../../store/types';

type Props = {
  data: typeof Table[]
  priceTrend: PriceTrendEnum
}

// type UpdateData = Flat & PriceListTableData

type PanelHeader = {
  attribute_name: string
  attribute_flag: string
  attribute_by_layout: string | null
  available_count: number
  total_count: number
  total_price_change: number
}

export const PriceUpdateTable = (props: Props): ReactElement => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const flag = searchParams.get('flag');
  const storedIndexes = localStorage.getItem('priceUpdateDataIndexes');
  const storedFilters = localStorage.getItem('priceUpdateFilters');
  const { data, priceTrend } = props;
  const { t } = useTranslation();
  const { breakpoints } = useBreakpoints();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const { formatCurrency, formatAreaUnits, calculateVatPrice, sortLayouts } =
    useRegionFormatting();
  const { getLayout, getLayoutAbbr } = useFlatLayout();
  const priceListData = useStoreState((state) =>
    state.priceList.data
      ?.sort((a, b) => a.internal_id?.localeCompare(b.internal_id))
      .map((obj) => {
        const newObj = { ...obj };
        const {
          current_price,
          current_price_per_sm,
          exterior_area,
          floor_area,
          new_price,
          new_price_per_sm,
        } = obj;

        newObj.current_price_per_sm =
          calculateVatPrice(current_price_per_sm, floor_area, exterior_area) ?? 0;
        newObj.current_price =
          calculateVatPrice(current_price, floor_area, exterior_area) ?? 0;

        newObj.new_price_per_sm =
          calculateVatPrice(new_price_per_sm, floor_area, exterior_area) ?? 0;
        newObj.new_price =
          calculateVatPrice(new_price, floor_area, exterior_area) ?? 0;
        return newObj;
      }),
  );

  const { showPricePerSM, selectedId, pageSize } = useStoreState(
    (state) => state.priceList,
  );
  const setSelectedId = useStoreActions(
    (actions) => actions.priceList.setSelectedId,
  );
  const setSelectedPricingId = useStoreActions(
    (actions) => actions.priceList.setSelectedPricingId,
  );
  const { setPageSize } = useStoreActions(
    (actions) => actions.priceList,
  );

  const rowClassName: TableProps<PriceListTableData>['rowClassName'] = (
    record,
  ) => (record.id === selectedId ? 'selected-row' : '');

  const onRow: TableProps<PriceListTableData>['onRow'] = ({ id }) => ({
    onClick: () => { setSelectedId(id); setSelectedPricingId(id); },
  });

  const [windowWidth] = useState(window.innerWidth);
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);
  const [columnsFilterValues, setColumnFilterValues] = useState<string[]>(windowWidth < 1600 ? ['floor', 'exterior_area'] : []);
  const [columnDataIndexes, setColumnDataIndexes] = useState<string[]>([]);
  const onHeaderRow: TableProps<PriceListTableData>['onHeaderRow'] = () => ({
    onMouseEnter: () => setIsMouseOver(true),
    onMouseLeave: () => setIsMouseOver(false),
  });

  const updateData = useMemo(() => data.map((updateItem) => {
    // @ts-ignore TODO: Fix typing
    const updatedFlats = updateItem.flats.map((updateFlat) => {
      const existingFlat = priceListData
        ?.find((priceListFlat) => priceListFlat.id === updateFlat.flat_id) || {};
      return {
        ...existingFlat,
        ...updateFlat,
      };
    });

    return {
      ...updateItem,
      // @ts-ignore TODO: Fix typing
      flats: updatedFlats.map((flat) => ({
        id: flat.flat_id,
        layout: flat.layout,
        floor: flat.floor,
        floor_area: flat.floor_area,
        exterior_area: flat.exterior_area,
        availability: flat.availability,
        current_price: flat.current_price,
        recommended_price: flat.new_price,
        current_price_per_sm: flat.current_price_per_sm,
        recommended_price_per_sm: flat.new_price_per_sm,
        internal_id: flat.internal_id,
        feature_update: flat.determinant_update_change * 100,
        total_update: flat.price_difference,
        // @ts-ignore TODO: Fix typing
      })).sort((a, b) => a.internal_id?.localeCompare(b.internal_id)),
    };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }), [data, priceListData]) as any;

  const collapsibleInitialOpen = useMemo(
    () => updateData.findIndex((table: any) => table.attribute_name === name && table.attribute_flag === flag),
    [name, updateData],
  );
  const activePanelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (collapsibleInitialOpen !== -1 && activePanelRef.current) {
      const timer = setTimeout(() => {
        activePanelRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 300);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [collapsibleInitialOpen]);
  const renderHeader = (): JSX.Element => (
      <div css={styles.headerContainer}>
          {priceTrend === PriceTrendEnum.INCREASE
            ? (
              <>
                <ArrowTrendingUpIcon css={styles.arrowIcon} /> <span css={styles.fontXl}>{isRent ? t('price_list.rent_increase') : t('price_list.price_increase', 'Price Increases')}</span>
              </>
            )
            : <>
                <ArrowTrendingDownIcon css={styles.arrowIcon} /> <span css={styles.fontXl}>{isRent ? t('price_list.rent_decrease') : t('price_list.price_decrease', 'Price Decreases')}</span>
              </>}
      </div>
    );

    const renderPanelHeader = ({
      attribute_name,
      attribute_flag,
      attribute_by_layout,
      available_count,
      total_count,
      total_price_change,
    }: PanelHeader): ReactNode => {
      const totalPriceChange = (total_price_change * 100).toFixed(1);
      const isLayoutClass = attribute_name === 'layout_class';
      const unavailable_count = total_count - available_count;
      const getPercentage = (count: number): string => ((count / total_count) * 100).toFixed(0);
      return (
      <div css={styles.panelHeaderContainer}>
          {/* LEFT SIDE */}
          <div css={styles.panelHeaderLeft}>
              <span>
                <span css={styles.panelHeaderContainerTitle}>
                  {t(`price_update.title.${attribute_name}`)}
                </span>
                <span className='text-[#828282]'>/</span>
                {attribute_by_layout && (
                  <>
                    <span css={styles.panelHeaderContainerSubtitle}>
                      {t(`enums.house_parts.layout_class.${attribute_by_layout}`)}
                    </span>
                    <span className='text-[#828282]'>{' '}/</span>
                  </>
                  )}
                <span css={styles.panelHeaderContainerSubtitle}>
                  {t(isLayoutClass ? attribute_flag : `price_update.subtitle.${attribute_flag}`)}
                </span>
              </span>
              <span css={styles.totalPriceChange}>{totalPriceChange.includes('-') ? totalPriceChange : `+${totalPriceChange}`}%</span>
          </div>
          {/* RIGHT SIDE */}
          <div css={styles.panelHeaderRight}>
            <Tooltip
              autoAdjustOverflow
              overlay={
              <div css={styles.tooltipContainer}>
              <div css={styles.tooltipColumnAvailability}>
                <p>{t('enums.state.available', 'Available')}</p>
                <p>{t('enums.state.sold_reserved', 'Sold + Reserved')}</p>
              </div>
              <div css={styles.tooltipColumn}>
                <p>{available_count}/{total_count}</p>
                <p>{unavailable_count}/{total_count}</p>
              </div>
              <div css={styles.tooltipColumn}>
                <p>{getPercentage(available_count)}%</p>
                <p>{getPercentage(unavailable_count)}%</p>
              </div>
              </div>
            }
            >
              <p className='mb-6 mt-6'>{available_count}/{total_count} {t('price_update.subtitle.units')}</p>
            </Tooltip>
          </div>
      </div>
      );
    };

    const onChangeFilter = (value: string): void => {
      if (columnsFilterValues.includes(value)) {
        setColumnFilterValues(columnsFilterValues.filter((val) => val !== value));
      } else {
        if (columnDataIndexes.length - columnsFilterValues.length === 2) return;
        setColumnFilterValues((prev) => [...prev, value]);
      }
    };

    useEffect(() => {
      if (!columnsFilterValues.length && storedFilters) {
        setColumnFilterValues(JSON.parse(storedFilters));
      }
    }, [columnsFilterValues.length, storedFilters]);

  return (
    <>
      {renderHeader()}
      <Collapse ghost defaultActiveKey={collapsibleInitialOpen} accordion expandIconPosition='end' style={{ paddingBottom: '5rem' }}>
        {/* @ts-ignore TODO: Fix typing */}
        {(updateData || []).length > 0 && updateData.map((item, index) => {
          const columns = getUpdateColumns(
            t,
            formatCurrency,
            formatAreaUnits,
            // @ts-ignore TODO: Fix typing
            getUniqueValues((item.flats ?? []).map((v) => v.layout)).sort(sortLayouts),
            // @ts-ignore TODO: Fix typing
            getUniqueValues((item.flats ?? []).map((v) => v.availability)).sort(sortByString),
            getLayout,
            getLayoutAbbr,
            showPricePerSM,
            sortLayouts,
            isRent,
          );
          const slicedColumns = columns.slice(1);
          const onOpenFilter = (dataIndexArray?: string[]): void => {
            if (!columnDataIndexes.length && dataIndexArray) {
              setColumnDataIndexes(storedIndexes ? JSON.parse(storedIndexes) : slicedColumns.map((col) => col.dataIndex as string));
            }
            setIsColumnFilterVisible(!isColumnFilterVisible);
          };
          const onSaveFilters = (): void => {
            onSaveColumnFilters('priceUpdateDataIndexes', columnDataIndexes, 'priceUpdateFilters', columnsFilterValues);
            setIsColumnFilterVisible(false);
          };
          const onResetFilters = (): void => {
            localStorage.removeItem('priceUpdateDataIndexes');
            localStorage.removeItem('priceUpdateFilters');
            setColumnFilterValues([]);
          };
          const filteredColumns = columns
            .filter((col) => col.dataIndex && !columnsFilterValues.includes(col.dataIndex.toString()))
            .sort((a, b) => columnDataIndexes.indexOf(a.dataIndex as string) - columnDataIndexes.indexOf(b.dataIndex as string));
          return (
            <Collapse.Panel key={item.key} header={renderPanelHeader(item)} ref={index === collapsibleInitialOpen ? activePanelRef : null}>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
              <div className='relative'>
                <div className='absolute items-center flex cursor-pointer z-10 right-5 top-4' onClick={() => onOpenFilter(slicedColumns.map((col) => col.dataIndex) as string[])}>
                  <EllipsisHorizontalIcon className='w-12 storke-2 text-[#828282]' />
                </div>
                <Table<PriceUpdateTableData<any, any>>
                  // @ts-ignore TODO: Fix typing
                  onRow={onRow}
                  rowKey='id'
                  // @ts-ignore TODO: Fix typing
                  rowClassName={rowClassName}
                  // @ts-ignore TODO: Fix typing
                  onHeaderRow={onHeaderRow}
                  className={`price-list-table ${!isMouseOver ? 'hide-filters' : ''}`}
                  dataSource={item.flats}
                  // @ts-ignore TODO: Fix typing
                  columns={filteredColumns}
                  showSorterTooltip={false}
                  pagination={{
                    pageSize: pageSize || 10,
                    onShowSizeChange: (_, size) => setPageSize(size),
                  }}
                  scroll={{ x: breakpoints.xl ? undefined : true }}
                />
                {isColumnFilterVisible && (
                  <div className='absolute -right-14 top-14 flex flex-col bg-white rounded-2xl shadow-xl p-4 min-w-[300px] z-20 space-y-6 text-bmblue select-none'>
                    <span className='font-bold p-4 flex items-center'>Selected Columns</span>
                    <ColumnFiltersList columnDataIndexes={columnDataIndexes} setColumnDataIndexes={setColumnDataIndexes} columnsFilterValues={columnsFilterValues} onChangeFilter={onChangeFilter} />
                    <div className='flex justify-evenly items-center space-x-4 p-4'>
                      <Button className='bm-default-btn' onClick={() => setIsColumnFilterVisible(false)}>
                        Cancel
                      </Button>
                      <Button className='bm-default-btn' onClick={() => onResetFilters()}>
                        Reset
                      </Button>
                      <Button className='bm-submit-btn' onClick={() => onSaveFilters()}>
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </>
  );
};
