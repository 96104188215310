import { FC, ReactNode } from 'react';

type DescriptionRowProps = {
  title: string;
  icon?: ReactNode;
  content: ReactNode;
}

export const DescriptionRow: FC<DescriptionRowProps> = ({ title, icon, content }) => (
  <div className='flex items-center min-w-[180px] min-h-[2rem]'>
    <div className='flex space-x-2 items-center w-1/2'>
      {icon}
      <span>{title}</span>
    </div>
    <div className='w-1/2'>
      {content}
    </div>
  </div>
);
