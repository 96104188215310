import { Dispatch, FC, SetStateAction } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { Checkbox, Popover } from 'antd';
import { useTranslation } from 'react-i18next';

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type MoreOptionsProps = {
  setShowAvailable: Dispatch<SetStateAction<boolean>>;
  showAvailabile: boolean;
  setShowSoldAndReserved: Dispatch<SetStateAction<boolean>>;
  showSoldAndReserved: boolean;
};

export const MoreOptions: FC<MoreOptionsProps> = ({
  setShowAvailable,
  showAvailabile,
  setShowSoldAndReserved,
  showSoldAndReserved,
}) => {
  const { t } = useTranslation();

  return (
    <div className='flex gap-4'>
      <Popover
        placement='bottom'
        trigger='click'
        content={
          <div className='flex gap-4 flex-col'>
            <div onClick={() => setShowAvailable((show) => !show)}>
              <span className='pr-4'>
                {t(
                  'dashboard.positioning.more_options.available',
                  'Show Available Units',
                )}
              </span>
              <Checkbox checked={showAvailabile} />
            </div>
            <div onClick={() => setShowSoldAndReserved((show) => !show)}>
              <span className='pr-4'>
                {t(
                  'dashboard.positioning.more_options.sold_reserved',
                  'Show Sold and Reserved Units',
                )}
              </span>
              <Checkbox checked={showSoldAndReserved} />
            </div>
          </div>
        }
      >
        <div role='button' tabIndex={0} onClick={() => handlePropagation()}>
          <EllipsisHorizontalIcon className='stroke-2 w-10 h-10' />
        </div>
      </Popover>
    </div>
  );
};
