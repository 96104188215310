/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useMemo, useState, useEffect } from 'react';
import { Segmented, Modal, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { styles } from '../../ChartCard/styles';
import { styles as msStyles } from '../../market/blocks/styles';
import { Card, CardProps } from '../../Card';
import { styles as customStyles } from '../../Zoom/styles';
import { useStoreState } from '../../../hooks';
import { useFilter } from '../../market/blocks/useFilter';
import { MarketShareData, MarketShareSaleSpeedData } from '../../../api/types';
import { Availability } from '../../../store/types';
import { MarketShareSaleSpeedChartData } from '../../market/blocks/MarketShare';
import {
  fetchBlockMarketShareData,
  fetchBlockMarketShareSaleSpeedData,
} from '../../../api';
import { useReservedData } from '../../../hooks/useReservedData';
import { MarketShareChart } from '../../market/blocks/MarketShare/MarketShareChart';
import { DateSelect } from '../../market/blocks/MarketShare/DateSelect';
import { PricePerSmCalcType } from '../../TopBar/SettingsPopover';

interface Props extends CardProps {
  isOpen: boolean;
  handleClose: () => void;
}

enum Filter {
  PROJECTS,
  DEVELOPERS,
}

export const MarketSharePopover: FC<Props> = ({ isOpen, handleClose }) => {
  const [data, setData] = useState<MarketShareData[]>([]);
  const [saleSpeedData, setSaleSpeedData] = useState<
    MarketShareSaleSpeedData[]
  >([]);
  const [isLoading, setLoading] = useState(true);
  const { filterValue, handleChange } = useFilter(Availability.SOLD);
  const projectFilter = useFilter(Filter.PROJECTS);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { selectedDate } = useStoreState((state) => state.market);
  const { area } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { mapReservedData } = useReservedData();

  useEffect(() => {
    if (paramsPM && area) {
      setLoading(true);
      fetchBlockMarketShareSaleSpeedData(
        area,
        paramsPM.date,
        pricePerSmCalculation,
      ).then((res) => {
        setSaleSpeedData(mapReservedData(res.data));
      });
      fetchBlockMarketShareData(area, paramsPM.date, pricePerSmCalculation)
        .then((response) => {
          setData(
            mapReservedData(
              response.data.filter((d) => typeof d.availability === 'string'),
            ),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM?.date, mapReservedData, pricePerSmCalculation]);

  const dates = useMemo(
    () => [...new Set(saleSpeedData.map((d) => d.date))],
    [saleSpeedData],
  );
  const chartData = useMemo(() => {
    let newData: (MarketShareData | MarketShareSaleSpeedChartData)[] = [
      ...data,
    ];
    if (
      filterValue === Availability.SOLD &&
      selectedDate &&
      projectFilter.filterValue === Filter.PROJECTS
    ) {
      newData = [
        ...saleSpeedData
          .filter((d) => d.date === selectedDate)
          .map(
            ({
              availability,
              date,
              developer_id,
              developer_name,
              layout,
              project_id,
              project_name,
              district,
              value,
            }) => ({
              availability,
              date,
              developer_id,
              layout,
              project_id,
              count: value,
              project_name,
              developer_name,
              district,
            }),
          ),
      ];
    }
    if (projectFilter.filterValue === Filter.DEVELOPERS) {
      const uniques = [
        ...new Set(saleSpeedData.map((d) => d.developer_id)),
      ].map((d) => ({
        developer_id: d,
        projects: [
          ...new Set(
            saleSpeedData
              .filter((obj) => obj.developer_id === d)
              .map((obj) => obj.project_id),
          ),
        ],
      }));
      const mappedData = uniques
        .map((d) =>
          d.projects
            .map((projectId) =>
              data
                .filter((nestedObj) => nestedObj.project_id === projectId)
                .map((nestedObj) => {
                  const {
                    developer_id,
                    developer_name,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  } = saleSpeedData.find(
                    ({ project_id }) => project_id === nestedObj.project_id,
                  )!;
                  return {
                    ...nestedObj,
                    developer_id,
                    developer_name,
                  };
                }),
            )
            .flat(),
        )
        .flat();

      newData = mappedData.reduce((result, project) => {
        const oldData = [...result];
        const { developer_id, developer_name } = project;
        const existingSum = oldData.find(
          (value) =>
            value.developer_id === developer_id &&
            value.isSum &&
            value.availability === project.availability,
        );
        const existingSumIndex = oldData.findIndex(
          (value) =>
            value.developer_id === developer_id &&
            value.isSum &&
            value.availability === project.availability,
        );
        if (existingSum && existingSumIndex) {
          oldData[existingSumIndex] = {
            ...existingSum,
            count: existingSum.count + (project.count || 0),
          };
          return [...oldData, project];
        }
        return [
          ...oldData,
          project,
          {
            count: project.count,
            developer_id,
            developer_name,
            availability: project.availability,
            isSum: true,
          },
        ];
      }, [] as any);
    }
    if (selectedDate && filterValue === Availability.SOLD) {
      const mappedData = saleSpeedData
        .filter((d) => d.date === selectedDate)
        .map(
          ({
            availability,
            date,
            developer_id,
            developer_name,
            layout,
            project_id,
            project_name,
            value,
          }) => ({
            availability,
            date,
            developer_id,
            layout,
            project_id,
            count: value,
            project_name,
            developer_name,
          }),
        );

      // Reduce the array to get the SUM of the counts for each project
      newData = mappedData.reduce((result, project) => {
        const oldData = [...result];
        const { project_id, project_name } = project;
        const existingSum = oldData.find(
          (value) =>
            value.project_id === project_id &&
            value.isSum &&
            value.availability === project.availability,
        );
        const existingSumIndex = oldData.findIndex(
          (value) =>
            value.project_id === project_id &&
            value.isSum &&
            value.availability === project.availability,
        );
        if (existingSum && existingSumIndex) {
          oldData[existingSumIndex] = {
            ...existingSum,
            count: existingSum.count + (project.count || 0),
          };
          return [...oldData, project];
        }
        return [
          ...oldData,
          project,
          {
            count: project.count,
            project_id,
            project_name,
            availability: project.availability,
            isSum: true,
          },
        ];
      }, [] as any);
    }
    return newData.filter((d) => d.availability === filterValue) as (
      | MarketShareData
      | MarketShareSaleSpeedChartData
    )[];
  }, [
    data,
    filterValue,
    saleSpeedData,
    selectedDate,
    projectFilter.filterValue,
  ]);

  return isOpen ? (
    <div css={styles.searchContainer}>
      <Modal
        className='bm-modal'
        css={customStyles.modal}
        open={isOpen}
        footer={null}
        onCancel={handleClose}
      >
        <Card css={styles.card}>
          <div css={styles.controlsContainer}>
            <div className='market-share'>
              <div className='segmented'>
                <Segmented
                  value={projectFilter.filterValue}
                  options={[
                    {
                      value: Filter.PROJECTS,
                      label: t('enums.state.projects'),
                    },
                    {
                      value: Filter.DEVELOPERS,
                      label: t('enums.state.developers'),
                    },
                  ]}
                  onChange={projectFilter.handleChange}
                />
                <Segmented
                  style={{ margin: '0 1.2rem' }}
                  value={filterValue}
                  options={[
                    {
                      value: Availability.SOLD,
                      label: t('enums.state.sold'),
                    },
                    {
                      value: Availability.AVAILABLE,
                      label: t('enums.state.available'),
                    },
                  ]}
                  onChange={handleChange}
                />
              </div>
              {filterValue === Availability.SOLD &&
                (!(saleSpeedData.length > 0) ? (
                  <Spin css={msStyles.center} spinning />
                ) : (
                  <DateSelect dates={dates} />
                ))}
            </div>
          </div>
          <div css={styles.chartContainer}>
            {!isLoading && (
              <MarketShareChart
                availability={filterValue as Availability}
                projects={projectFilter.filterValue === Filter.PROJECTS}
                data={chartData}
              />
            )}
          </div>
        </Card>
      </Modal>
    </div>
  ) : (
    <div> </div>
  );
};
