import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';
import { Mode } from '../../store/types';
import { Paths } from '../../routes/Paths';

export const Actions: FC = () => {
  const navigate = useNavigate();

  const mode = useStoreState((state) => state.market.mode);

  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const primaryParameters = useStoreState(
    (state) => state.market.primaryParameters,
  );
  const secondaryParameters = useStoreState(
    (state) => state.market.secondaryParameters,
  );
  const setActiveStep = useStoreActions(
    (actions) => actions.market.setAddReportStep,
  );
  const { polygons, circles } = useStoreState((state) => state.market);
  const projects = useStoreState((state) => state.market.simplifiedProjects);
  const createPrimaryDashboard = useStoreActions(
    (actions) => actions.market.createPrimaryDashboard,
  );
  const createSecondaryDashboard = useStoreActions(
    (actions) => actions.market.createSecondaryDashboard,
  );
  const updateDashboard = useStoreActions(
    (actions) => actions.market.updateDashboard,
  );
  const setPrimaryParameters = useStoreActions(
    (actions) => actions.market.setPrimaryParameters,
  );
  const setSecondaryParameters = useStoreActions(
    (actions) => actions.market.setSecondaryParameters,
  );
  const setMode = useStoreActions((actions) => actions.market.setMode);
  const resetTableData = useStoreActions(
    (actions) => actions.market.resetTableData,
  );
  const fetchDashboardSettings = useStoreActions(
    (actions) => actions.market.fetchDashboardSettings,
  );

  const { t } = useTranslation();

  const handleSave = (): void => {
    let error = false;
    if (primaryParameters) {
      if (
        (secondaryParameters &&
          !(polygons && Object.values(polygons).length > 0)) &&
        !(circles && Object.values(circles).length > 0)
      ) {
        message.error(t('market.validations.projects'));
        console.log('error');
        error = true;
      }
      if (projects.some((p) => !!p.include)) {
        if (mode === Mode.CREATE) {
          createPrimaryDashboard();
        }
        if (mode === Mode.EDIT) {
          updateDashboard();
        }
      } else {
        error = true;
        console.log('error');
        message.error(t('market.validations.projects'));
      }
    }

    if (paramsPM && mode === Mode.EDIT) {
      if (projects.some((p) => !!p.include)) {
        updateDashboard();
      } else {
        error = true;
        message.error(t('market.validations.projects'));
      }
    }
    if (secondaryParameters && !error) {
      if (
        (polygons && Object.values(polygons).length > 0) ||
        (circles && Object.values(circles).length > 0)
      ) {
        if (mode === Mode.CREATE) {
          createSecondaryDashboard();
        }
        if (mode === Mode.EDIT) {
          updateDashboard();
        }
      } else {
        error = true;
        console.log('error');
        message.error(t('market.validations.projects'));
      }
    }

    if (!error) {
      setPrimaryParameters(null);
      setSecondaryParameters(null);
    }
  };
  const handleCancel = (): void => {
    setPrimaryParameters(null);
    setSecondaryParameters(null);
    setActiveStep(null);
    if (mode === Mode.CREATE) {
      resetTableData();
      if (paramsPM) {
        fetchDashboardSettings(paramsPM);
      }
    }
    if (mode === Mode.EDIT) {
      resetTableData();
      if (paramsPM) {
        fetchDashboardSettings(paramsPM);
      }
    }
    setMode(Mode.READ);
    navigate(Paths.MARKET_PROJECTS);
  };
  return (
    <div className='h-auto flex gap-4 pointer-events-auto'>
      <Button shape='round' className='h-fit py-3 px-12' onClick={handleCancel}>
        {t('common.cancel')}
      </Button>
      <Button
        className='save-btn h-fit py-3'
        shape='round'
        type='primary'
        onClick={handleSave}
      >
        {t('common.save')}
      </Button>
    </div>
  );
};
