import { BarConfig, LineConfig } from '@ant-design/plots';
import { ColumnOptions, PieOptions, Options } from '@antv/g2plot';
import merge from 'lodash.merge';

const defaultOptions: Omit<Options, 'data'> = {
  xAxis: {
    grid: null,
  },
  yAxis: { grid: null, nice: true },
  autoFit: true,
  padding: 'auto',
  legend: {
    flipPage: false,
    position: 'bottom',
    marker: {
      symbol: 'circle',
      style: {
        r: 12,
      },
    },
  },
};

const defaultColumnOptions: Omit<ColumnOptions, 'data' | 'xField' | 'yField'> =
  {
    marginRatio: 0.3,
    columnStyle: {
      radius: [7, 7, 0, 0],
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
    legend: {
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
    },
  };

export const getColumnOptions = <T extends ColumnOptions>(options: T): T =>
  merge(options, merge(defaultColumnOptions, defaultOptions));

const defaultPieOptions: Omit<
  PieOptions,
  'angleField' | 'colorField' | 'data'
> = {
  // radius: 1,
  innerRadius: 0.6,
  radius: 0.8,
  startAngle: Math.PI,
  endAngle: Math.PI * 2,
  statistic: {
    title: false,
    content: false,
  },
  tooltip: {
    shared: false,
  },
  legend: {
    marker: {
      symbol: 'circle',
      style: {
        r: 12,
      },
    },
  },
};

export const getPieOptions = <T extends PieOptions>(options: T): T =>
  merge(options, merge(defaultPieOptions, defaultOptions));

const defaultBarConfig: Omit<BarConfig, 'data' | 'xField' | 'yField'> = {
  xAxis: {
    label: null,
  },
  legend: {
    marker: {
      symbol: 'circle',
      style: {
        r: 12,
      },
    },
  },
};

export const getBarOptions = <T extends LineConfig>(options: T): T =>
  merge(options, merge(defaultBarConfig, defaultOptions));

const defaultLineOptions: Omit<LineConfig, 'data'> = {
  smooth: true,
  lineStyle: { lineWidth: 3 },
  animation: {
    appear: {
      animation: 'path-in',
      duration: 3000,
    },
  },
  xAxis: {
    label: {
      offsetY: 10,
    },
  },
  legend: {
    marker: {
      symbol: 'circle',
      style: {
        r: 12,
      },
    },
  },
};

export const getLineOptions = <T extends LineConfig>(options: T): T =>
  merge(options, merge(defaultLineOptions, defaultOptions));
