/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Spin, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  fetchBlockMarketShareData,
  fetchBlockMarketShareSaleSpeedData,
} from '../../../../api';
import { useStoreState } from '../../../../hooks';
import { styles } from '../styles';
import { MarketShareChart } from './MarketShareChart';
import {
  MarketShareData,
  MarketShareSaleSpeedData,
} from '../../../../api/types';
import { ChartCard } from '../../../ChartCard';
import { useReservedData } from '../../../../hooks/useReservedData';
import { useFilter } from '../useFilter';
import { Availability } from '../../../../store/types';
import { DateSelect } from './DateSelect';
import { CardPopover } from '../CardPopover';
import { getLastUpdateText, getReportExportFileName } from '../../../../utils/utils';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { UpdateDates } from '../../../../api/enums';

enum Filter {
  PROJECTS = 'project_name',
  DEVELOPERS = 'developer_name',
  DISTRICTS = 'district',
}

export type MarketShareSaleSpeedChartData = Omit<
  MarketShareSaleSpeedData,
  'value'
>;

export const MarketShare: FC = () => {
  const [data, setData] = useState<MarketShareData[]>([]);
  const [saleSpeedData, setSaleSpeedData] = useState<
    MarketShareSaleSpeedData[]
  >([]);
  const [isLoading, setLoading] = useState(true);
  const { filterValue, handleChange } = useFilter(Availability.SOLD);
  const projectFilter = useFilter(Filter.PROJECTS);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const { selectedDates } = useStoreState((state) => state.market);
  const { t } = useTranslation();
  const { mapReservedData } = useReservedData();
  const pngRef = useRef(null);

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockMarketShareSaleSpeedData(
        paramsPM.dashboardId,
        paramsPM.date,
        pricePerSmCalculation,
      ).then((res) => {
        setSaleSpeedData(mapReservedData(res.data).filter((d) => dayjs(d.date).isBefore(dayjs().subtract(UpdateDates.LAST_5_DAYS, 'days'))));
      });
      fetchBlockMarketShareData(
        paramsPM.dashboardId,
        paramsPM.date,
        pricePerSmCalculation,
      ).then((res) => {
        setData(mapReservedData(res.data));
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [paramsPM, mapReservedData, pricePerSmCalculation]);

  const dates = useMemo(
    () => [...new Set(saleSpeedData.map((d) => d.date))],
    [saleSpeedData],
  );
  const chartData = useMemo(() => {
    let newData: (MarketShareData | MarketShareSaleSpeedChartData)[] = [
      ...data,
    ];
    // 1. SOLD
    if (filterValue === Availability.SOLD && selectedDates) {
      // a. PROJECTS
      if (projectFilter.filterValue === Filter.PROJECTS) {
        const projectData = [
          ...saleSpeedData
            .filter((d) => selectedDates.includes(d.date))
            .map(
              ({
                availability,
                date,
                developer_id,
                developer_name,
                layout,
                project_id,
                project_name,
                value,
              }) => ({
                availability,
                date,
                developer_id,
                layout,
                project_id,
                count: value,
                project_name,
                developer_name,
              }),
            ),
        ];

        newData = projectData
          .filter((p) => p.availability === Availability.SOLD)
          .reduce((result, project) => {
            const { project_id, project_name } = project;
            const oldData = [...result];
            const existingSum = oldData.find((value) => (
              value.project_id === project_id &&
              value.isSum &&
              value.availability === project.availability
            ));
            const existingSumIndex = oldData.findIndex((value) => (
              value.project_id === project_id &&
              value.isSum &&
              value.availability === project.availability
            ));
            if (existingSum && existingSumIndex) {
              oldData[existingSumIndex] = {
                ...existingSum,
                count: existingSum.count + (project.count || 0),
              };
              return [...oldData, project];
            }
            return [
              ...oldData,
              project,
              {
                count: project.count,
                project_id: project.project_id,
                project_name,
                availability: project.availability,
                isSum: true,
              },
            ];
          }, [] as any);
      }
      // b. DEVELOPERS
      if (projectFilter.filterValue === Filter.DEVELOPERS) {
        const developerData = saleSpeedData
          // 1. filter by date
          .filter((d) => selectedDates.includes(d.date))
          // 2. filter out counts that are NaN or 0
          .filter((d) => !Number.isNaN(d.count));

        newData = developerData.reduce((result, project) => {
          const oldData = [...result];
          const { developer_id, developer_name } = project;
          const existingSum = oldData.find((value) => (
            value.developer_id === developer_id &&
            value.isSum &&
            value.availability === project.availability
          ));
          const existingSumIndex = oldData.findIndex((value) => (
            value.developer_id === developer_id &&
            value.isSum &&
            value.availability === project.availability
          ));
          if (existingSum && existingSumIndex) {
            oldData[existingSumIndex] = {
              ...existingSum,
              count: existingSum.count + (project.count || 0),
            };
            return [...oldData, project];
          }
          return [
            ...oldData,
            project,
            {
              count: project.count,
              developer_id,
              developer_name,
              availability: project.availability,
              isSum: true,
            },
          ];
        }, [] as any);
      }
      // c. DISTRICTS
      if (projectFilter.filterValue === Filter.DISTRICTS) {
        const districtData = saleSpeedData
          // 1. Filter by date
          .filter((d) => selectedDates.includes(d.date))
          // 2. Optionally filter out counts that are NaN or 0 (if needed)
          .filter((d) => !Number.isNaN(d.count) && d.count !== 0);

        newData = districtData.reduce((result, project) => {
          const oldData = [...result];
          const { district, availability } = project;
          // Find existing summary for the same district and availability
          const existingSum = oldData.find((value) =>
            value.district === district &&
            value.isSum &&
            value.availability === availability,
          );
          const existingSumIndex = oldData.findIndex((value) =>
            value.district === district &&
            value.isSum &&
            value.availability === availability,
          );

          if (existingSum && existingSumIndex !== -1) {
            // If found, update the existing summary
            oldData[existingSumIndex] = {
              ...existingSum,
              count: existingSum.count + project.count,
            };
          } else {
            // If not found, create a new summary entry
            oldData.push({
              ...project,
              isSum: true,
              count: project.count,
            });
          }
          return oldData;
        }, [] as any);
      }
    }

    // 2. AVAILABLE
    if (filterValue === Availability.AVAILABLE) {
      // a. PROJECTS
      if (projectFilter.filterValue === Filter.PROJECTS) {
        newData = data
          .reduce((result, project) => {
            const oldData = [...result];
            const { project_id, project_name } = project;
            const existingSum = oldData.find((value) => (
              value.project_id === project_id &&
              value.isSum &&
              value.availability === project.availability
            ));
            const existingSumIndex = oldData.findIndex((value) => (
              value.project_id === project_id &&
              value.isSum &&
              value.availability === project.availability
            ));
            if (existingSum && existingSumIndex) {
              oldData[existingSumIndex] = {
                ...existingSum,
                count: existingSum.count + (project.count || 0),
              };
              return [...oldData, project];
            }
            return [
              ...oldData,
              project,
              {
                count: project.count,
                project_id,
                project_name,
                availability: project.availability,
                isSum: true,
              },
            ];
          }, [] as any);
      }
      // b. DEVELOPERS
      if (projectFilter.filterValue === Filter.DEVELOPERS) {
        const uniques = [
          ...new Set(saleSpeedData.map((d) => d.developer_id)),
        ].map((d) => ({
          developer_id: d,
          projects: [
            ...new Set(
              saleSpeedData
                .filter((obj) => obj.developer_id === d)
                .map((obj) => obj.project_id),
            ),
          ],
        }));

        const mappedData = uniques
          .map((d) =>
            d.projects
              .map((projectId) =>
                data
                  .filter((nestedObj) => nestedObj.project_id === projectId)
                  .map((nestedObj) => {
                    const {
                      developer_id,
                      developer_name,
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    } = saleSpeedData.find(
                      ({ project_id }) => project_id === nestedObj.project_id,
                    )!;
                    return {
                      ...nestedObj,
                      developer_id,
                      developer_name,
                    };
                  }),
              )
              .flat(),
          )
          .flat();

        newData = mappedData.reduce((result, project) => {
          const oldData = [...result];
          const { developer_id, developer_name } = project;
          const existingSum = oldData.find((value) => (
            value.developer_id === developer_id &&
            value.isSum &&
            value.availability === project.availability
          ));
          const existingSumIndex = oldData.findIndex((value) => (
            value.developer_id === developer_id &&
            value.isSum &&
            value.availability === project.availability
          ));
          if (existingSum && existingSumIndex) {
            oldData[existingSumIndex] = {
              ...existingSum,
              count: existingSum.count + (project.count || 0),
            };
            return [...oldData, project];
          }
          return [
            ...oldData,
            project,
            {
              count: project.count,
              developer_id,
              developer_name,
              availability: project.availability,
              isSum: true,
            },
          ];
        }, [] as any);
      }
      // c. DISTRICTS
      if (projectFilter.filterValue === Filter.DISTRICTS) {
        // Step 1: Group saleSpeedData by district
        const uniques = [
          ...new Set(saleSpeedData.map((d) => d.district)),
        ].map((district) => ({
          district,
          projects: [
            ...new Set(
              saleSpeedData
                .filter((obj) => obj.district === district)
                .map((obj) => obj.project_id),
            ),
          ],
        }));

        // Step 2: Map data to nested project details under each district
        const mappedData = uniques
          .map((d) =>
            d.projects
              .map((projectId) =>
                data
                  .filter((nestedObj) => nestedObj.project_id === projectId)
                  .map((nestedObj) => {
                    const {
                      district, // Assuming district data is also available in the `data` dataset
                      // Additional logic here if you have more district-related data to merge
                    } = saleSpeedData.find(({ project_id }) => project_id === nestedObj.project_id) || {}; // Safe fallback
                    return {
                      ...nestedObj,
                      district,
                    };
                  }),
              )
              .flat(),
          )
          .flat();

        // Step 3: Reduce to aggregate data by district
        newData = mappedData.reduce((result, project) => {
          const oldData = [...result];
          const { district } = project;
          const existingSum = oldData.find((value) =>
            value.district === district &&
            value.isSum &&
            value.availability === project.availability,
          );
          const existingSumIndex = oldData.findIndex((value) =>
            value.district === district &&
            value.isSum &&
            value.availability === project.availability,
          );
          if (existingSum && existingSumIndex !== -1) {
            oldData[existingSumIndex] = {
              ...existingSum,
              count: existingSum.count + (project.count || 0),
            };
          } else {
            oldData.push({
              ...project,
              isSum: true,
              count: project.count,
            });
          }
          return oldData;
        }, [] as any);
      }
    }
    return newData.filter((d) => d.availability === filterValue) as (
      | MarketShareData
      | MarketShareSaleSpeedChartData
    )[];
  }, [
    data,
    filterValue,
    saleSpeedData,
    selectedDates,
    projectFilter.filterValue,
  ]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.market_share.title', 'Market Share'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <ChartCard
        title={t('market.reports.market_share.title', 'Market Share')}
        subtitle={`${t(
          'market.reports.market_share.subtitle',
          'The number of available units in the selected projects.',
        )} ${getLastUpdateText(UpdateDates.LAST_5_DAYS, t)}`}
        extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
        controls={
          <div className='market-share'>
            <div className='segmented'>
              <Segmented
                value={projectFilter.filterValue}
                style={{ marginRight: '1.2rem' }}
                options={[
                  {
                    value: Filter.PROJECTS,
                    label: t('enums.state.projects'),
                  },
                  {
                    value: Filter.DEVELOPERS,
                    label: t('enums.state.developers'),
                  },
                  {
                    value: Filter.DISTRICTS,
                    label: t('enums.state.districts'),
                  },
                ]}
                onChange={projectFilter.handleChange}
              />
              <Segmented
                style={{ margin: '1.2rem 0 0 0' }}
                value={filterValue}
                options={[
                  {
                    value: Availability.SOLD,
                    label: isSold ? t('enums.state.sales_reservations', 'Sales and Reservations') : t('enums.state.sales', 'Sales'),
                  },
                  {
                    value: Availability.AVAILABLE,
                    label: t('enums.state.available'),
                  },
                ]}
                onChange={handleChange}
              />
            </div>
            {filterValue === Availability.SOLD &&
              (!(saleSpeedData.length > 0) ? (
                <Spin css={styles.center} spinning />
              ) : (
                <DateSelect dates={dates} />
              ))}
          </div>
        }
        chart={
          isLoading ? (
            <Spin css={styles.center} spinning />
          ) : (
            <MarketShareChart
              availability={filterValue as Availability}
              projectFilter={projectFilter.filterValue}
              projects={projectFilter.filterValue === Filter.PROJECTS}
              data={chartData}
            />
          )
        }
      />
    </div>
  );
};
