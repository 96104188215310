/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { formatPercent } from '../../utils';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-price_list`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

export const csvExporter = new ExportToCsv(options);

type Props = {
  type: 'priceList' | 'priceUpdate';
};

export const PriceExportButton: FC<Props> = ({ type }) => {
  const { calculateVatPrice, getCurrency, getVATText } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayoutAbbr } = useFlatLayout();
  const priceListData = useStoreState(
    (state) => state.priceList.pricingData?.flats,
  );
  const priceUpdateData = useStoreState(
    (state) => state.priceList.updateDataUpdated,
  );
  const pricesDecreases = useStoreState(
    (state) => state.filters.priceDecreases,
  );

  const largeUpdates = priceUpdateData?.updates?.positive?.large_updates ?? [];
  const disabledUpdates =
    priceUpdateData?.updates?.positive?.disabled_updates ?? [];
  const smallUpdates = priceUpdateData?.updates?.positive?.small_updates ?? [];

  const updates = [...largeUpdates, ...disabledUpdates, ...smallUpdates];

  const positiveIds = updates.flatMap((update) =>
    update.affected_units.map((unit) => unit.id),
  );

  const positiveUnits = priceUpdateData?.flats?.filter((flat) =>
    positiveIds.includes(flat.id),
  );

  const priceUpdateFormattedData = pricesDecreases
    ? priceUpdateData?.flats
    : positiveUnits;

  const data = type === 'priceList' ? priceListData : priceUpdateFormattedData;

  const mappedData = data?.map(
    ({
      name: internal_id,
      layout,
      floor_area,
      exterior_area,
      floor,
      availability,
      recommended_price,
      current_price,
      update_percentage,
      building_name,
    }) => ({
      id: internal_id,
      layout: getLayoutAbbr(`layout_${layout}`),
      floor_area,
      exterior_area: exterior_area ?? 0,
      floor,
      availability,
      building_name,
      ...{
        ...(withVAT
          ? {
              new_price_with_vat: recommended_price,
              current_price_with_vat: current_price,
            }
          : {
              new_price_without_vat: recommended_price,
              current_price_without_vat: current_price,
            }),
      },
      currency: getCurrency(),
      price_difference: formatPercent(update_percentage ?? 0),
    }),
  );
  const handleClick = (format: string): void => {
    if (mappedData) {
      if (format === 'csv') {
        csvExporter.options.filename = `${options.filename}${getVATText()}`;
        csvExporter.generateCsv(mappedData);
        return;
      }
      if (format === 'xlsx') {
        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}.xlsx`);
        return;
      }
      const CRMData = data?.map((d) => ({
        internalId: d.name,
        buildingNo: d.building_name,
        floorNo: d.floor,
        price: calculateVatPrice(
          d.current_price,
          d.floor_area,
          Number(d.exterior_area),
          false,
        ),
        priceVAT: calculateVatPrice(
          d.current_price,
          d.floor_area,
          Number(d.exterior_area),
          true,
        ),
      }));
      if (CRMData) {
        const worksheet = XLSX.utils.json_to_sheet(CRMData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}.xlsx`);
      }
    }
  };
  return (
    <>
      <ExportDropdown type={type} onDownload={handleClick} />
    </>
  );
};
