/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { Row, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProjectDetailTable } from '../ProjectDetailTable';
import { aggregatePriceListData } from '../../../../../utils/utils';
import { styles } from '../styles';
import { Data } from '../types';
import {
  Availability,
  ExtendedProjectDetailTableData,
  MarketProjectExtended,
} from '../../../../../store/types';
import { useReservedData } from '../../../../../hooks/useReservedData';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../../../../hooks';
import { FilterEnum } from '../../../../project/useFilter';
import { ProjectCardDescription } from './ProjectCardDescription/ProjectCardDescription';
import { Description } from './Description';

interface Props {
  data: MarketProjectExtended;
}

interface Summary {
  key: 'available' | 'interior_sm' | 'price_per_sm' | 'price';
  value: number | string | null;
}

export const Content: FC<Props> = ({ data }) => {
  const { price_list: priceList } = data;
  const setAvailable = useStoreActions(
    (state) => state.filters.setAvailability,
  );
  const available = useStoreState((state) => state.filters.availability);
  const { t } = useTranslation();
  const { mapReservedData } = useReservedData();
  const { sortByLayouts, formatCurrency } = useRegionFormatting();
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  const handleChange = (state: string | number): void => {
    setAvailable(String(state) as FilterEnum);
  };

  const { tableData, priceListData, customSummary } = useMemo(() => {
    const mappedPriceList = mapReservedData(priceList);
    const aggregatedTableData = aggregatePriceListData(mappedPriceList);

    const filteredPriceListdata = mappedPriceList.filter(
      ({ availability }) =>
        availability ===
        (available === FilterEnum.AVAILABLE
          ? Availability.AVAILABLE
          : Availability.SOLD),
    );

    const summary: Summary[] = [
      {
        key: 'price_per_sm',
        value: formatCurrency(
          available === FilterEnum.AVAILABLE
            ? data?.avg_price_per_sm
            : data?.sold_avg_price_per_sm,
        ),
      },
      {
        key: 'price',
        value: formatCurrency(
          available === FilterEnum.AVAILABLE
            ? data?.avg_price
            : data?.sold_avg_price,
        ),
      },
    ];

    const sortedAggregatedTableData = aggregatedTableData.filter(
      ({ availability }) =>
        availability ===
        (available === FilterEnum.AVAILABLE
          ? Availability.AVAILABLE
          : Availability.SOLD),
    );

    const finalTableData = sortedAggregatedTableData.map((aggregatedData) => {
      const layoutData = data?.average_prices_layouts?.find(
        (d) => d.layout_name === aggregatedData.layout,
      );
      return available === FilterEnum.AVAILABLE
        ? {
            ...aggregatedData,
            price: layoutData?.avg_price ?? null,
            price_per_sm: layoutData?.avg_price_per_sm ?? null,
          }
        : {
            ...aggregatedData,
            price: layoutData?.sold_avg_price ?? null,
            price_per_sm: layoutData?.sold_avg_price_per_sm ?? null,
          };
    }) as ExtendedProjectDetailTableData[];

    return {
      tableData: sortByLayouts(finalTableData),
      priceListData: filteredPriceListdata,
      customSummary: summary,
    };
  }, [available, mapReservedData, priceList, sortByLayouts, formatCurrency]);

  return (
    <>
      <ProjectCardDescription data={data} />
      <Row css={styles.segmentedContainer}>
        <Segmented
          value={available}
          options={[
            {
              value: FilterEnum.AVAILABLE,
              label: t('enums.state.available', 'Available'),
            },
            {
              value: FilterEnum.SOLD,
              label: isSold
                ? t('enums.state.sales_reservations')
                : t('enums.state.sales'),
            },
          ]}
          onChange={handleChange}
        />
      </Row>
      <Row>
        <ProjectDetailTable
          dataSource={tableData}
          priceList={priceListData}
          floorCoefficient={data.floor_coefficient}
          customSummary={customSummary}
          using_total_area={data.using_total_area}
        />
      </Row>
    </>
  );
};
