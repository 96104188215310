import { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { AVAILABLE, SOLD } from '../styles/constants';

type MinimalPieChartProps = {
  sold: number;
  total: number;
};

export const MinimalPieChart: FC<MinimalPieChartProps> = ({ total, sold }) => (
  <div className='w-[24px] justify-self-end'>
    <PieChart
      lineWidth={60}
      startAngle={-90}
      animate
      className='w-[24px]'
      data={[
        { value: sold, color: AVAILABLE },
        { value: total - sold, color: SOLD },
      ]}
    />
  </div>
);
