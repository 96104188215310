/** @jsxImportSource @emotion/react */
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import clsx from 'clsx';
import { InfoPopover } from '../../../InfoPopover';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { styles } from './styles';
import { PriceDetailFilter } from '../../../../api/enums';
import { REVAMP_ALLOWED_DEVELOPERS } from '../../../../constants';

interface Props {
  className?: string;
  houseAreaKey?: string;
  housePartKey: string;
  showMinus?: boolean;
  price?: number;
  popoverTitle?: ReactNode;
  valueContent: ReactNode;
  layoutValue?: boolean;
  popoverContent?: ReactNode;
  updatePrice?: number;
}

export const PriceBreakdownRow: FC<Props> = ({
  className,
  houseAreaKey,
  housePartKey,
  showMinus,
  popoverTitle,
  price: row_price,
  valueContent,
  layoutValue = false,
  popoverContent,
  updatePrice,
}) => {
  const { showPrediction, showPredictionUpdated } = useStoreState(
    (state) => state.priceListDetail,
  );

  const { formatCurrency } = useRegionFormatting();
  const { t } = useTranslation();
  const priceFilter = useStoreState(
    (state) => state.priceListDetail.priceFilter,
  );
  const isPercentage = priceFilter === PriceDetailFilter.PERCENTAGE;
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id ?? 0,
  );
  const showPrices = REVAMP_ALLOWED_DEVELOPERS.includes(developerId)
    ? showPredictionUpdated
    : showPrediction;
  const isNewData = REVAMP_ALLOWED_DEVELOPERS.includes(developerId);
  const price = isPercentage && isNewData ? (row_price ?? 0) * 100 : row_price;
  const update =
    isPercentage && isNewData ? (updatePrice ?? 0) * 100 : updatePrice;

  return (
    <div className={clsx(className, 'grid grid-cols-4 gap-10')}>
      <div css={styles.title}>
        {houseAreaKey && <b>{t(`enums.house_areas.${houseAreaKey}`)}</b>}
      </div>
      <div className='flex justify-between'>
        <div>{t(`enums.house_parts.${housePartKey}`)}</div>
        <div css={styles.infoButton}>
          <InfoPopover
            popoverProps={{
              content: popoverContent ?? (
                <div css={styles.popoverContainer}>
                  <p>{t(`price_list.hints.${housePartKey}.desc`)}</p>
                </div>
              ),
              title: popoverTitle ?? t(`enums.house_parts.${housePartKey}`),
            }}
          />
        </div>
      </div>
      <div className='flex justify-between'>
        <div css={styles.value}>
          {layoutValue
            ? t(`enums.house_parts.layout_class.${valueContent}`)
            : valueContent}
        </div>
        <div css={styles.price}>
          {!!price && showPrices && (
            <>
              {showMinus && '-'}
              {isPercentage
                ? `${price.toFixed(1) ?? 0} %`
                : formatCurrency(price)}
            </>
          )}
        </div>
      </div>
      {/* Positive update price */}
      {!!update && update > 0 && showPrices && (
        <div className='flex justify-between'>
          <div css={styles.chevronPositive}>
            <CaretUpFilled />
          </div>
          <div css={styles.updatePricePositive}>
            {`+ ${
              isPercentage
                ? `${update.toFixed(1) ?? 0} %`
                : formatCurrency(update)
            }`}
          </div>
        </div>
      )}
      {/* Negative update price */}
      {!!update && update < 0 && showPrices && (
        <div className='flex justify-between'>
          <div css={styles.chevronNegative}>
            <CaretDownFilled />
          </div>
          <div css={styles.updatePriceNegative}>
            {`${
              isPercentage
                ? `${update.toFixed(1) ?? 0} %`
                : formatCurrency(update)
            }`}
          </div>
        </div>
      )}
    </div>
  );
};
