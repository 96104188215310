import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceBreakdownRow } from './PriceBreakdownRow';
import { Floor as FloorData } from '../../../api/mockData/pricingPriceListDetaildata';

interface Props {
  data: FloorData;
  isHouse: boolean;
}

export const FloorUpdated: FC<Props> = ({
  data: { bottom_floor, floor, top_floor, floor_count },
  isHouse,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        {isHouse ? (
          <PriceBreakdownRow
            className='row-top-spacing'
            houseAreaKey='floor'
            housePartKey='floor_count'
            valueContent={floor_count.value}
            price={floor_count.price}
            updatePrice={floor_count.update_price}
          />
        ) : (
          <>
            <PriceBreakdownRow
              className='row-top-spacing'
              houseAreaKey='floor'
              housePartKey='floor'
              valueContent={floor.value}
              price={floor.price}
              updatePrice={floor.update_price}
            />
            <PriceBreakdownRow
              housePartKey='top_floor'
              valueContent={top_floor.value ? t('common.yes') : t('common.no')}
              price={top_floor.price}
              updatePrice={top_floor.update_price}
            />
            <PriceBreakdownRow
              housePartKey='bottom_floor'
              valueContent={
                bottom_floor.value ? t('common.yes') : t('common.no')
              }
              price={bottom_floor.price}
              updatePrice={bottom_floor.update_price}
            />
          </>
        )}
      </div>
    </>
  );
};
