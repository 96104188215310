/** @jsxImportSource @emotion/react */
import {
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { BanIcon, CirclePlus } from 'lucide-react';
import { useSearchParams } from 'react-router-dom';
import { Button, Collapse, Table, TableProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import {
  useStoreActions,
  useStoreState,
  useRegionFormatting,
  useBreakpoints,
  useFlatLayout,
} from '../../hooks';
import { getUniqueValues, sortByString } from '../../utils/utils';
import { getUpdateColumns } from './columnConfig';
import { PriceListTableData } from '../../api/mockData/priceListData';
import { PriceTrendEnum } from '../../pages/PriceUpdate';
import { PriceUpdateTableData } from '../../api/mockData/priceUpdateData';
import { styles } from './styles';
import { ColumnFiltersList } from '../ColumnFiltersList/ColumnFiltersList';
import { onSaveColumnFilters } from '../ColumnFiltersList/columnFilters';
import { ProjectType } from '../../store/types';
import { formatPercent } from '../../utils';
import { MinimalPieChart } from '../MinimalPieChart';
import { K, M } from '../../constants';
import {
  PercentageChange,
  UpdateDetails,
} from '../../api/mockData/priceUpdateDataUpdated';
import { UpdateTag } from './UpdateTag/UpdateTag';

type Props = {
  data: typeof Table[];
  priceTrend: PriceTrendEnum;
  disabledUpdatesGroup?: boolean;
  handleDisableAttribute: (attribute: Partial<UpdateDetails>) => void;
  handleEnableAttribute: (attribute: Partial<UpdateDetails>) => void;
  isDisabledAttribute: (attribute: Partial<UpdateDetails>) => boolean;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
  percentageChange: PercentageChange[];
  filteredPercentageChange: PercentageChange[];
  setPercentageChange: Dispatch<SetStateAction<PercentageChange[]>>;
  resetSignal: number;
};

type PanelHeader = {
  attribute_by_layout: string | null;
  attribute_flag: string;
  attribute_name: string;
  total_count: number;
  unavailable_count: number;
  disabled_reason: string;
  updates_sum: number;
  revenue_change_percentage: number;
  avg_update_percentage: number;
};

export const PriceUpdateTableUpdated: FC<Props> = ({
  data,
  priceTrend,
  disabledUpdatesGroup = false,
  handleDisableAttribute,
  handleEnableAttribute,
  isDisabledAttribute,
  isEditing,
  setIsEditing,
  percentageChange,
  filteredPercentageChange,
  setPercentageChange,
  resetSignal,
}): ReactElement => {
  const [searchParams] = useSearchParams();
  const name = searchParams.get('name');
  const flag = searchParams.get('flag');
  const storedIndexes = localStorage.getItem('priceUpdateDataIndexes');
  const storedFilters = localStorage.getItem('priceUpdateFilters');
  const { t } = useTranslation();
  const { breakpoints } = useBreakpoints();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;
  const { formatCurrency, formatAreaUnits, sortLayouts } =
    useRegionFormatting();
  const { getLayout, getLayoutAbbr } = useFlatLayout();

  const { showPricePerSM, selectedId, pageSize } = useStoreState(
    (state) => state.priceList,
  );
  const developerId = useStoreState((state) => state.user.profile);
  const setSelectedId = useStoreActions(
    (actions) => actions.priceList.setSelectedId,
  );
  const setSelectedPricingId = useStoreActions(
    (actions) => actions.priceList.setSelectedPricingId,
  );
  const setSelectedPricingUpdateId = useStoreActions(
    (actions) => actions.priceList.setSelectedPricingUpdateId,
  );
  const { setPageSize } = useStoreActions((actions) => actions.priceList);

  const rowClassName: TableProps<PriceListTableData>['rowClassName'] = (
    record,
  ) => (record.id === selectedId ? 'selected-row' : '');

  const onRow: TableProps<PriceListTableData>['onRow'] = ({ id }) => ({
    onClick: () => {
      setSelectedId(id);
      setSelectedPricingId(id);
      setSelectedPricingUpdateId(id);
    },
  });

  const [windowWidth] = useState(window.innerWidth);
  const [isColumnFilterVisible, setIsColumnFilterVisible] = useState(false);
  const [columnsFilterValues, setColumnFilterValues] = useState<string[]>(
    windowWidth < 1600 ? ['floor', 'exterior_area'] : [],
  );
  const [columnDataIndexes, setColumnDataIndexes] = useState<string[]>([]);
  const onHeaderRow: TableProps<PriceListTableData>['onHeaderRow'] = () => ({
    onMouseEnter: () => setIsMouseOver(true),
    onMouseLeave: () => setIsMouseOver(false),
  });

  const collapsibleInitialOpen = useMemo(
    () =>
      data.findIndex(
        (table: any) =>
          table.attribute_name === name && table.attribute_flag === flag,
      ),
    [name, data],
  );
  const activePanelRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (collapsibleInitialOpen !== -1 && activePanelRef.current) {
      const timer = setTimeout(() => {
        activePanelRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 300);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [collapsibleInitialOpen]);

  const renderPanelHeader = ({
    attribute_by_layout,
    attribute_flag,
    attribute_name,
    total_count,
    unavailable_count,
    disabled_reason,
    updates_sum,
    revenue_change_percentage,
    avg_update_percentage,
  }: PanelHeader): ReactNode => {
    const totalPriceChange = formatCurrency(
      updates_sum,
      false,
      Math.abs(updates_sum) > M,
      Math.abs(updates_sum) < M && Math.abs(updates_sum) > K,
    );
    const availableCount = total_count - unavailable_count;
    const isLayoutClass = attribute_name === 'layout_class';
    const getPercentage = (count: number): string =>
      formatPercent(count / total_count, {
        truncate: 0,
      });
    const partialAttribute = {
      attribute_by_layout: attribute_by_layout ?? null,
      attribute_flag,
      attribute_name,
    };
    const isDisabled = isDisabledAttribute(partialAttribute);
    const canDisable =
      isDisabled ||
      !filteredPercentageChange?.some(
        (change) =>
          change.attribute_by_layout === attribute_by_layout &&
          change.attribute_flag === attribute_flag &&
          change.attribute_name === attribute_name,
      );
    return (
      <div
        className={clsx(
          isDisabled && 'text-[#828282] opacity-50',
          'group border-b-[2px] border-[#DEDEDE] flex justify-between items-center relative',
        )}
      >
        {/* LEFT SIDE */}
        <div
          className={clsx('absolute -left-10 w-10')}
          onClick={(e) => {
            e.stopPropagation();
            if (handleDisableAttribute && !isDisabled) {
              handleDisableAttribute(partialAttribute);
            }
            if (handleEnableAttribute && isDisabled) {
              handleEnableAttribute(partialAttribute);
            }
            if (!isEditing) {
              setIsEditing(true);
            }
          }}
        >
          {disabledUpdatesGroup ? (
            <CirclePlus
              className={clsx(
                isDisabled ? 'text-bmblue' : 'text-[#828282]',
                'w-8',
              )}
            />
          ) : (
            <div>
              {canDisable && (
                <Tooltip
                  title={t('price_update.disable.tooltip')}
                  trigger='hover'
                >
                  <BanIcon
                    className={clsx(
                      isDisabled
                        ? 'text-bmblue block'
                        : 'text-[#828282] hidden group-hover:block',
                      'w-8',
                    )}
                  />
                </Tooltip>
              )}
            </div>
          )}
        </div>
        <div className='flex items-center justify-start flex-wrap'>
          <Tooltip
            autoAdjustOverflow
            overlay={
              <div className='flex flex-col'>
                <div className='flex gap-10 w-full justify-between'>
                  <span>
                    <span className='text-bmblue font-semibold mr-[0.5rem]'>
                      {t(`price_update.title.${attribute_name}`)}
                    </span>
                    <span className='text-[#828282]'>/</span>
                    {attribute_by_layout && (
                      <>
                        <span className='text-[#828282] ml-[0.5rem]'>
                          {t(
                            `enums.house_parts.layout_class.${attribute_by_layout}`,
                          )}
                        </span>
                        <span className='text-[#828282]'> /</span>
                      </>
                    )}
                    <span className='text-[#828282] ml-[0.5rem]'>
                      {t(
                        isLayoutClass
                          ? attribute_flag
                          : `price_update.subtitle.${attribute_flag}`,
                      )}
                    </span>
                  </span>
                  <span>
                    {avg_update_percentage > 0
                      ? `+${formatPercent(avg_update_percentage)}`
                      : formatPercent(avg_update_percentage)}
                  </span>
                </div>
                <div className='flex gap-10 w-full justify-between'>
                  <span className='font-bold'>
                    {t('price_update.tooltip.total_change')}
                  </span>
                  <span>
                    {revenue_change_percentage > 0
                      ? `+${formatPercent(revenue_change_percentage)}`
                      : formatPercent(revenue_change_percentage)}
                  </span>
                </div>
              </div>
            }
          >
            <div className='flex items-center flex-wrap'>
              <span>
                <span className='text-bmblue font-semibold mr-[0.5rem]'>
                  {t(`price_update.title.${attribute_name}`)}
                </span>
                <span className='text-[#828282]'>/</span>
                {attribute_by_layout && (
                  <>
                    <span className='text-[#828282] ml-[0.5rem]'>
                      {t(
                        `enums.house_parts.layout_class.${attribute_by_layout}`,
                      )}
                    </span>
                    <span className='text-[#828282]'> /</span>
                  </>
                )}
                <span className='text-[#828282] ml-[0.5rem]'>
                  {t(
                    isLayoutClass
                      ? attribute_flag
                      : `price_update.subtitle.${attribute_flag}`,
                  )}
                </span>
              </span>
              <UpdateTag
                revenue_change_percentage={revenue_change_percentage}
                priceTrend={priceTrend}
                percentageChangeArr={percentageChange}
                setPercentageChangeArr={setPercentageChange}
                partialAttribute={partialAttribute}
                avg_update_percentage={avg_update_percentage}
                isDisabled={isDisabled}
                isEditing={isEditing}
                resetSignal={resetSignal}
                setIsEditing={setIsEditing}
                handleDisableAttribute={handleDisableAttribute}
              />
            </div>
          </Tooltip>
          <div className='select-none'>
            <Tooltip
              autoAdjustOverflow
              overlay={
                <div className='flex gap-10'>
                  <span className='font-bold'>
                    {t('price_update.tooltip.revenue_change')}
                  </span>
                  <span>
                    {updates_sum > 0
                      ? `+${totalPriceChange}`
                      : totalPriceChange}
                  </span>
                </div>
              }
            >
              <span>
                {updates_sum > 0 ? `+${totalPriceChange}` : totalPriceChange}
              </span>
            </Tooltip>
          </div>
        </div>
        {/* RIGHT SIDE */}
        <div css={styles.panelHeaderRight}>
          <div className='grid grid-cols-2 items-center gap-4'>
            <Tooltip
              autoAdjustOverflow
              overlay={
                <div css={styles.tooltipContainer}>
                  <div css={styles.tooltipColumnAvailability}>
                    <p>{t('enums.state.sold_reserved', 'Sold + Reserved')}</p>
                  </div>
                  <div css={styles.tooltipColumn}>
                    <p>
                      {unavailable_count}/{total_count}
                    </p>
                  </div>
                  <div css={styles.tooltipColumn}>
                    <p>{getPercentage(unavailable_count)}</p>
                  </div>
                </div>
              }
            >
              <div className='grid grid-cols-2 items-center gap-4 justify-self-end w-[100px]'>
                <div className='w-[24px] justify-self-end'>
                  <MinimalPieChart
                    total={total_count}
                    sold={unavailable_count}
                  />
                </div>
                <span className='w-[44px] justify-self-start'>
                  {unavailable_count
                    ? formatPercent(unavailable_count / total_count, {
                        truncate: 0,
                      })
                    : '0%'}
                </span>
              </div>
            </Tooltip>
            <Tooltip
              autoAdjustOverflow
              overlay={
                <div css={styles.tooltipContainer}>
                  <div css={styles.tooltipColumnAvailability}>
                    <p>{t('enums.state.available', 'Available')}</p>
                    <p>{t('enums.state.sold_reserved', 'Sold + Reserved')}</p>
                  </div>
                  <div css={styles.tooltipColumn}>
                    <p>
                      {availableCount}/{total_count}
                    </p>
                    <p>
                      {unavailable_count}/{total_count}
                    </p>
                  </div>
                  <div css={styles.tooltipColumn}>
                    <p>{getPercentage(availableCount)}</p>
                    <p>{getPercentage(unavailable_count)}</p>
                  </div>
                </div>
              }
            >
              <p className='mb-6 mt-6'>
                {unavailable_count}/{total_count}{' '}
                {t('price_update.subtitle.units')}
              </p>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  const onChangeFilter = (value: string): void => {
    if (columnsFilterValues.includes(value)) {
      setColumnFilterValues(columnsFilterValues.filter((val) => val !== value));
    } else {
      if (columnDataIndexes.length - columnsFilterValues.length === 2) return;
      setColumnFilterValues((prev) => [...prev, value]);
    }
  };

  useEffect(() => {
    if (!columnsFilterValues.length && storedFilters) {
      setColumnFilterValues(JSON.parse(storedFilters));
    }
  }, [columnsFilterValues.length, storedFilters]);

  return (
    <>
      {/* {renderHeader()} */}
      <Collapse
        ghost
        defaultActiveKey={collapsibleInitialOpen}
        accordion
        expandIconPosition='end'
      >
        {/* @ts-ignore TODO: Fix typing */}
        {(data || []).length > 0 &&
          data.map((item, index) => {
            const columns = getUpdateColumns(
              t,
              formatCurrency,
              formatAreaUnits,
              // @ts-ignore TODO: Fix typing
              getUniqueValues((item.flats ?? []).map((v) => v.layout)).sort(
                // @ts-ignore TODO: Fix typing
                sortLayouts,
              ),
              getUniqueValues(
                // @ts-ignore TODO: Fix typing
                (item.flats ?? []).map((v) => v.availability),
                // @ts-ignore TODO: Fix typing
              ).sort(sortByString),
              getLayout,
              getLayoutAbbr,
              showPricePerSM,
              sortLayouts,
              isRent,
              developerId,
            );
            const slicedColumns = columns.slice(1);
            const onOpenFilter = (dataIndexArray?: string[]): void => {
              if (!columnDataIndexes.length && dataIndexArray) {
                setColumnDataIndexes(
                  storedIndexes
                    ? JSON.parse(storedIndexes)
                    : slicedColumns.map((col) => col.dataIndex as string),
                );
              }
              setIsColumnFilterVisible(!isColumnFilterVisible);
            };
            const onSaveFilters = (): void => {
              onSaveColumnFilters(
                'priceUpdateDataIndexes',
                columnDataIndexes,
                'priceUpdateFilters',
                columnsFilterValues,
              );
              setIsColumnFilterVisible(false);
            };
            const onResetFilters = (): void => {
              localStorage.removeItem('priceUpdateDataIndexes');
              localStorage.removeItem('priceUpdateFilters');
              setColumnFilterValues([]);
            };
            const filteredColumns = columns
              .filter(
                (col) =>
                  col.dataIndex &&
                  !columnsFilterValues.includes(col.dataIndex.toString()),
              )
              .sort(
                (a, b) =>
                  columnDataIndexes.indexOf(a.dataIndex as string) -
                  columnDataIndexes.indexOf(b.dataIndex as string),
              );
            return (
              <Collapse.Panel
                // @ts-ignore TODO: Fix typing
                key={item.key}
                // @ts-ignore TODO: Fix typing
                header={renderPanelHeader(item)}
                ref={index === collapsibleInitialOpen ? activePanelRef : null}
              >
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <div className='relative'>
                  <div
                    className='absolute items-center flex cursor-pointer z-10 right-5 top-4'
                    onClick={
                      () =>
                        onOpenFilter(
                          slicedColumns.map((col) => col.dataIndex) as string[],
                        ) /* eslint-disable react/jsx-curly-newline */
                    }
                  >
                    <EllipsisHorizontalIcon className='w-12 storke-2 text-[#828282]' />
                  </div>
                  <Table<PriceUpdateTableData<any, any>>
                    // @ts-ignore TODO: Fix typing
                    onRow={onRow}
                    rowKey='id'
                    // @ts-ignore TODO: Fix typing
                    rowClassName={rowClassName}
                    // @ts-ignore TODO: Fix typing
                    onHeaderRow={onHeaderRow}
                    className={`price-list-table ${
                      !isMouseOver ? 'hide-filters' : ''
                    }`}
                    // @ts-ignore TODO: Fix typing
                    dataSource={item.flats}
                    // @ts-ignore TODO: Fix typing
                    columns={filteredColumns}
                    showSorterTooltip={false}
                    pagination={{
                      pageSize: pageSize || 10,
                      onShowSizeChange: (_, size) => setPageSize(size),
                    }}
                    scroll={{ x: breakpoints.xl ? undefined : true }}
                  />
                  {isColumnFilterVisible && (
                    <div className='absolute -right-14 top-14 flex flex-col bg-white rounded-2xl shadow-xl p-4 min-w-[300px] z-20 space-y-6 text-bmblue select-none'>
                      <span className='font-bold p-4 flex items-center'>
                        Selected Columns
                      </span>
                      <ColumnFiltersList
                        columnDataIndexes={columnDataIndexes}
                        setColumnDataIndexes={setColumnDataIndexes}
                        columnsFilterValues={columnsFilterValues}
                        onChangeFilter={onChangeFilter}
                      />
                      <div className='flex justify-evenly items-center space-x-4 p-4'>
                        <Button
                          className='bm-default-btn'
                          onClick={() => setIsColumnFilterVisible(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          className='bm-default-btn'
                          onClick={() => onResetFilters()}
                        >
                          Reset
                        </Button>
                        <Button
                          className='bm-submit-btn'
                          onClick={() => onSaveFilters()}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Collapse.Panel>
            );
          })}
      </Collapse>
    </>
  );
};
