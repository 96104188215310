import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import {
  useFlatLayout,
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { OverviewLoading } from '../OverviewLoading';
import { ProjectSelect } from '../ProjectSelect';
import { getUniqueValues } from '../../utils/utils';
import { LayoutFilter } from './LayoutFilter';
import { AddProject } from '../AddProject';
import { ProjectType } from '../../store/types';
import { PriceExportButton } from './PriceExportButton';
import { REVAMP_ALLOWED_DEVELOPERS } from '../../constants';
import { PriceListExportButton } from './PriceListExportButton';

enum ColumnSwitchValue {
  PRICE,
  PRICE_PER_SM,
}

export const PriceListFilters = React.memo(
  ({ showLayoutFilter }: { showLayoutFilter: boolean }) => {
    const { t } = useTranslation();
    const { projectType } = useStoreState((state) => state.filters);
    const isRent = projectType === ProjectType.RENT;
    const showPricePerSM = useStoreState(
      (state) => state.priceList.showPricePerSM,
    );
    const { areaUnit } = useRegionFormatting();
    const setShowPricePerSM = useStoreActions(
      (actions) => actions.priceList.setShowPricePerSM,
    );
    const layoutFilter = useStoreState((state) => state.priceList.layoutFilter);
    const setLayoutFilter = useStoreActions(
      (actions) => actions.priceList.setLayoutFilter,
    );
    const { profile } = useStoreState((state) => state.user);
    const developerId = profile?.developer_id ?? 0;

    const handleOnPricePerSMChange = useCallback(
      (value: SegmentedValue): void => {
        setShowPricePerSM(value === ColumnSwitchValue.PRICE_PER_SM);
      },
      [setShowPricePerSM],
    );

    const handleOnLayoutFilterChange = useCallback(
      (data: CheckboxValueType[]): void => {
        setLayoutFilter(data as string[]);
      },
      [setLayoutFilter],
    );

    const { getLayoutAbbr } = useFlatLayout();

    const data = useStoreState((state) => state.priceList.data);
    const layouts = useMemo(
      () =>
        getUniqueValues(
          data?.map((item) => getLayoutAbbr(item.layout)) ?? [],
        ).sort(),
      [data, getLayoutAbbr],
    );

    return (
      <>
        {showLayoutFilter && (
          <LayoutFilter
            layoutOptions={layouts}
            layoutFilterValue={layoutFilter}
            onLayoutFilterChange={handleOnLayoutFilterChange}
          />
        )}
        <div>
          <Segmented
            value={
              showPricePerSM
                ? ColumnSwitchValue.PRICE_PER_SM
                : ColumnSwitchValue.PRICE
            }
            options={[
              {
                value: ColumnSwitchValue.PRICE,
                label: t(
                  `common.${isRent ? 'rent' : 'price'}`,
                  `${isRent ? 'Rent' : 'Price'}`,
                ),
              },
              {
                value: ColumnSwitchValue.PRICE_PER_SM,
                label: t(
                  `common.${isRent ? 'rent' : 'price'}_per_unit`,
                  `${isRent ? 'Rent' : 'Price'} per {{unit}}`,
                  {
                    unit: areaUnit,
                  },
                ),
              },
            ]}
            onChange={handleOnPricePerSMChange}
          />
        </div>
        {developerId === 122 ? <AddProject /> : null}
        <div>
          <OverviewLoading>
            <ProjectSelect />
          </OverviewLoading>
        </div>
        <div>
          {!REVAMP_ALLOWED_DEVELOPERS.includes(developerId) ? (
            <PriceListExportButton />
          ) : (
            <PriceExportButton type='priceList' />
          )}
        </div>
      </>
    );
  },
);
