import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getProject } from '../api';
import { ExtendedProjectDetailTableData, StoreModel } from './types';
import { Project } from '../api/mockData/projectData';
import { aggregatePriceListData, logError } from '../utils/utils';
import { PricePerSMCalcEnum } from '../api/enums';

export interface ProjectStore {
  data?: Project;
  aggregatedTableData: Computed<
    ProjectStore,
    ExtendedProjectDetailTableData[],
    StoreModel
  >;
  setProject: Action<ProjectStore, Project>;
  fetchProject: Thunk<ProjectStore, number, unknown, StoreModel>;
}

export const projectStore: ProjectStore = {
  aggregatedTableData: computed(
    [(state) => state.data?.price_list ?? []],
    (data) => aggregatePriceListData(data),
  ),
  setProject: action((state, newProject: Project) => {
    state.data = newProject;
  }),
  fetchProject: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    const {
      user: { profile },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    try {
      const { data } = await getProject(
        payload,
        profile?.price_per_sm_calculation ??
          PricePerSMCalcEnum.WITHOUT_EXTERIOR,
        profile?.VAT_included,
        profile?.reserved_as_sold,
      );
      actions.setProject(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
};
