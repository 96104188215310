import { FC, useState, useEffect, useRef } from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import type { InputRef } from 'antd';
import clsx from 'clsx';
import { IncludeIcon } from '../market/IncludeIcon';
import { useStoreActions, useStoreState } from '../../hooks';
import { InfoPopover } from '../InfoPopover';

type Column = {
  column_name: string;
  included: boolean;
};

type Props = {
  showColumnsFilter: boolean;
};

export const ColumnSelector: FC<Props> = ({ showColumnsFilter }) => {
  const columns = useStoreState((state) => state.market.columns);
  const setIncluded = useStoreActions(
    (actions) => actions.market.setIncludedColumns,
  );
  const setIncludeAll = useStoreActions(
    (actions) => actions.market.setIncludeAllColumns,
  );
  const [searchValue, setSearchValue] = useState('');
  const searchInputRef = useRef<InputRef>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const filteredColumns = columns
    .filter((column: Column) =>
      t(`market.export.columns.${column.column_name}`)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          searchValue
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        ),
    )
    .sort((a, b) => {
      if (a.included === b.included) {
        return 0;
      }
      return a.included ? -1 : 1;
    });

  // Save scroll position before rerender
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      // Store the scroll position
      scrollContainer.dataset.scrollTop = String(scrollContainer.scrollTop);
    }
  }, [filteredColumns]);

  // Restore scroll position after rerender
  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer && scrollContainer.dataset.scrollTop) {
      // Restore the scroll position
      scrollContainer.scrollTop = parseInt(
        scrollContainer.dataset.scrollTop,
        10,
      );
    }
  }, [filteredColumns]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (e.key === ' ') {
        const { activeElement } = document;

        // Check if the current focused element is the actual input element
        if (searchInputRef.current?.input === activeElement) {
          e.preventDefault(); // Prevent default space behavior
          // Manually add space to the search input value
          setSearchValue((prevValue) => `${prevValue} `);
        } else {
          // Space is pressed outside the search input, prevent default to stop closing
          e.preventDefault();
        }
      }
    };

    if (showColumnsFilter) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showColumnsFilter]);

  return (
    <div>
      <span className='pt-[2px]'>{t('market.export.columns', 'Columns')}</span>
      {showColumnsFilter && (
        <div
          onClick={(e) => e.stopPropagation()}
          className='absolute right-[188px] w-[320px] top-0 bg-white min-h-[300px] max-h-[500px] drop-shadow-lg border border-bmgrey rounded-[30px] pt-4 flex flex-col overflow-hidden truncate'
        >
          <div className='flex gap-4 border-b border-bmlightgrey px-8 py-2 text-bmblue font-bold'>
            <IncludeIcon
              value={columns.every(
                (col) =>
                  columns.find((sCol) => sCol.column_name === col.column_name)
                    ?.included,
              )}
              onClick={() => setIncludeAll()}
            />
            <Input
              ref={searchInputRef}
              className='outline-none border-none focus:outline-none'
              prefix={
                <MagnifyingGlassIcon color='#BDBDBD' className='w-[24px]' />
              }
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('menu.search')}
            />
          </div>
          <div
            ref={scrollContainerRef}
            className='overflow-y-scroll flex flex-col'
            style={{ overflowAnchor: 'none' }} // Do not scroll to top on every render
          >
            {filteredColumns.map((column: Column) => (
              <div
                key={column.column_name}
                className={clsx(
                  column.included ? 'bg-[#E0E8F0]' : 'bg-white',
                  'flex gap-4 border-t border-bmlightgrey px-8 py-2 text-bmblue w-full justify-between',
                )}
              >
                <>
                  <div className='flex gap-4 truncate'>
                    <IncludeIcon
                      value={
                        columns.find(
                          (col) => col.column_name === column.column_name,
                        )?.included || false
                      }
                      onClick={() => setIncluded(column.column_name)}
                    />
                    <span className='line-clamp-3'>
                      {t(`market.export.columns.${column.column_name}`)}
                    </span>{' '}
                  </div>
                  <InfoPopover
                    popoverProps={{
                      content: (
                        <p>
                          {t(
                            `market.export.columns.description.${column.column_name}`,
                          )}
                        </p>
                      ),
                    }}
                  />
                </>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
