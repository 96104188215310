/* eslint-disable import/no-extraneous-dependencies */
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

export const DropdownTrigger: FC<PropsWithChildren> = ({ children }) => (
  <DropdownMenu.Trigger asChild>
    <div className='flex cursor-pointer bg-white w-auto justify-between pt-2.5 pb-3.5 px-6 rounded-[25px] gap-4 text-bmblue font-lg font-bold'>
      {children}
      <ChevronDownIcon className='w-6 text-[#BDBDBD]' />
    </div>
  </DropdownMenu.Trigger>
);

type DropdownItemProps = {
  key: string;
  active: boolean;
  onSelect: () => void;
};

export const DropdownItem: FC<PropsWithChildren<DropdownItemProps>> = ({
  children,
  key,
  active,
  onSelect,
}) => (
  <DropdownMenu.Item key={key} onSelect={onSelect} asChild>
    <span
      className={clsx(
        active
          ? 'font-bold bg-[rgba(158,185,218,0.25)]'
          : 'hover:bg-[rgba(211,211,211,0.25)]',
        'outline-none text-xl cursor-pointer py-2 text-bmblue w-full px-6 rounded-lg',
      )}
    >
      {children}
    </span>
  </DropdownMenu.Item>
);

export const DropdownPortal: FC<PropsWithChildren> = ({ children }) => (
  <DropdownMenu.Portal>{children}</DropdownMenu.Portal>
);

export const DropdownContent: FC<PropsWithChildren> = ({ children }) => (
  <DropdownMenu.Content sideOffset={2}>
    <div className='flex flex-col w-auto h-auto shadow py-3 bg-white rounded-xl px-2'>
      {children}
    </div>
  </DropdownMenu.Content>
);

type DropdownProps = {
  className?: string;
};

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  className,
  children,
}) => (
  <div className={className}>
    <DropdownMenu.Root>{children}</DropdownMenu.Root>
  </div>
);
