import _formatNumber, { IFormatNumberOptions } from 'format-number';
import { countDigits } from './utils';

export const formatNumber = (
  value: number,
  options?: IFormatNumberOptions,
): string => {
  let tranfromedValue = value;
  // fix for numbers with "e-"
  const containsMantisa = value?.toString().includes('e-');
  // fix issue with -0.0 after rounding
  const isNegativeZero = value?.toString().startsWith('-0.0');
  if (containsMantisa || isNegativeZero) {
    tranfromedValue = 0;
  }
  const numOfDigits = countDigits(tranfromedValue);
  return _formatNumber({
    round: numOfDigits <= 2 && numOfDigits > 0 ? 1 : 0,
    ...options,
  })(tranfromedValue);
};

export const formatPercent = (
  value: number,
  options?: IFormatNumberOptions,
): string => {
  const formattedNum = formatNumber(value * 100, {
    suffix: '%',
    ...options,
  });
  return formattedNum === '0%' ? '0.0%' : formattedNum;
};

export const formatDifference = (
  value: number,
  isPercent?: boolean, // value is 0-1
): string => {
  const transformedValue = isPercent ? value : value / 100;
  const formattedPercentages = formatPercent(transformedValue);
  if (formattedPercentages === '0.0%') {
    return formattedPercentages;
  }

  return formatPercent(transformedValue, {
    prefix: value > 0 ? '+' : undefined,
  });
};

export const round = (num: number, decimals: number): number => {
  const factor = 10 ** decimals;
  return Math.round(num * factor) / factor;
};

export const formatDate = (date: Date): string =>
  date.toISOString().slice(0, 10);

export const formatStringValue = (string: string, value: string | number, varString: '{Num}' | '{Str}'): string => string.replace(varString, value.toString());
