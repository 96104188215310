import { FC } from 'react';
import { MarketProjectExtended } from '../../store/types';
import { ProjectCardHeader } from './ProjectCardHeader';
import { Content } from '../map/ProjectDetail/Content';
import { Card } from '../Card';

interface ProjectCardProps {
  data: MarketProjectExtended;
  onClose?: () => void;
}

export const ProjectCard: FC<ProjectCardProps> = ({
  data,
  onClose,
}) => {
  const ProjectCardBase: FC = () => (
      <div className='market-project-detail'>
        <ProjectCardHeader data={data} onClose={onClose} />
        <Content data={data} />
      </div>
  );

  return (
    onClose ? (
      <ProjectCardBase />
    ) : (
    <Card>
      <ProjectCardBase />
    </Card>
  ));
};
