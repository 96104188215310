import { FC, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useStoreState } from '../../hooks';
import { Territory } from '../../store/types';

type Props = {
  onSelect: (val: Territory) => void;
};

export const TerritoryInput: FC<Props> = ({ onSelect }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { territories } = useStoreState((state) => state.filters);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
        setSearchValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  if (!territories) return null;

  const filteredTerritories = territories.filter((territory) =>
    territory.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .startsWith(
        searchValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      ),
  );

  return (
    <div ref={dropdownRef}>
      <div className='w-[350px] pointer-events-auto drop-shadow'>
        <div
          className={clsx(
            'h-[55px] flex gap-4 border-bmgrey px-8 py-2 text-bmblue font-bold bg-white rounded-[30px] cursor-pointer',
            !!filteredTerritories.length && open
              ? 'rounded-b-none rounded-t-[30px] border-t border-x'
              : 'rounded-[30px] border',
          )}
          onClick={() => setOpen(!open)}
        >
          <input
            className='outline-none border-none focus:outline-none w-full'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('map.helper.territory', 'Type in an area ...')}
          />
          {!!filteredTerritories.length && open && (
            <div className='absolute bottom-1 h-[1px] bg-bmgrey w-[300px]' />
          )}
        </div>

        {open && (
          <>
            <div
              className={clsx(
                'border-b border-x border-bmgrey bg-white w-[350px] overflow-y-scroll absolute flex flex-col max-h-[200px] rounded-b-[30px]',
                !!filteredTerritories.length ? 'py-2' : '',
              )}
            >
              {filteredTerritories.map((territory) => (
                <div
                  key={territory.key}
                  className='flex gap-4 text-bmblue hover:bg-gray-100 w-full cursor-pointer'
                  onClick={() => {
                    onSelect(territory);
                    setSearchValue('');
                    setOpen(false);
                  }}
                >
                  <span className='px-8 py-2'>{territory.name}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
